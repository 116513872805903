import React, { Fragment, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { CheckIcon, ExternalLinkIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import Footer from './Footer'
import { NavLink, useHistory } from 'react-router-dom'
import Logo from './assets/logo.png'
import LogoDark from './assets/logo-dark.png'
import SampleImage3 from './assets/samplev2-4.png'
import GreenhouseIllustration from './assets/greenhouse-illustration.png'

const navigation = [
  { name: 'Home', to: '/' },
  { name: 'Features', to: '/features' },
  { name: 'Pricing', to: '/pricing' },
  { name: 'Preview', to: '/sample-experience'}
]

const features = [
  {
    name: 'Beautiful interview guides',
    description: 'Create delightful interview guides to enhance your candidates experience.',
  },
  { name: 'Simplified communication', description: 'Chat and synced email encourages communication throughout the process.' },
  {
    name: 'Interview scheduling',
    description: 'Quickly and easily get interviews scheduled with our Calendly integration.',
  },
  { name: 'Greenhouse integration', description: 'Manage your candidates seamlessly within your existing workflow.' }, //Greenhouse Integration
  { name: 'File sharing', description: 'Share and receive files with candidates to ensure everyone has the files they need.' },
  { name: 'Experience automation', description: 'Automate the candidate journey to keep the process moving.' },
]

export default function Home() {
  const [notifyEmail, setNotifyEmail] = useState('')
  const history = useHistory()

  return (
    <div className="min-h-screen bg-gray-800">
      <div className="relative bg-white overflow-hidden">
        <div className="relative">
          <Popover as="header" className="relative">
              <div className="bg-indigo-600 py-6">
                <nav
                  className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
                  aria-label="Global"
                >
                  <div className="flex items-center flex-1">
                    <div className="flex items-center justify-between w-full lg:w-auto">
                      <NavLink exact to='/'>
                        <span className="sr-only">Drumcircle</span>
                        <img
                          className="h-8 w-auto sm:h-10"
                          src={Logo}
                          alt="Drumcircle"
                        />
                      </NavLink>
                      <div className="-mr-2 flex items-center lg:hidden">
                        
                        <div className="hidden sm:block lg:hidden space-x-4 mr-4">
                          <NavLink exact to='/login' className="text-base font-normal bg-indigo-500 py-2 px-4 border border-transparent rounded-xl hover:bg-opacity-75 text-white">Sign in</NavLink>
                          <NavLink exact to='/signup' className="bg-white py-2 px-4 border border-transparent rounded-xl text-base font-medium text-indigo-600 hover:opacity-95">Get started</NavLink>
                        </div>

                        <Popover.Button className="bg-indigo-700 rounded-md p-2 inline-flex items-center justify-center text-gray-100 hover:bg-indigo-800 focus:outline-none">
                          <span className="sr-only">Open main menu</span>
                          <MenuIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="pt-1.5 hidden space-x-4 lg:flex lg:ml-8">
                      {navigation.map((item) => (
                        <NavLink key={item.name} to={item.to} className="text-base font-normal text-white hover:text-gray-50 hover:bg-indigo-500 px-4 py-2 rounded-md">{item.name}</NavLink>
                      ))}
                    </div>
                  </div>
                  <div className="hidden lg:flex lg:items-center lg:space-x-6">
                    <NavLink exact to='/login' className="text-base font-normal bg-indigo-500 py-2 px-4 border border-transparent rounded-xl hover:bg-opacity-75 text-white">Sign in</NavLink>
                    <NavLink exact to='/signup' className="bg-white py-2 px-4 border border-transparent rounded-xl text-base font-medium text-indigo-600 hover:opacity-95">Get started today</NavLink>
                  </div>

                  
                </nav>
              </div>

              <Transition
                as={Fragment}
                enter="duration-150 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top lg:hidden"
                >
                  <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="px-5 pt-4 flex items-center justify-between">
                      <div>
                        <img
                          className="h-8 w-auto"
                          src={LogoDark}
                          alt="Drumcircle"
                        />
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none">
                          <span className="sr-only">Close menu</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="pt-5 pb-6">
                      <div className="px-2 space-y-1">
                        {navigation.map((item) => (
                          <NavLink key={item.name} to={item.to} className="block px-3 py-2 rounded-md text-base font-medium text-indigo-900 hover:bg-gray-50">{item.name}</NavLink>
                        ))}
                      </div>
                      <div className="mt-6 px-5">
                        <NavLink
                          exact
                          to="/signup"
                          className="block text-center w-full py-3 px-4 rounded-xl shadow bg-indigo-600 text-white font-medium hover:bg-indigo-700"
                        >
                          Get started today
                        </NavLink>
                      </div>
                      <div className="mt-6 px-5">
                        <p className="text-center text-base font-medium text-gray-500">
                          Existing customer?{' '}
                          <NavLink exact to='/login' className="text-gray-900 hover:underline">
                            Sign in
                          </NavLink>
                        </p>
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>

            <main className="">
              <div className="pt-8 sm:pt-12 lg:relative lg:py-48">
                <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-20">
                  <div>
                    <div className="sm:text-center sm:mt-12 md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
                      <h1>
                        <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
                          <span className="block text-blue-gray-900">A better way to&nbsp;</span>
                          <span className="block text-indigo-600">hire candidates</span>
                        </span>
                      </h1>
                      <p className="mt-3 text-base text-blue-gray-700 tracking-tight sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                        Personalized candidate interview guides deliver engaging interview experiences and eliminate frustration.
                      </p>
                      <div className="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
                        <div className="flex justify-center lg:justify-start gap-x-6">
                        <NavLink
                            exact to='/signup'
                            className="w-full inline-block text-center px-6 py-3 border border-transparent text-sm font-semibold rounded-full bg-blue-gray-900 text-white hover:bg-blue-gray-700 hover:text-blue-gray-100 active:bg-blue-gray-800 active:text-blue-gray-300 focus:outline-none sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
                          >
                            Get 6 months free
                          </NavLink>
                          <NavLink
                            exact to='/features'
                            className="hidden sm:block w-full px-6 py-3 text-sm rounded-full text-blue-gray-700 hover:text-blue-gray-900 ring-1 ring-blue-gray-200 hover:ring-blue-gray-300 focus:outline-none active:bg-blue-gray-100 active:text-blue-gray-600 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
                          >
                            Learn more
                          </NavLink>
                        </div>
                        {/* <form className="mt-3 sm:flex">
                          <label htmlFor="email" className="sr-only">
                            Email
                          </label>
                          <input
                            type="text"
                            name="email"
                            id="email"
                            value={notifyEmail}
                            onChange={e => setNotifyEmail(e.target.value)}
                            className="block w-full py-3 text-base rounded-md placeholder-gray-500 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:flex-1 border-gray-300"
                            placeholder="Enter your email"
                          />
                          <button
                            type="submit"
                            onClick={() => {
                              history.push(`/signup?email=${encodeURIComponent(notifyEmail)}`)
                            }}
                            className="mt-3 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-500 shadow-sm hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto "
                          >
                            Sign up for free
                          </button>
                        </form> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="px-4 sm:mx-auto sm:max-w-3xl sm:px-6">
                  <div className="py-12 sm:relative sm:mt-12 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                    <div className="hidden sm:block">
                      <div className="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full" />
                      <svg
                        className="absolute top-9 right-1/2 -mr-3 lg:m-0 lg:left-1/4"
                        width={404}
                        height={525}
                        fill="none"
                        viewBox="0 0 404 525"
                      >
                        <defs>
                          <pattern
                            id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                          >
                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                          </pattern>
                        </defs>
                        <rect width={404} height={525} fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
                      </svg>
                    </div>
                    <NavLink exact to="/sample-experience" className="relative pl-4 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
                      <img
                        className="w-full rounded-md shadow-xl lg:ml-24 ring-1 ring-black ring-opacity-10 lg:h-full lg:w-auto lg:max-w-none"
                        src={SampleImage3}
                        alt="Sample Experience"
                      />
                    </NavLink>
                  </div>
                </div>
              </div>
              
              <div className="bg-white">
                <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
                  <div>
                    <h2 className="text-base font-semibold text-indigo-600 uppercase tracking-wide">Engaging experiences</h2>
                    <p className="mt-2 text-3xl font-extrabold text-gray-900">Win more candidates</p>
                    <p className="mt-4 text-lg text-gray-500">
                      Deliver results in todays competitive hiring environment with compelling interview experiences for your candidates.
                      {/* Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla nec. */}
                    </p>
                  </div>
                  <div className="mt-12 lg:mt-0 lg:col-span-2">
                    <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-3 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
                      {features.map((feature) => (
                        <div key={feature.name} className="relative">
                          <dt>
                            <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                            <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                          </dt>
                          <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
                        </div>
                      ))}
                    </dl>
                  </div>
                </div>
              </div>

              <div className="bg-indigo-50">
                <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
                  <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                    <span className="block">Want to see more?</span>
                    <span className="-mb-1 pb-1 hidden sm:block bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent">
                      Explore the candidate experience.
                      {/* Get in touch or create an account. */}
                    </span>
                  </h2>
                  <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
                    <a
                      href="/sample-experience"
                      target="_blank"
                      rel="noreferrer"
                      className="flex items-center justify-center bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700"
                    >
                      <ExternalLinkIcon className="-ml-2 mr-2 h-5 w-5" aria-hidden="true" />
                      Preview experience
                    </a>
                    <NavLink
                      exact to='/features'
                      className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-800 bg-white hover:bg-indigo-100"
                    >
                      Learn more
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className="relative bg-gray-50 pt-16 overflow-hidden sm:pt-24 lg:pt-32">
                <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
                  <div>
                    <h2 className="text-base font-semibold tracking-wider text-greenhouse-green uppercase">Greenhouse</h2>
                    <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                      Seamless workflow integration.
                    </p>
                    <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
                      With our powerful Greenhouse integration, your team can deliver and manage incredible candidate experiences directly from Greenhouse.
                    </p>
                  </div>
                  <div className="mt-12 max-h-32 md: max-h-48 lg:max-h-96">
                    <img
                      className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
                      src={GreenhouseIllustration}
                      alt="Greenhouse Recruiting Illustration"
                    />
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      <Footer/>
    </div>
  )
}
