import { CheckIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { ControlledTextArea } from "../../../ControlledTextArea";
import { RoleAttachmentFormElement, RoleAttachmentFormElementLongText } from "../../../types";

interface FormElementLongTextProps {
  element: RoleAttachmentFormElementLongText
  updateElement: (element: RoleAttachmentFormElement) => void
  nextAction: () => void
  hasNextStep: boolean
}

export default function FormElementLongText(props: FormElementLongTextProps) {
  const [missingRequired, setMissingRequired] = useState(false)
  
  const next = () => {
    if(props.element.payload.required && !props.element.payload.value) {
      setMissingRequired(true)
    } else {
      props.nextAction()
    }
  }


  return (
    <>
      <div className="flex flex-col justify-center h-full mx-20 text-white">
        <h3 className="text-2xl">{props.element.payload.label}</h3>
        
        {props.element.payload.description &&
          <p className="mt-2.5 opacity-80 text-lg leading-7">{props.element.payload.description}</p>
        }

      <div>
        <div className="mt-1 border-b border-gray-300 focus-within:border-white mt-6">
          <ControlledTextArea
            type="text"
            name="longtext"
            id="longtext"
            value={props.element.payload.value}
            onChange={(e) => { props.updateElement({...props.element, payload: {...props.element.payload, value: e.target.value}})} }
            className="placeholder-white placeholder-opacity-50 px-0 block w-full border-0 border-b border-transparent bg-transparent focus:border-white focus:ring-0 text-2xl"
            minRows={1}
          />
        </div>
      </div>

        {props.hasNextStep ?
          <button 
            onClick={next}
            className="inline-flex mt-5 self-start px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-black text-opacity-60 bg-white hover:bg-gray-50 focus:outline-none"
          >
            Ok
            <CheckIcon className="ml-2 mt-0.5 -mr-1 h-5 w-5" aria-hidden="true" />
          </button>
        :
          <button 
            onClick={next}
            className="inline-flex mt-5 self-start px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-black text-opacity-60 bg-white hover:bg-gray-50 focus:outline-none"
          >
            Submit
          </button>
        }
        
      </div>
    </>
  )
}