/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment"
import { useState, useEffect } from "react"
import { CandidateStagePreview, CandidateStage, CandidateActivity, CandidateNotification, Candidate, RoleStage, RoleAttachment, RoleStageFile, RoleAttachmentFileRequest, RoleAttachmentChecklist, BrandingTheme, RoleAttachmentTypeform, RoleAttachmentForm } from "../../Shared/types"
import sampleExperience from "../assets/sampleExperience"
import { ExperienceContext } from "../ExperienceContext"
import { v4 as uuidv4 } from 'uuid';

interface Role {
  title: string,
  description: string,
  id: string
  background_url?: string
  background_color?: string
  role_stages: Array<RoleStage>
  source?: {
    id: string
    external_id: string
    name: string
    provider: string
  }
  archived_at?: string
  created_at: string
}

interface ExperienceSampleProviderProps {
  children: JSX.Element
}

export const ExperienceSampleProvider = (props: ExperienceSampleProviderProps) => {
  const currentUser =  {
    id: '1',
    first_name: 'Sydney',
    last_name: 'Burton',
    email: 'sydney@drumcircle.io',
    bio: 'Lead Recruiter',
    role: 'owner',
    avatar: 'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    email_confirmed: true,
    email_notifications: true,
    org_id: '1'
  }
  
  const [activityMenuOpen, setActivityMenuOpen] = useState(false)
  const [activeStage, setActiveStage] = useState(0)
  const [loadingCandidate, setLoadingCandidate] = useState(true)
  
  const [candidate, setCandidate] = useState({
    candidate_stage_previews: Array<CandidateStagePreview>(),
    candidate_stages: Array<CandidateStage>(),
    activity: Array<CandidateActivity>(),
    notifications: Array<CandidateNotification>()
  } as Candidate)

  const [branding, setBranding] = useState({} as BrandingTheme)

  const [role, setRole] = useState({title: '', description: '', id: '', created_at: '', role_stages: Array<RoleStage>(), attachments: Array<RoleAttachment>()} as Role);

  const fetchRole = async () => {
    if(!currentUser) {return }
    const role = sampleExperience as Role

    setRole(role)

    const sampleCandidate = {
      id: 'sample_candidate_id',
      slug: 'sample_candidate_slug',
      name: 'Angela Fisher',
      email: 'candidate@example.com',
      subscribed_email: true,
      avatar: 'https://images.unsplash.com/photo-1501031170107-cfd33f0cbdcc?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
      role_id: role.id,
      role_overview: role.description,
      role_title: role.title,
      background_url: role.background_url,
      background_color: role.background_color,
      stage: 0,
      stage_title: role.role_stages[0].title,
      status: 'active',
      candidate_stages: role.role_stages,
      candidate_stage_previews: role.role_stages,
      activity: [],
      notifications: [],
      assignee: currentUser,
      updated_at: moment().toISOString()
    }

    setCandidate(sampleCandidate)
    setBranding({
      logo: undefined,
      background_image: 'https://images.unsplash.com/photo-1454496522488-7a8e488e8606?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3276&q=80',
      background_color: ''
    })
    setLoadingCandidate(false)
  }
  useEffect(() => { fetchRole() }, []);

  useEffect(() => {
    if(!loadingCandidate) {
      updateStageDetails(candidate.stage)
    }
  }, [loadingCandidate])

  const messageActivity = (message: string) => {
    if(message === '' || !currentUser) { return }
    return {
      activity_type: 'message',
      candidate_id: candidate.id,
      creator: {type: 'User', id: currentUser.id, name: `${currentUser.first_name} ${currentUser.last_name}`, avatar: currentUser.avatar},
      payload: JSON.stringify({text: message}),
      metadata: JSON.stringify({}),
      created_at: moment().toISOString(),
      updated_at: moment().toISOString(),  
    }
  }

  const messageNotification = (message: string) => {
    if(message === '' || !currentUser) { return }

    const notificationUser = {
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      email: currentUser.email,
      bio: currentUser.bio,
      avatar: currentUser.avatar
    }

    return {
      user: notificationUser,
      created_at: moment().toISOString(),
      id: uuidv4(),
      metadata: JSON.stringify({}),
      notification_type: 'message',
      candidate_id: candidate.id,
      payload: JSON.stringify({text: message}),
      updated_at: moment().toISOString(),
    }
  }

  const sendMessage = async (message: string) => {
    if(message === '') { return }

    const newMessageActivity = {
      activity_type: 'message',
      candidate_id: candidate.id,
      creator: {type: 'Candidate', id: candidate.id, name: candidate.name, avatar: candidate.avatar},
      payload: JSON.stringify({text: message}),
      metadata: JSON.stringify({}),
      created_at: moment().toISOString(),
      updated_at: moment().toISOString(),  
    }

    if(!newMessageActivity) { return }

    setCandidate({ ...candidate, activity: [...candidate.activity, newMessageActivity] });
  }

  const fileActivity = (file: RoleStageFile) => {
    if(!currentUser) { return }

    return {
      activity_type: 'file',
      candidate_id: candidate.id,
      creator: {type: 'User', id: currentUser.id, name: `${currentUser.first_name} ${currentUser.last_name}`, avatar: currentUser.avatar},
      payload: JSON.stringify({file: {name: file.name, type: file.type, url: file.url }}),
      metadata: JSON.stringify({}),
      created_at: moment().toISOString(),
      updated_at: moment().toISOString(),  
    }
  }

  const fileNotification = (file: RoleStageFile) => {
    if(!currentUser) { return }

    const notificationUser = {
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      email: currentUser.email,
      bio: currentUser.bio,
      avatar: currentUser.avatar
    }

    return {
      user: notificationUser,
      created_at: moment().toISOString(),
      id: uuidv4(),
      metadata: JSON.stringify({}),
      notification_type: 'file',
      candidate_id: candidate.id,
      payload: JSON.stringify({file: {name: file.name, type: file.type, url: file.url }}),
      updated_at: moment().toISOString(),
    }
  }
  
  const uploadFile = (event: any, fileRequest?: RoleAttachmentFileRequest) => {
  }

  const markNotificationsAsRead = async () => {
    setCandidate({...candidate, notifications: []})
  }

  const markNotificationAsRead = async (notification: CandidateNotification) => {
    setCandidate({...candidate, notifications: candidate.notifications.filter(not => not.id !== notification.id)})
  }

  const updateTypeform = async (typeform: RoleAttachmentTypeform) => {
  }

  const updateChecklist = async (checklist: RoleAttachmentChecklist, itemIndex: number, checked: boolean) => {
  }

  const updateStageDetails = (index: number) => {
    let updatedCandidate = {...candidate, stage: index, notifications: Array<CandidateNotification>()}

    const stageMessage = role.role_stages[index].message
    if(stageMessage && stageMessage !== '') {
      const newMessageActivity = messageActivity(stageMessage)
      const newMessageNotification = messageNotification(stageMessage)

      if(newMessageActivity && newMessageNotification) {
        updatedCandidate = {...updatedCandidate, activity: [...candidate.activity, newMessageActivity], notifications: [...updatedCandidate.notifications, newMessageNotification]}
      }
    }

    const stageFiles = role.role_stages[index].files
    stageFiles.forEach((file) => {
      const newFileActivity = fileActivity(file)
      const newFileNotification = fileNotification(file)

      if(newFileActivity && newFileNotification) {
        updatedCandidate = {...updatedCandidate, activity: [...updatedCandidate.activity, newFileActivity], notifications: [...updatedCandidate.notifications, newFileNotification]}
      }
    })

    setCandidate(updatedCandidate)
  }
  
  const stageAvailable = (index: number) => { return true }
  const stageSetup = (index: number) => { return index <= candidate.stage }
  const isPreview = true

  const setStage = (index: number) => {
    if(!stageSetup(index)) { updateStageDetails(index) }
    setActiveStage(index)
  }

  const stage = candidate.candidate_stages[activeStage]

  const updateForm = (form: RoleAttachmentForm) => {
    setCandidate(prevCandidate => {
      const stages = prevCandidate.candidate_stages.map((stage) => {
        const attachments = stage.attachments.map((attachment) => {
          return attachment.id === form.id ? form : attachment
        })

        return {...stage, attachments: attachments}
      })

      return {...prevCandidate, candidate_stages: stages}
    })
  }

  const submitForm = async (form: RoleAttachmentForm) => {
    updateForm({...form, payload: {...form.payload, submitted: true}})
  }

  return (
    <ExperienceContext.Provider value={{ isPreview, updateForm, submitForm, branding, candidate, setCandidate, sendMessage, uploadFile, updateTypeform, stage, setStage, activityMenuOpen, setActivityMenuOpen, markNotificationsAsRead, markNotificationAsRead, updateChecklist, stageAvailable}}>
      {!loadingCandidate && props.children}
    </ExperienceContext.Provider>
  )
}