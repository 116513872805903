import { RoleAttachmentBlurb } from '../../types';

interface BlurbTileProps {
  blurb: RoleAttachmentBlurb
  index: number
}

export default function BlurbTile(props: BlurbTileProps) {
  return (
    <>
      <div key={props.index} className="relative w-full justify-between items-center px-4 sm:px-6 lg:px-8 py-8 sm:py-10 focus:outline-none">
        <div className="md:flex-shrink-0">
          {/* <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">{props.blurb.payload.title}</h2> */}
        </div>
        <blockquote className="blurb-body md:flex-grow md:flex md:flex-col">
          <div className="relative md:flex-grow">
            <p className="relative prose" dangerouslySetInnerHTML={{ __html: props.blurb.payload.text.replace(new RegExp('<p><br></p>', 'g'), "") }} />
          </div>
        </blockquote>
      </div>
    </>
  )
}