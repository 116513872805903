/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CandidateFile, CandidateInvite, CandidateNotification, RoleAttachment } from '../Shared/types';
import ExperiencePreviewBanner from './Banners/ExperiencePreviewBanner';
import MessageNotification from './ExperienceNotifications/MessageNotification';
import FileNotification from './ExperienceNotifications/FileNotification';
import InviteNotification from './ExperienceNotifications/InviteNotification';
import { useExperience } from './ExperienceContext';
import RolePreviewBanner from './Banners/RolePreviewBanner';
import SampleExperienceBanner from './Banners/SampleExperienceBanner';
import { ExperienceProvider } from './Providers/ExperienceProvider';
import { RolePreviewProvider } from './Providers/RolePreviewProvider';
import { ExperienceSampleProvider } from './Providers/ExperienceSampleProvider';
import ExperienceArchivedBanner from './Banners/ExperienceArchivedBanner';
import ExperienceChat from './ExperienceChat';
import moment from 'moment';
import { DocumentTextIcon } from '@heroicons/react/outline';

import CallToActionTile from '../Shared/Tiles/CallToActionTile/CallToActionTile';
import QuoteTile from '../Shared/Tiles/QuoteTile/QuoteTile';
import BlurbTile from '../Shared/Tiles/BlurbTile/BlurbTile';
import FileRequestTile from '../Shared/Tiles/FileRequestTile/FileRequestTile';
import YoutubeTile from '../Shared/Tiles/YoutubeTile/YoutubeTile';
import LocationTile from '../Shared/Tiles/LocationTile/LocationTile';
import CalendlyTile from '../Shared/Tiles/CalendlyTile/CalendlyTile';
import UserProfileTile from '../Shared/Tiles/UserProfileTile/UserProfileTile';
import ExperienceStageSelector from './ExperienceStageSelector';
import classNames from 'classnames';
import { LocationMarkerIcon } from '@heroicons/react/solid';
import { InviteLocationDetails } from '../Shared/InviteLocationDetails';
import TypeformTile from '../Shared/Tiles/TypeformTile/TypeformTile';
import GreenhouseFormTile from '../Shared/Tiles/GreenhouseFormTile/GreenhouseFormTile';
import FormTile from '../Shared/Tiles/FormTile/FormTile';

interface ExperiencePageParams {
  slug: string
}

export function ExperiencePage(): JSX.Element {
  const { slug } = useParams<ExperiencePageParams>()

  return (
    <ExperienceProvider slug={slug}>
      <>
        <ExperiencePreviewBanner />
        <ExperienceArchivedBanner />
        <Experience/>
      </>
    </ExperienceProvider>
  )
}


interface PreviewRolePageParams {
  role_id: string
}

export function PreviewRolePage(): JSX.Element {
  const { role_id } = useParams<PreviewRolePageParams>()
  
  return (
    <RolePreviewProvider id={role_id}>
      <>
        <RolePreviewBanner/>
        <Experience/>
        </>
    </RolePreviewProvider>
  )
}

export function ExperienceSamplePage(): JSX.Element {  
  return (
    <ExperienceSampleProvider>
      <>
        <SampleExperienceBanner/>
        <Experience/>
        </>
    </ExperienceSampleProvider>
  )
}

function Experience() {
  const {candidate, stage, branding} = useExperience()

  useEffect(() => { if(candidate) { document.title = candidate.role_title } }, [candidate]);

  var mainAreaStyle = {}
  mainAreaStyle = {backgroundColor: '#f4f4f4'}
  if(branding.background_image) { mainAreaStyle = {backgroundImage: `url(${branding.background_image})`} }
  if(!branding.background_image && branding.background_color) { mainAreaStyle = {backgroundColor: branding.background_color} }

  return (
    <>
      <div aria-live="assertive" className="fixed inset-0 flex items-end px-4 pointer-events-none sm:px-6 pt-16 pb-24 sm:items-end z-50">
        <div className="w-full hidden md:flex flex-col items-center space-y-4 sm:items-end">
          {candidate.notifications.map(renderNotification)}
        </div>
      </div>

      <div className="min-h-screen min-w-full bg-fixed bg-cover" style={mainAreaStyle}>
        <div className={classNames(branding.logo ? 'md:pt-8 pb-20' : 'md:p-20', "p-4 sm:p-6")}>
          {branding.logo &&
            <div className="flex justify-center pb-2 sm:pb-4 md:pb-8">
              <img className="inline-block max-w-xs max-h-40" src={branding.logo} alt="logo" />
            </div>
          }

          <div className="mx-auto max-w-2xl rounded-lg bg-white overflow-hidden shadow">
            <div className="py-6 space-y-6">
              <div className="md:flex md:items-center md:justify-between md:space-x-5 px-4 sm:px-6">
                <div className="flex items-start space-x-5">
                  <div className="flex-shrink-0">
                    <div className="relative">
                      <img
                        className="h-16 w-16 rounded-full"
                        src={candidate.avatar}
                        alt="Candidate Avatar"
                      />
                      <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
                    </div>
                  </div>
                  <div className="pt-1.5">
                    <h1 className="text-2xl font-bold text-gray-900">{candidate.name}</h1>
                    <p className="text-sm font-medium text-gray-500">
                      for{' '}{candidate.role_title}
                    </p>
                  </div>
                </div>

                <div className="mt-6 relative flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                  <div className="relative">
                    <ExperienceStageSelector />
                  </div>
                </div>
              </div>
              
              {renderInvites(stage.invites)}
              {renderFiles(stage.files)}
            </div>
            
            <div className="border-t border-gray-200 divide-y divide-gray-200">
              {stage.attachments.map(renderAttachment)}
            </div>
            
          </div>  
        </div>  
        <ExperienceChat />
      </div>
    </>
  )
}

function renderFiles(files: Array<CandidateFile>) {
  if(files.length === 0) { return null }

  return (
    <div className="px-4 sm:px-6">
      <ul className="border border-gray-200 divide-y divide-gray-200 rounded-md bg-gray-50">
        {files.map((attachment, index) => (
          <li className="px-4 py-3 flex items-center justify-between text-sm">
            <div className="w-0 flex-1 flex items-center">
              <DocumentTextIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
              <span className="ml-2 flex-1 w-0 truncate text-sm text-gray-600">{attachment.name}</span>
            </div>
            <div className="ml-4 flex-shrink-0">
              <a href={attachment.url} target="_blank" rel="noreferrer" className="font-medium text-indigo-600">
                Download
              </a>
            </div>
          </li>
          
        ))}
      </ul>
    </div>
  )
}


function isSameDateAsPrevious(invites: Array<CandidateInvite>, index: number) {
  if(index < 1) { return false }

  const previousMoment = moment(invites[index-1].event_start)
  const currentMoment = moment(invites[index].event_start)

  return previousMoment.isSame(currentMoment, 'date')
}

function renderInvites(invites: Array<CandidateInvite>) {
  if(invites.length === 0) { return null }

  return (
    <>
      <section className="px-4 sm:px-6">
        <ol className="space-y-6">
          {invites.map((invite, index) => (
            <li className="flex">
              <div className="flex-none w-16">
                {!isSameDateAsPrevious(invites, index) &&
                  <>
                    <p className="text-xs font-light text-gray-400 text-center">{moment(invite.event_start).format("ddd")}</p>
                    <p className="text-2xl mt-1 font-light text-gray-800 text-center">{moment(invite.event_start).format("D")}</p>
                  </>
                }
                
              </div>

              <div className="bg-indigo-400 flex-shrink-0 w-0.5 rounded-full">
              </div>
              <div className="flex-auto ml-4 my-1">
                <p className="flex-auto font-semibold text-gray-900">{invite.summary}</p>
                <p className="flex-none sm:hidden text-sm leading-6 text-gray-500 -mt-1">
                  <time>{moment(invite.event_start).format("h:mm A")}</time> - <time>{moment(invite.event_end).format("h:mm A")}</time>
                </p>
                <div className="mt-1 flex items-center">
                  <LocationMarkerIcon className="w-3 h-3 mr-1 text-indigo-500"/>
                  <InviteLocationDetails location={invite.location} />
                </div>
              </div>

              <p className="flex-none hidden sm:block sm:ml-6 text-sm leading-6 text-gray-500 my-1">
                <time>{moment(invite.event_start).format("h:mm A")}</time> - <time>{moment(invite.event_end).format("h:mm A")}</time>
              </p>
            </li>
          ))}
        </ol>       
      </section>
    </>
  )
}

function renderAttachment(attachment: RoleAttachment, index: number) {
  switch(attachment.type) {
    case 'link': return <CallToActionTile link={attachment} index={index} />
    case 'calendly_link': return <CalendlyTile calendlyLink={attachment} index={index} />
    case 'location': return <LocationTile location={attachment} index={index} />
    case 'user_profile': return <UserProfileTile userProfile={attachment} index={index} />
    case 'youtube_link': return <YoutubeTile link={attachment} index={index} />
    case 'file_request': return <FileRequestTile fileRequest={attachment} index={index} />
    case 'blurb': return <BlurbTile blurb={attachment} index={index} />
    case 'quote': return <QuoteTile quote={attachment} index={index} />
    case 'typeform': return <TypeformTile typeform={attachment} index={index} sandbox={false} />
    case 'greenhouse_form': return <GreenhouseFormTile greenhouse_form={attachment} index={index} />
    case 'form': return <FormTile form={attachment} index={index} />
    default: return null
  }
}

function renderNotification(notification: CandidateNotification) {
  switch(notification.notification_type) {
    case 'message': return <MessageNotification key={notification.id} notification={notification} />
    case 'file': return <FileNotification key={notification.id} notification={notification} />
    case 'invite': return <InviteNotification key={notification.id} notification={notification} />
    default: return null
  }
}