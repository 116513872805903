import React, { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import Footer from './Footer'
import { NavLink } from 'react-router-dom'
import Logo from './assets/logo.png'
import LogoDark from './assets/logo-dark.png'

const navigation = [
  { name: 'Home', to: '/' },
  { name: 'Features', to: '/features' },
  { name: 'Pricing', to: '/pricing' },
  { name: 'Preview', to: '/sample-experience', target: '_blank'}
]
interface LayoutProps {
  children: JSX.Element
}

export default function Layout(props: LayoutProps) {
  return (
    <div className="min-h-screen bg-white">
      <div className="relative overflow-hidden">
      <Popover as="header" className="relative">
              <div className="bg-indigo-600 py-6">
                <nav
                  className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
                  aria-label="Global"
                >
                  <div className="flex items-center flex-1">
                    <div className="flex items-center justify-between w-full lg:w-auto">
                      <NavLink exact to='/'>
                        <span className="sr-only">Drumcircle</span>
                        <img
                          className="h-8 w-auto sm:h-10"
                          src={Logo}
                          alt="Drumcircle"
                        />
                      </NavLink>
                      <div className="-mr-2 flex items-center lg:hidden">
                        
                        <div className="hidden sm:block lg:hidden space-x-4 mr-4">
                          <NavLink exact to='/login' className="text-base font-normal bg-indigo-500 py-2 px-4 border border-transparent rounded-xl hover:bg-opacity-75 text-white">Sign in</NavLink>
                          <NavLink exact to='/signup' className="bg-white py-2 px-4 border border-transparent rounded-xl text-base font-medium text-indigo-600 hover:opacity-95">Get started</NavLink>
                        </div>

                        <Popover.Button className="bg-indigo-700 rounded-md p-2 inline-flex items-center justify-center text-gray-100 hover:bg-indigo-800 focus:outline-none">
                          <span className="sr-only">Open main menu</span>
                          <MenuIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="pt-1.5 hidden space-x-4 lg:flex lg:ml-8">
                      {navigation.map((item) => (
                        <NavLink key={item.name} to={item.to} className="text-base font-normal text-white hover:text-gray-50 hover:bg-indigo-500 px-4 py-2 rounded-md">{item.name}</NavLink>
                      ))}
                    </div>
                  </div>
                  <div className="hidden lg:flex lg:items-center lg:space-x-6">
                    <NavLink exact to='/login' className="text-base font-normal bg-indigo-500 py-2 px-4 border border-transparent rounded-xl hover:bg-opacity-75 text-white">Sign in</NavLink>
                    <NavLink exact to='/signup' className="bg-white py-2 px-4 border border-transparent rounded-xl text-base font-medium text-indigo-600 hover:opacity-95">Get started today</NavLink>
                  </div>

                  
                </nav>
              </div>

              <Transition
                as={Fragment}
                enter="duration-150 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top lg:hidden"
                >
                  <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="px-5 pt-4 flex items-center justify-between">
                      <div>
                        <img
                          className="h-8 w-auto"
                          src={LogoDark}
                          alt="Drumcircle"
                        />
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none">
                          <span className="sr-only">Close menu</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="pt-5 pb-6">
                      <div className="px-2 space-y-1">
                        {navigation.map((item) => (
                          <NavLink key={item.name} to={item.to} className="block px-3 py-2 rounded-md text-base font-medium text-indigo-900 hover:bg-gray-50">{item.name}</NavLink>
                        ))}
                      </div>
                      <div className="mt-6 px-5">
                        <NavLink
                          exact
                          to="/signup"
                          className="block text-center w-full py-3 px-4 rounded-xl shadow bg-indigo-600 text-white font-medium hover:bg-indigo-700"
                        >
                          Get started today
                        </NavLink>
                      </div>
                      <div className="mt-6 px-5">
                        <p className="text-center text-base font-medium text-gray-500">
                          Existing customer?{' '}
                          <NavLink exact to='/login' className="text-gray-900 hover:underline">
                            Sign in
                          </NavLink>
                        </p>
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>

        <main>
          {props.children}
        </main>
      </div>
      <Footer/>
    </div>
  )
}
