import React, { ChangeEvent, ChangeEventHandler, useEffect, useRef, useState } from "react"
import TextareaAutosize from 'react-textarea-autosize';

interface IControlledTextArea {
  value: string
  onChange: ChangeEventHandler<HTMLTextAreaElement> | undefined
  [x: string]: any
}

export const ControlledTextArea = ({ value, onChange, ...rest }: IControlledTextArea) => {
  const [cursor, setCursor] = useState(0)
  const ref = useRef(null)

  useEffect(() => {
      const input: any = ref.current
      if (input) {
          input.setSelectionRange(cursor, cursor)
      }
  }, [ref, cursor, value])

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      setCursor(e.target.selectionStart)
      onChange && onChange(e)
  }

  return <TextareaAutosize ref={ref} value={value} onChange={handleChange} {...rest} />
}