import React from "react";
import { RoleAttachmentFormElementStatement } from "../../../types";

interface FormElementStatementProps {
  element: RoleAttachmentFormElementStatement
  nextAction: () => void
}

export default function FormElementStatement(props: FormElementStatementProps) {

  return (
    <>
      <div className="flex flex-col justify-center h-full mx-20 text-white">
        <h3 className="text-2xl">{props.element.payload.label}</h3>
        
        {props.element.payload.description &&
          <p className="mt-2.5 opacity-80 text-lg leading-7">{props.element.payload.description}</p>
        }

        <button
          onClick={props.nextAction}
          className="mt-9 self-start px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-black text-opacity-60 bg-white hover:bg-gray-50 focus:outline-none"
        >
          {props.element.payload.button_text}
        </button>
      </div>
    </>
  )
}