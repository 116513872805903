import { useEffect, useState } from 'react'
import { CheckCircleIcon } from '@heroicons/react/solid'

const applicationPermissions = ['Get: Retrieve Application', 'Post: Move Application', 'Post: Add Attachment']
const candidatePermissions = ['Get: Retrieve Candidate', 'Patch: Edit Candidate', 'Post: Add Note', 'Post: Add E-mail']
const jobsPermissions = ['Get: Retrieve Job', 'Get: List Jobs']
const jobStagePermissions = ['Get: List Job Stages for Job']
const userPermissions = ['Get: Retrieve User', 'Get: List Users']
const customFieldPermissions = ['Get: Get custom fields', '	Post: Create custom field']

interface GreenhouseModalStep1Props {
  apiKey: string
  setApiKey: React.Dispatch<React.SetStateAction<string>>
  saveApiKey: () => Promise<void>
}

export default function GreenhouseModalStep1(props: GreenhouseModalStep1Props) {
  const [apiKey, setApiKey] = useState(props.apiKey)

  useEffect(() => { setApiKey(props.apiKey) }, [props.apiKey])

  return (
    <div className="mt-3 sm:mt-6">
      <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">Greenhouse Harvest API Key</h3>

      <p className="mt-4 text-base text-gray-500">
        In Greenhouse click on the Configure icon and go to Dev Center &rarr; API Credential Management. <br/>
        Select Create New API Key and set API Type to <i>Harvest</i>, Partner to <i>Drumcircle</i>, and Description to <i>Drumcircle Harvest API Key</i><br/>
        Press Manage Permissions and enable the following required permissions, then paste your new API key below.<br/>
      </p>

      <div className="px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-12 mt-8">
          <div className="">
            <div className="flex items-center">
              <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-gray-600">
                Applications
              </h4>
              <div className="flex-1 border-t-2 border-gray-200" />
            </div>
            <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
              {applicationPermissions.map((feature) => (
                <li key={feature} className="flex items-start lg:col-span-1">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                  </div>
                  <p className="ml-3 text-sm text-gray-700">{feature}</p>
                </li>
              ))}
            </ul>
          </div>

          <div className="">
            <div className="flex items-center">
              <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-gray-600">
                Candidates
              </h4>
              <div className="flex-1 border-t-2 border-gray-200" />
            </div>
            <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
              {candidatePermissions.map((feature) => (
                <li key={feature} className="flex items-start lg:col-span-1">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                  </div>
                  <p className="ml-3 text-sm text-gray-700">{feature}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-12 mt-8">
          <div className="">
            <div className="flex items-center">
              <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-gray-600">
                Jobs
              </h4>
              <div className="flex-1 border-t-2 border-gray-200" />
            </div>
            <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
              {jobsPermissions.map((feature) => (
                <li key={feature} className="flex items-start lg:col-span-1">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                  </div>
                  <p className="ml-3 text-sm text-gray-700">{feature}</p>
                </li>
              ))}
            </ul>
          </div>

          <div className="">
            <div className="flex items-center">
              <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-gray-600">
                Job Stages
              </h4>
              <div className="flex-1 border-t-2 border-gray-200" />
            </div>
            <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
              {jobStagePermissions.map((feature) => (
                <li key={feature} className="flex items-start lg:col-span-1">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                  </div>
                  <p className="ml-3 text-sm text-gray-700">{feature}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-12 mt-8">
          <div className="">
            <div className="flex items-center">
              <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-gray-600">
                Users
              </h4>
              <div className="flex-1 border-t-2 border-gray-200" />
            </div>
            <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
              {userPermissions.map((feature) => (
                <li key={feature} className="flex items-start lg:col-span-1">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                  </div>
                  <p className="ml-3 text-sm text-gray-700">{feature}</p>
                </li>
              ))}
            </ul>
          </div>

          <div className="">
            <div className="flex items-center">
              <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-gray-600">
                Custom Fields
              </h4>
              <div className="flex-1 border-t-2 border-gray-200" />
            </div>
            <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
              {customFieldPermissions.map((feature) => (
                <li key={feature} className="flex items-start lg:col-span-1">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                  </div>
                  <p className="ml-3 text-sm text-gray-700">{feature}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>


      <div className="mt-12 sm:flex">
        <label htmlFor="email" className="sr-only">
          Greenhouse Harvest API Key
        </label>
        <input
          type="text"
          name="harvest-api-key"
          id="harvest-api-key"
          className="block w-full py-3 text-base rounded-md placeholder-gray-500 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:flex-1 border-gray-300"
          placeholder="Paste your Harvest API Key"
          value={apiKey}
          onChange={(e) => { props.setApiKey(e.target.value) }}
        />
        <button
          type="button"
          className="mt-3 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
          onClick={props.saveApiKey}
        >
          Save API Key
        </button>
      </div>
      

      </div>
  )
}