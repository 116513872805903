import { useState } from 'react'
import { GreenhouseRole } from '../../Shared/types'
import { ReactComponent as GreenhouseLogo } from '../assets/greenhouse.svg';
import GreenhouseModal from '../Integrations/GreenhouseModal'

export interface GreenhouseRoleSelection extends GreenhouseRole {
  selected: boolean
}

export default function GreenhouseOnboarding() {
  const [open, setOpen] = useState(false)
  
  return (
    <>
      <div className="rounded-t-lg px-6 py-8 sm:px-10 sm:pt-10 sm:pb-8">
        <GreenhouseLogo className="h-10"/>
        <div className="text-lg text-gray-700 font-medium mt-8">
          <p className="">
            Or connect your Greenhouse account to automatically create roles and enable two-way syncing.
          </p>
        </div>
      </div>
      <button onClick={() => { setOpen(true) }} style={{backgroundColor: '#24A47F'}} className="block w-full relative flex items-center justify-center mt-2 rounded-b-lg not-italic py-5 px-6 focus:outline-none">
        <span className="relative leading-6 sm:pl-1">
          <p className="text-white font-semibold sm:inline">Connect Greenhouse</p>{' '}
        </span>
      </button>
      
      <GreenhouseModal open={open} setOpen={setOpen}/>
    </>
  )
}