import { useState } from 'react'
import { CheckCircleIcon } from '@heroicons/react/solid'

const steps = [
  { id: '01', name: 'Candidate has submitted application', href: '#', status: 'complete' },
  { id: '02', name: 'Candidate has changed stage', href: '#', status: 'current' },
  { id: '03', name: 'Candidate has been hired', href: '#', status: 'upcoming' },
  { id: '03', name: 'Candidate or Prospect rejected', href: '#', status: 'upcoming' },
]

const hooks = [
  {when: 'Candidate has submitted application', name: 'Drumcircle - Candidate Application'},
  {when: 'Candidate has changed stage', name: 'Drumcircle - Candidate Stage'},
  {when: 'Candidate has been hired', name: 'Drumcircle - Candidate Hired'},
  {when: 'Candidate or Prospect rejected', name: 'Drumcircle - Candidate Rejected'},
]

interface GreenhouseModalStep2Props {
  secretKey: string
  advanceStep: () => void
}

export default function GreenhouseModalStep2(props: GreenhouseModalStep2Props) {
  const [currentHook, setCurrentHook] = useState(0)

  return (
    <div className="mt-3 sm:mt-6">
      <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">Greenhouse Webhooks</h3>

      <p className="mt-4 text-base text-gray-500">
        In Greenhouse click on the Configure icon and go to Dev Center &rarr; Webhooks &rarr; Webhooks. <br/><br/>

        Create each of the webhooks below with the provided information and then click Next Step.
      </p>

      <div className="px-8 mt-10 ">
        <div className="grid grid-cols-3">
        <div className="py-12 px-4 sm:px-6 lg:px-8">
          <nav className="flex justify-center" aria-label="Progress">
            <ol className="space-y-6">
              {steps.map((step, index) => (
                <li key={step.name}>
                  {index < currentHook? (
                    <span onClick={() => { setCurrentHook(index) }} className="group cursor-pointer">
                      <span className="flex items-start">
                        <span className="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
                          <CheckCircleIcon
                            className="h-full w-full text-indigo-600 group-hover:text-indigo-800"
                            aria-hidden="true"
                          />
                        </span>
                        <span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                          {step.name}
                        </span>
                      </span>
                    </span>
                  ) : index === currentHook ? (
                    <span onClick={() => { setCurrentHook(index) }} className="flex items-start cursor-pointer" aria-current="step">
                      <span className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                        <span className="absolute h-4 w-4 rounded-full bg-indigo-200" />
                        <span className="relative block w-2 h-2 bg-indigo-600 rounded-full" />
                      </span>
                      <span className="ml-3 text-sm font-medium text-indigo-600">{step.name}</span>
                    </span>
                  ) : (
                    <span onClick={() => { setCurrentHook(index) }} className="group cursor-pointer">
                      <div className="flex items-start">
                        <div className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                          <div className="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400" />
                        </div>
                        <p className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</p>
                      </div>
                    </span>
                  )}
                </li>
              ))}
            </ol>
          </nav>
        </div>
        <div className="col-span-2">
          <div className="overflow-hidden">
            <div className="px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Name</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{hooks[currentHook].name}</dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">When</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{hooks[currentHook].when}</dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Endpoint URL</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">https://drumcircle.io/webhooks/greenhouse</dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Secret key</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.secretKey}</dd>
                </div>
              </dl>
            </div>
          </div>
          </div>
        </div>
        
      </div>


      <div className="mt-12 sm:flex sm:justify-end">
        <button
          type="button"
          className="mt-3 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
          onClick={props.advanceStep}
        >
          Next Step
        </button>
      </div>
      </div>
  )
}