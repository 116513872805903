import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Switch, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { TokenResult } from '@stripe/stripe-js'
import axios from 'axios'
import { useAuth } from '../../Shared/AuthContext'
import classNames from 'classnames'

enum SubscriptionPeriod {
  Monthly = 'monthly',
  Annual = 'annual'
}

interface SubscribeGrowthProps {
  annualBillingEnabled: boolean
  plan: string
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}


export default function SubscribeGrowth(props: SubscribeGrowthProps) {
  const [open, setOpen] = useState(props.open)
  const [creatingSubscription, setCreatingSubscription] = useState(false)
  const cancelButtonRef = useRef(null)

  useEffect(() => {setOpen(props.open)}, [props.open])
  useEffect(() => {setAnnualBillingEnabled(props.annualBillingEnabled)}, [props.annualBillingEnabled])

  const stripe = useStripe();
  const elements = useElements();
  const {setSubscriptions} = useAuth()

  const [annualBillingEnabled, setAnnualBillingEnabled] = useState(props.annualBillingEnabled)

  const createSubscription = async (token: TokenResult) => {
    const response = await axios.post('/subscriptions', {
      subscription: {
        card_token: token.token?.id,
        plan: props.plan,
        period: (annualBillingEnabled ? SubscriptionPeriod.Annual : SubscriptionPeriod.Monthly)
      }
    })
    setSubscriptions(response.data.subscriptions)
    props.setOpen(false)
  }

  const subscribe = () => {
    // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
    if (!stripe || !elements) { return }

    setCreatingSubscription(true)
    const cardElement = elements.getElement("card");

    stripe.createToken(cardElement!)
      .then((payload) => {
        createSubscription(payload)
      })
    setCreatingSubscription(false)
  }

  function toCaps(string: string) {
    return string[0].toUpperCase() + string.slice(1);
  }


  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={props.setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Upgrade to {toCaps(props.plan)}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      You are about to upgrade to the {toCaps(props.plan)} plan.
                    </p>
                  </div>
                  <div className="mt-6 mx-6">
                    <CardElement
                      options={{
                        style: {
                          base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                              color: '#aab7c4',
                            },
                          },
                          invalid: {
                            color: '#9e2146',
                          },
                        },
                      }}
                    />
                  </div>
                  <div className="mt-6 mx-6">
                  <Switch.Group as="div" className="flex items-end justify-end">
                  <Switch.Label as="span" className="mr-3">
                    <span className="text-sm font-medium text-gray-900">{annualBillingEnabled ? 'Annual' : 'Monthly'}</span>
                      </Switch.Label>
                      <Switch
                        checked={annualBillingEnabled}
                        onChange={setAnnualBillingEnabled}
                        className={classNames(
                          annualBillingEnabled ? 'bg-indigo-500' : 'bg-gray-200',
                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer focus:outline-none transition-colors ease-in-out duration-200'
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            annualBillingEnabled ? 'translate-x-5' : 'translate-x-0',
                            'inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200'
                          )}
                        />
                      </Switch>
                      
                    </Switch.Group>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:col-start-2 sm:text-sm"
                  disabled={creatingSubscription}
                  onClick={subscribe}
                >
                  Subscribe
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:col-start-1 sm:text-sm"
                  disabled={creatingSubscription}
                  onClick={() => props.setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
