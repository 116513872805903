import React from "react";
import { RoleAttachment, RoleAttachmentCalendlyLink } from '../../types';
import TextareaAutosize from 'react-textarea-autosize';

interface CalendlyTileEditorProps {
  calendlyLink: RoleAttachmentCalendlyLink
  updateAttachment: (attachment: RoleAttachment) => void
}

export default function CalendlyTileEditor(props: CalendlyTileEditorProps) {
  return (
    <div className="">
      <div className="p-4 space-y-6">
        <div>
          <label htmlFor="url" className="block text-sm font-medium text-gray-700">
            Calendly link
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="url"
              id="url"
              value={props.calendlyLink.payload.url}
              onChange={(e) => { props.updateAttachment({...props.calendlyLink, payload: {...props.calendlyLink.payload, url: e.target.value}})} }
              autoComplete="url"
              placeholder="https://calendly.com/drumcircle/meet"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>

        <div>
          <label htmlFor="primary-text" className="block text-sm font-medium text-gray-700">
            Primary text
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="primary-text"
              id="primary-text"
              placeholder="Schedule Interview"
              value={props.calendlyLink.payload.description}
              onChange={(e) => { props.updateAttachment({...props.calendlyLink, payload: {...props.calendlyLink.payload, description: e.target.value}})} }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>

        <div>
          <label htmlFor="supporting-text" className="block text-sm font-medium text-gray-700">
            Supporting text
          </label>
          <div className="mt-1">
            <TextareaAutosize
              name="supporting-text"
              id="supporting-text"
              value={props.calendlyLink.payload.details}
              minRows={3}
              onChange={(e) => { props.updateAttachment({...props.calendlyLink, payload: {...props.calendlyLink.payload, details: e.target.value}})} }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
      </div>
    </div>
  )
}