import React, { ChangeEvent, ChangeEventHandler, useEffect, useRef, useState } from "react"

interface IControlledInput {
  value: string
  onChange: ChangeEventHandler<HTMLInputElement> | undefined
  [x: string]: any
}

export const ControlledInput = ({ value, onChange, ...rest }: IControlledInput) => {
  const [cursor, setCursor] = useState(0)
  const ref = useRef(null)

  useEffect(() => {
      const input: any = ref.current
      if (input) {
          input.setSelectionRange(cursor, cursor)
      }
  }, [ref, cursor, value])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      setCursor(e.target.selectionStart || 0)
      onChange && onChange(e)
  }

  return <input ref={ref} value={value} onChange={handleChange} {...rest} />
}