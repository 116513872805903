import React from "react";
import { RoleAttachmentQuote } from "../../types";

interface QuoteTileProps {
  quote: RoleAttachmentQuote
  index: number
}

export default function QuoteTile(props: QuoteTileProps) {
  return (
    <>
    {/* <section className="bg-gray-50 overflow-hidden">
      <div className="relative max-w-7xl mx-auto pt-12 pb-12 px-4 sm:px-6">
        <svg
          className="absolute top-full left-0 transform translate-x-80 -translate-y-24"
          width={784}
          height={404}
          fill="none"
          viewBox="0 0 784 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={784} height={404} fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)" />
        </svg>

      
        <div className="relative">
          <div className="relative">
            <blockquote className="relative">
            
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-extrabold text-gray-900">All-in-one platform</h2>
              <p className="mt-4 text-lg text-gray-500">
                Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla nec.
              </p>
            </div>
            
           
            </blockquote>
          </div>
        </div>
      </div>
    </section> */}

    {/* <div className="h-10 w-full bg-green-500"></div> */}
    
    
    
    <section className="py-12 bg-gray-50 overflow-hidden md:py-16">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <svg
          className="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          role="img"
          aria-labelledby="svg-workcation"
        >
          <title id="svg-workcation">Workcation</title>
          <defs>
            <pattern
              id="ad119f34-7694-4c31-947f-5c9d249b21f3"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={404} fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)" />
        </svg>

        
          <div className="relative">
            <blockquote className="">
              <div className="max-w-3xl mx-auto text-center">
                {props.quote.payload.title && 
                  <h2 className="text-3xl font-extrabold text-gray-900">{props.quote.payload.title}</h2>
                }
                <p className="mt-4 text-lg text-gray-500">{props.quote.payload.text}</p>
              </div>

              {props.quote.payload.attribution &&
                <footer className="mt-8 -mb-7">
                  <div className="md:flex md:items-center md:justify-center">
                    <div className="md:flex-shrink-0">
                      <img
                        className="mx-auto h-10 w-10 rounded-full"
                        src={props.quote.payload.attribution.avatar}
                        alt=""
                      />
                    </div>
                    <div className="mt-3 text-end md:mt-0 md:ml-4 md:flex md:items-center">
                      <div className="text-base text-center font-medium text-gray-900">{props.quote.payload.attribution.name}</div>
                    </div>
                  </div>
                </footer>
              }
            </blockquote>
          </div>
      </div>
    </section>
    </>
  )
}