
import React, { useEffect } from 'react'
import { usePage } from '../Shared/PageContext'
import NavBar from './NavBar'

interface LayoutProps {
  children: JSX.Element
}

export default function Layout(props: LayoutProps) {
  const {page} = usePage()

  useEffect(() => {
    let pageTitle = "Drumcircle"
    if(page.title) { pageTitle += ` - ${page.title}` }

    document.title = pageTitle
  }, [page]);
  
  return (
    <div className="min-h-screen bg-gray-100 w-max">
      <NavBar />

      
      <header className="py-4 sm:py-10 bg-indigo-600">
        <div className="w-screen">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:flex sm:items-center sm:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate">{page.title}</h2>
            </div>
            <div className="flex mt-3 sm:mt-0">
              {page.controls}
            </div>
          </div>
        </div>
      </header>
      
      <div className="bg-indigo-600 pb-32"></div>
      

      <main className="-mt-32 w-screen">
        {props.children}
      </main>
    </div>
  )
}
