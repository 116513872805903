import { createContext, useContext } from "react";
import { BrandingTheme, Candidate, CandidateNotification, CandidateStage, RoleAttachmentChecklist, RoleAttachmentFileRequest, RoleAttachmentForm, RoleAttachmentTypeform } from "../Shared/types";


interface ExperienceContextInterface {
  candidate: Candidate
  setCandidate: React.Dispatch<React.SetStateAction<Candidate>>
  isPreview: boolean
  branding: BrandingTheme
  sendMessage: (message: string) => Promise<void>
  uploadFile: (event: any, fileRequest?: RoleAttachmentFileRequest) => void
  updateTypeform: (typeform: RoleAttachmentTypeform) => Promise<void>
  updateChecklist: (checklist: RoleAttachmentChecklist, itemIndex: number, checked: boolean) => Promise<void>
  stage: CandidateStage
  setStage: (index: number) => void
  activityMenuOpen: boolean
  setActivityMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  markNotificationsAsRead: () => Promise<void>
  markNotificationAsRead: (notification: CandidateNotification) => Promise<void>
  stageAvailable: (index: number) => boolean
  updateForm: (form: RoleAttachmentForm) => void
  submitForm: (form: RoleAttachmentForm) => Promise<void>
}

export const ExperienceContext = createContext({} as ExperienceContextInterface);
export const useExperience: () => ExperienceContextInterface = () => useContext(ExperienceContext)