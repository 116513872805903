import { XIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import { useExperience } from "../ExperienceContext";

export default function ExperienceArchivedBanner()  {
  const { candidate } = useExperience()
  const [hideBanner, setHideBanner] = useState(false)

  if(hideBanner) { return null }
  if(candidate && !candidate.archived_at) { return null }
  
  return (
    <div className="fixed left-0 top-0 right-0 bg-indigo-600 z-50">
      <div className="max-w-7xl mx-auto py-5 px-3 sm:px-6 lg:px-8">
        <div className="pr-16 sm:text-center sm:px-16">
          <p className="font-medium text-white">
            <span className="md:hidden">Experience Archived!</span>
            <span className="hidden md:inline">This experience has been archived!</span>
          </p>
        </div>
        <div className="absolute inset-y-0 right-0 pt-3 pr-1 flex items-start sm:pt-3 sm:pr-2 sm:items-start">
          <button
            type="button"
            onClick={() => { setHideBanner(true) }}
            className="flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white"
          >
            <span className="sr-only">Dismiss</span>
            <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  )
}