/* eslint-disable react-hooks/exhaustive-deps */
import { Transition } from "@headlessui/react"
import React, { Fragment, useEffect, useState } from "react"
import { CandidateNotification } from "../../Shared/types";
import { useExperience } from "../ExperienceContext"

interface FileNotificationProps {
  notification: CandidateNotification
}

export default function FileNotification(props: FileNotificationProps) {
  const [show, setShow] = useState(true)
  const {markNotificationAsRead} = useExperience()

  useEffect(() => { if(!show) { markNotificationAsRead(props.notification)} }, [show])
  
  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5">
        <div className="w-0 flex-1 p-4 self-center">
          <div className="flex items-start">
            <div className="flex-shrink-0 pt-0.5">
              <img
                className="h-10 w-10 rounded-full"
                src={props.notification.user.avatar}
                alt='avatar'
              />
            </div>
            <div className="ml-3 w-0 flex-1">
              <p className="text-sm font-medium text-gray-900">{props.notification.user.first_name} {props.notification.user.last_name}</p>
              <p className="mt-1 text-sm text-gray-500">
                Shared {' '}
                <>
                  <span className="relative inline-flex items-center text-sm">
                    <span className="font-medium text-gray-900">{JSON.parse(props.notification.payload).file.name}</span>
                  </span>{' '}
                </>
              </p>
            </div>
          </div>
        </div>
        <div className="flex border-l border-gray-200">
          <div className="flex flex-col divide-y divide-gray-200">
            <div className="h-0 flex-1 flex">
              <a
                href={JSON.parse(props.notification.payload).file.url}
                target="_blank"
                rel="noreferrer"
                className="w-full border border-transparent rounded-none rounded-tr-lg px-6 py-3 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:z-10 focus:ring-2 focus:ring-indigo-500"
                onClick={() => {
                  setShow(false)
                }}
              >
                Download
              </a>
            </div>
            <div className="h-0 flex-1 flex">
              <button
                className="w-full border border-transparent rounded-none rounded-br-lg px-6 py-3 flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={() => {
                  setShow(false)
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  )
}