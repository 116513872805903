import { VideoCameraIcon } from '@heroicons/react/outline'
import React from 'react'
import { RoleAttachmentYoutubeLink } from '../../types'
import './YoutubeTile.css'

interface YoutubeTileProps {
  link: RoleAttachmentYoutubeLink
  index: number
}

export default function YoutubeTile(props: YoutubeTileProps) {
  return (
    <div key={props.index} className="bg-gray-900 w-full break-inside justify-center items-center">
      {props.link.payload.embedHtml ?
        <div className="youtube-container aspect-w-16 aspect-h-9 h-full w-full" dangerouslySetInnerHTML={{ __html: props.link.payload.embedHtml }}></div>
      :
        <div className="youtube-container min-h-80 h-full w-full flex justify-center items-center">
          <VideoCameraIcon className="w-24 h-24 text-white"/>
        </div>
      }
    </div>
  )
}