import axios from "axios"
import React, { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import { useAuth } from "../../Shared/AuthContext"
import { Organization } from "../../Shared/types"

export default function ProfileOverview() {
  const { currentUser } = useAuth()
  const [org, setOrg] = useState({} as Organization)

  const fetchOrg = async () => {
    const response = await axios.get('/organization?mini=true')
    setOrg(response.data.org)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchOrg() }, []);
  
  return (
    <section aria-labelledby="profile-overview-title">
      <div className="bg-white overflow-hidden">
        <h2 className="sr-only" id="profile-overview-title">
          Profile Overview
        </h2>
        <div className="bg-white p-6">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="sm:flex sm:space-x-5">
              <div className="flex-shrink-0">
                <img className="mx-auto h-20 w-20 rounded-full" src={currentUser?.avatar} alt="" />
              </div>
              <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                <p className="text-sm font-medium text-gray-600">{org.name}&nbsp;</p>
                <p className="text-xl font-bold text-gray-900 sm:text-2xl">{`${currentUser?.first_name} ${currentUser?.last_name}`}</p>
                
                {currentUser?.bio ?
                  <p className="text-sm font-medium text-gray-600">{currentUser?.bio}</p>
                  :
                  <NavLink exact to='/settings/profile' className="text-sm font-medium text-gray-600">Add a bio...</NavLink>
                }  
              </div>
            </div>
            <div className="mt-5 flex justify-center sm:mt-0">
              <NavLink
                exact to='/settings/profile'
                className="flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Edit profile
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}