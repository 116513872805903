import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

declare global {
  interface Window { Beacon: any; }
}

function HelpScout() {
  const location = useLocation();
  const [showBeacon, setShowBeacon] = useState(false)
  const {currentUser} = useAuth()

  const displayBeacon = () => {
    if(window.Beacon) {
      window.Beacon('config', {display: {position: 'left'}})
      window.Beacon('init', '9ffed37e-0a0d-4c45-94ce-a00767d5f007')
    }
  }

  useEffect(() => {
    if(currentUser) {
      window.Beacon('identify', {
        name: `${currentUser.first_name} ${currentUser.last_name}`,
        email: currentUser.email
      })
    } else {
      window.Beacon('logout')
      window.Beacon('close')
      window.Beacon('reset')
    }
  },[currentUser])

  useEffect(() => {
    location.pathname.startsWith('/e/') || location.pathname.startsWith('/sample-experience') || location.pathname.endsWith('/preview') ? setShowBeacon(false) : setShowBeacon(true)
  }, [location])

  useEffect(() => {
    if(window.Beacon) {
      showBeacon ? displayBeacon() : window.Beacon('destroy')
    }
  }, [showBeacon])

  return null
}

export default HelpScout;