/* eslint-disable react-hooks/exhaustive-deps */
import { AssigneeOptions2, RoleAttachment, RoleAttachmentQuote, RoleAttachmentUserProfile, TileAttribution, User } from "../../../Shared/types"
import { Fragment, useEffect, useState } from "react";
import { Md5 } from 'ts-md5/dist/md5';
import axios from "axios";
import { Listbox, Portal, Switch, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { useAuth } from "../../../Shared/AuthContext";
import { ControlledTextArea } from "../../../Shared/ControlledTextArea";
import { ControlledInput } from "../../../Shared/ControlledInput";
import classNames from "classnames";
import React from "react";
import { usePopper } from "react-popper";

interface QuoteTileEditorProps {
  quote: RoleAttachmentQuote,
  updateAttachment: (attachment: RoleAttachment) => void
}

export default function QuoteTileEditor(props: QuoteTileEditorProps) {
  const [quote, setQuote] = useState(props.quote)
  const [members, setMembers] = useState(Array<User>())
  

  const [attribution, setAttribution] = useState(quote.payload.attribution as (TileAttribution | null))
  const [attributeTo, setAttributeTo] = useState(!!quote.payload.attribution)

  useEffect(() => { setAttributeTo(!!props.quote.payload.attribution) }, [props.quote.payload.attribution]) 

  useEffect(() => { setQuote(props.quote) }, [props.quote])
  useEffect(() => { if(!attributeTo) {setAttribution(null)} }, [attributeTo])
  useEffect(() => { props.updateAttachment({...quote, payload: {...quote.payload, attribution: attribution}}) }, [attribution])

  const fetchMembers = async () => {
    const response = await axios.get('/organization/members')
    setMembers(response.data.members)
  }
  useEffect(() => { fetchMembers() }, [])

  return (
    <div className="">
      <div className="p-4 space-y-6">
        <div>
          <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
            Title
          </label>
          <div className="mt-1">
            <ControlledInput
              type="text"
              name="title-text"
              id="title-text"
              value={quote.payload.title}
              onChange={(e) => { props.updateAttachment({...quote, payload: {...quote.payload, title: e.target.value}})} }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>

        <div>
          <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
            Body
          </label>
          <div className="mt-1">
            <ControlledTextArea
              type="text"
              name="body-text"
              id="body-text"
              value={quote.payload.text}
              onChange={(e) => { props.updateAttachment({...quote, payload: {...quote.payload, text: e.target.value}})} }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              minRows={2}
            />
          </div>
        </div>

        <div>
          <Switch.Group as="li" className="py-4 flex items-center justify-between">
            <div className="flex flex-col">
              <Switch.Label as="p" className="text-sm font-medium text-gray-700" passive>
                Attribute to
              </Switch.Label>
              <Switch.Description className="text-sm text-gray-500">
                Add user attribution
              </Switch.Description>
            </div>
            <Switch
              checked={attributeTo}
              onChange={setAttributeTo}
              className={classNames(
                attributeTo ? 'bg-teal-500' : 'bg-gray-200',
                'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  attributeTo ? 'translate-x-5' : 'translate-x-0',
                  'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                )}
              />
            </Switch>
          </Switch.Group>
        
          {attributeTo &&
            <div className="mt-1">
              <MemberSelect members={members} attribution={attribution} setAttribution={setAttribution} />
            </div>
          }
        </div>
      </div>
    </div>
  )
}

interface MemberSelectProps {
  members: Array<User>
  attribution: TileAttribution | null
  setAttribution: React.Dispatch<React.SetStateAction<TileAttribution | null>>
}

const MemberSelect = (props: MemberSelectProps) => {
  const { currentUser } = useAuth()
  const [selected, setSelected] = useState(null as AssigneeOptions2 | null)
  const [assigneeOptions, setAssigneeOptions] = useState(Array<AssigneeOptions2>())

  const popperElRef = React.useRef(null);
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  let { styles, attributes } = usePopper(referenceElement, popperElement)

  useEffect(() => {
    if(!selected) { return }

    if(selected.key !== 'other') {
      props.setAttribution({
        name: selected.text,
        email: selected.email,
        bio: selected.bio,
        avatar: selected.image.src
      })
    }
  }, [selected])

  useEffect(() => {
    const matchingAssignee = assigneeOptions.find((assignee) => assignee.email === props.attribution?.email )
    const otherOption = assigneeOptions[assigneeOptions.length - 1]
    const defaultOption = assigneeOptions[0]

    if(matchingAssignee) {
      setSelected(matchingAssignee)
    } else if(props.attribution?.email !== '' ) {
      setSelected(otherOption)
    } else {
      setSelected(defaultOption)
    }
  }, [assigneeOptions])

  useEffect(() => {
    let newAssigneeOptions = Array<AssigneeOptions2>()

    if(currentUser) {
      newAssigneeOptions.push({
        key: currentUser.id,
        text: `${currentUser.first_name} ${currentUser.last_name}`,
        email: currentUser.email,
        bio: currentUser.bio,
        value: currentUser.id,
        image: { avatar: true, src: currentUser.avatar }
      })
    }

    props.members.forEach((member) => {
      if(member.id === currentUser?.id) { return }

      newAssigneeOptions.push({
        key: member.id,
        text: `${member.first_name} ${member.last_name}`,
        email: member.email,
        bio: member.bio,
        value: member.id,
        image: { avatar: true, src: member.avatar }
      })
    })

    newAssigneeOptions.push({
      key: 'other',
      text: 'Custom',
      value: 'other',
      email: '',
      bio: '',
      image: { avatar: false, src: '' }
    })

    setAssigneeOptions(newAssigneeOptions)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.members])

  const previewGravatar = () => {
    if(!props.attribution) { return }
    const emailHash = Md5.hashStr(props.attribution.email.toLowerCase())

    props.setAttribution({...props.attribution, avatar: `https://www.gravatar.com/avatar/${emailHash}`} as TileAttribution)
  }

  if(!selected) { return null }
  
  return (
    <>
    <Listbox value={selected} onChange={setSelected}>
    {({ open }) => (
      <div className="relative w-full">
        <Listbox.Button ref={setReferenceElement} className="relative w-full border border-gray-300 rounded-md shadow-sm overflow-hidden pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <span className="flex items-center">
            {/* {selected.image.avatar ?
              <img src={selected.image.src} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
            :
              <UserCircleIcon className="flex-shrink-0 h-6 w-6 rounded-full text-orange-50 bg-orange-400 border-2 border-transparent" />
            } */}
            
            <span className="block truncate">{selected.text}</span>
          </span>
          <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Portal>
          <div
            ref={popperElRef}
            style={{ ...styles.popper, minWidth: referenceElement?.scrollWidth }}
            {...attributes.popper}
          >
          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            beforeEnter={() => setPopperElement(popperElRef.current)}
            afterLeave={() => setPopperElement(null)}
          >
            <Listbox.Options className="mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
              {assigneeOptions.map((person) => (
                <Listbox.Option
                  key={person.key}
                  className={({ active }) =>
                    classNames(
                      active ? 'text-white bg-indigo-600' : 'text-gray-900',
                      'cursor-default select-none relative py-2 pl-3 pr-9'
                    )
                  }
                  value={person}
                >
                  {({ selected, active }) => (
                    <>
                      <div className="flex items-center">
                        {/* {person.image.avatar ?
                          <img src={person.image.src} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
                        :
                          <UserCircleIcon className="flex-shrink-0 h-6 w-6 rounded-full text-orange-50 bg-orange-400 border-2 border-transparent" />
                        } */}
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {person.text}
                        </span>
                      </div>
  
                      {selected ? (
                        <span
                          className={classNames(
                            active ? 'text-white' : 'text-indigo-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
          </div>
          </Portal>
        </div>
      )}
      </Listbox>

      {props.attribution && selected && selected.key === 'other' &&
          <div className="mt-6 space-y-6">
            <div>
              <label htmlFor="attribute-email" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <div className="mt-1">
                <ControlledInput
                  type="text"
                  name="attribute-name"
                  id="attribute-name"
                  value={props.attribution?.name || ''}
                  onChange={(e) => { props.setAttribution({...props.attribution, name: e.target.value} as TileAttribution)} }
                  autoComplete="given-name"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div>
              <label htmlFor="attribute-email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1">
                <ControlledInput
                  type="text"
                  name="attribute-email"
                  id="attribute-email"
                  value={props.attribution?.email || ''}
                  onChange={(e) => { props.setAttribution({...props.attribution, email: e.target.value} as TileAttribution)} }
                  onBlur={() => { previewGravatar() }}
                  autoComplete="email"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

          </div>
        }
    </>
  )
}