
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import SuccessNotification from '../Shared/SuccessNotification'
import { BlockPicker } from 'react-color';
import { BrandingTheme } from '../../Shared/types'


interface LogoFile {
  name?: string
  type?: string
  url: string
  file?: File
}



export default function Branding() {
  const [saveSuccess, setSaveSuccess] = useState(false)
  
  const [loadingBranding, setLoadingBranding] = useState(true)
  const [branding, setBranding] = useState<BrandingTheme>({background_color: ''})
  const [newLogo, setNewLogo] = useState(null as (LogoFile | null))
  const [newBackgroundImage, setNewBackgroundImage] = useState(null as (LogoFile | null))

  const fetchBranding = async () => {
    const response = await axios.get('/branding')
    setBranding(response.data.branding)
    setLoadingBranding(false)
  }
  useEffect(() => { fetchBranding() }, []);

  const updateBranding = async (e: any) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append('branding[background_color]', branding.background_color)

    if(newLogo && newLogo.file) {
      formData.append('branding[logo]', newLogo.file)
    }

    if(!newLogo && !branding.logo) {
      formData.append('branding[remove_logo]', 'true')
    }

    if(newBackgroundImage && newBackgroundImage.file) {
      formData.append('branding[background_image]', newBackgroundImage.file)
    }

    if(!newBackgroundImage && !branding.background_image) {
      formData.append('branding[remove_background_image]', 'true')
    }

    const response = await axios.put('/branding', formData)
    setBranding(response.data.branding)
    setSaveSuccess(true)
  }


  const selectFileHandler = (e:any) => {
    const logoFile = e.target.files[0];
    
    setNewLogo({
      url: URL.createObjectURL(logoFile),
      file: logoFile
    })
  }

  const selectBackgroundImageFileHandler = (e:any) => {
    const logoFile = e.target.files[0];
    
    setNewBackgroundImage({
      url: URL.createObjectURL(logoFile),
      file: logoFile
    })
  }

  const removeLogo = () => {
    setBranding({background_color: branding.background_color, background_image: branding.background_image})
    setNewLogo(null)
  }

  const removeBackgroundImage = () => {
    setBranding({background_color: branding.background_color, logo: branding.logo})
    setNewBackgroundImage(null)
  }

  if(loadingBranding) { return null }
  
  const logoUrl = newLogo && newLogo.url ? newLogo.url : branding.logo
  const backgroundImageUrl = newBackgroundImage && newBackgroundImage.url ? newBackgroundImage.url : branding.background_image
  
  return (
    <>
    <SuccessNotification show={saveSuccess} setShow={setSaveSuccess} title="Successfully saved!" subtitle="Your branding has been updated." />
      <form className="divide-y divide-gray-200 lg:col-span-9" onSubmit={updateBranding}>
        <div className="py-6 px-4 sm:p-6 lg:pb-8">
          <div>
            <h2 className="text-lg leading-6 font-medium text-gray-900">Branding</h2>
            <p className="mt-1 text-sm text-gray-500">
              Customize the look and feel of your experiences.
            </p>
          </div>

          <div className="mt-6 flex flex-col lg:flex-row">

          <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
          <div className="sm:col-span-6">
            <label htmlFor="photo" className="block text-sm font-medium text-blue-gray-900">
              Logo
            </label>
            <div className="mt-1 flex items-center">
              {logoUrl ?
                <>
                  <img
                    className="inline-block w-80 aspect-auto rounded"
                    src={logoUrl}
                    alt=""
                  />
                  <div className="ml-4 flex">
                    <div className="relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
                      <label
                        htmlFor="user-photo"
                        className="relative text-sm font-medium text-blue-gray-900 pointer-events-none"
                      >
                        <span>Change</span>
                        <span className="sr-only"> user photo</span>
                      </label>
                      <input
                        id="user-photo"
                        name="user-photo"
                        type="file"
                        onChange={selectFileHandler}
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                      />
                    </div>
                    <button
                      type="button"
                      className="ml-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:outline-none focus:border-blue-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-gray-50 focus:ring-blue-500"
                      onClick={removeLogo}
                    >
                      Remove
                    </button>
                  </div>
                </>
              :
                <>
                  <div className="flex">
                    <div className="relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
                      <label
                        htmlFor="user-photo"
                        className="relative text-sm font-medium text-blue-gray-900 pointer-events-none"
                      >
                        <span>Upload</span>
                        <span className="sr-only"> logo</span>
                      </label>
                      <input
                        id="branding-logo"
                        name="branding-logo"
                        type="file"
                        onChange={selectFileHandler}
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </>
              }
            </div>
          </div>

          <div className="sm:col-span-6">
            <label htmlFor="photo" className="block text-sm font-medium text-blue-gray-900">
              Background
            </label>
            
              {backgroundImageUrl ? 
                <div className="mt-1 flex items-center">
                  
                  <img
                    className="inline-block w-80 aspect-auto rounded shadow"
                    src={backgroundImageUrl}
                    alt=""
                  />
                  <div className="ml-4 flex">
                    <div className="relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
                      <label
                        htmlFor="user-photo"
                        className="relative text-sm font-medium text-blue-gray-900 pointer-events-none"
                      >
                        <span>Change</span>
                        <span className="sr-only"> background image</span>
                      </label>
                      <input
                        id="background-image"
                        name="background-image"
                        type="file"
                        onChange={selectBackgroundImageFileHandler}
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                      />
                    </div>
                    <button
                      type="button"
                      className="ml-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:outline-none focus:border-blue-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-gray-50 focus:ring-blue-500"
                      onClick={removeBackgroundImage}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              :
                <>
                <div className="mt-1 flex items-center">
                  <div className="w-80 rounded shadow">
                    <BlockPicker color={branding.background_color} colors={['#e2e8f0', '#e3e0cf', '#C5D5CB', '#F47373', '#6EE7B7', '#93C5FD', '#ff8a65', '#ba68c8', '#111827']} onChange={(color) => { setBranding({...branding, background_color: color.hex}) }} width="100%" triangle="hide"/>
                  </div>
                </div>
                <div className="py-4">

                  <div className="relative">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center">
                      <span className="px-2 bg-white text-sm text-gray-500">Or</span>
                    </div>
                  </div>
                  </div>

                  <div className="mt-1 flex items-center justify-center">

                  <div className="flex">
                    <div className="relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
                      <label
                        htmlFor="user-photo"
                        className="relative text-sm font-medium text-blue-gray-900 pointer-events-none"
                      >
                        <span>Upload photo</span>
                        <span className="sr-only"> user photo</span>
                      </label>
                      <input
                        id="background-image"
                        name="background-image"
                        type="file"
                        onChange={selectBackgroundImageFileHandler}
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>
                </>
              }
              
          </div>
          </div>
          </div>
        </div>

         
        <div className="mt-4 py-4 px-4 flex justify-end sm:px-6">
          <button
            type="button"
            className="bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-5 bg-sky-700 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
          >
            Save
          </button>
        </div>
      </form>
    </>
  )
}
