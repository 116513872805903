import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import GreenhouseModalStep1 from './GreenhouseModalStep1'
import GreenhouseModalStep2 from './GreenhouseModalStep2'
import GreenhouseModalStep3 from './GreenhouseModalStep3'
import axios from 'axios'
import { useMixPanel } from 'react-mixpanel-provider-component'
import { GreenhouseRole } from '../../Shared/types'

const steps = [{ id: 'Step 1', name: 'Create API Key'}, { id: 'Step 2', name: 'Setup Webhooks'},{ id: 'Step 3', name: 'Sync Roles'}]

export interface GreenhouseRoleSelection extends GreenhouseRole {
  selected: boolean
}

interface GreenhouseModalProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function GreenhouseModal(props: GreenhouseModalProps) {
  const {mixpanel} = useMixPanel()
  
  const [currentStep, setCurrentStep] = useState(0)
  const [apiKey, setApiKey] = useState('')
  const [secretKey, setSecretKey] = useState('')
  const [loadingGreenhouseIntegration, setLoadingGreenhouseIntegration] = useState(true)
  const [greenhouseRoles, setGreenhouseRoles] = useState(Array<GreenhouseRoleSelection>())

  const fetchGreenhouseIntegration = async () => {
    setLoadingGreenhouseIntegration(true)
    const response = await axios.get('/greenhouse')
    if(response.data.greenhouse) {
      setApiKey(response.data.greenhouse.api_key)
      setSecretKey(response.data.greenhouse.secret_key)
      fetchGreenhouseRoles()
    }
    
    setLoadingGreenhouseIntegration(false)
  }
  useEffect(() => { fetchGreenhouseIntegration() }, [])
  
  // useEffect(() => { if(apiKey) { fetchGreenhouseRoles() } }, [apiKey])
  useEffect(() => { if(props.open) { setCurrentStep(0) }}, [props.open])

  const saveApiKey = async () => {
    try {
      const response = await axios.post('/greenhouse', {greenhouse: {api_key: apiKey}})
      setApiKey(response.data.greenhouse?.api_key)
      setSecretKey(response.data.greenhouse.secret_key)
      setCurrentStep(1)
      fetchGreenhouseRoles()
      mixpanel.track('Greenhouse - Set Api Key')
    } catch(error) {
      setApiKey('')
    }
  }

  const fetchGreenhouseRoles = async () => {
    try {
      const response = await axios.get('/greenhouse/roles')
      setGreenhouseRoles(response.data.greenhouse.map((role: GreenhouseRole) => ( {...role, selected: false} ) ))
    } catch(error) {
    }
  }

  const advanceStep = () => {
    setCurrentStep(currentStep + 1)
  }

  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={props.setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full">
              <nav aria-label="Progress" className="px-4 pt-5 pb-4 sm:p-6">
                <ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
                  {steps.map((step, index) => (
                    <li key={step.name} className="md:flex-1">
                      {index < currentStep ? (
                        <span
                          onClick={() => { setCurrentStep(index) }}
                          className="group cursor-pointer pl-4 py-2 flex flex-col border-l-4 border-indigo-600 hover:border-indigo-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
                        >
                          <span className="text-xs text-indigo-600 font-semibold tracking-wide uppercase group-hover:text-indigo-800">
                            {step.id}
                          </span>
                          <span className="text-sm font-medium">{step.name}</span>
                        </span>
                      ) : index === currentStep ? (
                        <span
                          onClick={() => { if(apiKey) { setCurrentStep(index) } }}
                          className="pl-4 py-2 cursor-pointer flex flex-col border-l-4 border-indigo-600 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
                          aria-current="step"
                        >
                          <span className="text-xs text-indigo-600 font-semibold tracking-wide uppercase">{step.id}</span>
                          <span className="text-sm font-medium">{step.name}</span>
                        </span>
                      ) : (
                        <span
                          onClick={() => { if(apiKey) { setCurrentStep(index) } }}
                          className="group pl-4 py-2 cursor-pointer flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
                        >
                          <span className="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700">
                            {step.id}
                          </span>
                          <span className="text-sm font-medium">{step.name}</span>
                        </span>
                      )}
                    </li>
                  ))}
                </ol>
              </nav>
              
              <div className="border-t border-gray-300 px-4 sm:px-6 pb-6">
                {currentStep === 0 && 
                  <GreenhouseModalStep1 apiKey={apiKey} setApiKey={setApiKey} saveApiKey={saveApiKey}/>
                }
                {currentStep === 1 && 
                  <GreenhouseModalStep2 secretKey={secretKey} advanceStep={advanceStep}/>
                }
                {currentStep === 2 && 
                  <GreenhouseModalStep3 greenhouseRoles={greenhouseRoles} setOpen={props.setOpen}/>
                }
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}