import React from "react";
import { RoleAttachmentTypeform } from '../../types';
import { Widget as TypeformWidget} from '@typeform/embed-react'
import { useExperience } from "../../../Candidate/ExperienceContext";

interface TypeformTileProps {
  typeform: RoleAttachmentTypeform
  index: number
  sandbox: boolean
}

function TypeformTile(props: TypeformTileProps) {
  const {updateTypeform, isPreview} = useExperience()

  if(props.typeform.payload.submitted) {
    return null
  }

  if(!props.typeform.payload.form_id) {
    return (
      <div className="bg-indigo-50" style={{height: `${props.typeform.payload.height || 400}px`}}>
      </div>
    )
  }

  return (
    <>
      {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdOMzA8spSgn9VmVpf5-Dtz9x9UVkHQgX8lJy_qPgIB-RBgrA/viewform?embedded=true" width="640" height="1130" >Loading…</iframe> */}
      <TypeformWidget onSubmit={() => {updateTypeform(props.typeform)}} id={props.typeform.payload.form_id} style={{width: '102%', marginLeft: '-1%', height: `${props.typeform.payload.height || 400}px`}} enableSandbox={isPreview || props.sandbox} />
    </>
  )
}

export default React.memo(TypeformTile);