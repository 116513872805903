import { CalendarIcon, ChevronRightIcon, CollectionIcon } from "@heroicons/react/solid"
import axios from "axios"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import { Candidate, Flow } from "../../Shared/types"

const tabs = [
  { name: 'Active', key: 'active'},
  { name: 'Archived', key: 'archived'}
]

function classNames(...classes: Array<any>) {
  return classes.filter(Boolean).join(' ')
}


interface FlowListProps {
  candidates: Array<Candidate>
}

export default function FlowList(props: FlowListProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(true)
  const [flows, setFlows] = useState(new Array<Flow>());
  const [archivedFlows, setArchivedFlows] = useState(new Array<Flow>());
  const [currentFilter, setCurrentFilter] = useState(tabs[0].name)

  const fetchFlows = async () => {
    const response = await axios.get('/roles')
      setFlows(response.data.roles)
      setLoading(false)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchFlows() }, []);

  const fetchArchivedFlows = async () => {
    const response = await axios.get('/roles?archived=true')
      setArchivedFlows(response.data.roles)
      setLoading(false)
  }
  
  useEffect(() => {
    if(currentFilter === 'Archived') {
      fetchArchivedFlows()
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilter]);


  const displayedRoles = currentFilter === 'Active' ? flows : archivedFlows

  // const emptyState = () => {
  //   return (
  //     <div className="text-center">
  //     <svg
  //       className="mx-auto h-12 w-12 text-gray-400"
  //       fill="none"
  //       viewBox="0 0 24 24"
  //       stroke="currentColor"
  //       aria-hidden="true"
  //     >
  //       <path
  //         vectorEffect="non-scaling-stroke"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         strokeWidth={2}
  //         d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
  //       />
  //     </svg>
  //     <h3 className="mt-2 text-sm font-medium text-gray-900">No projects</h3>
  //     <p className="mt-1 text-sm text-gray-500">Get started by creating a new project.</p>
  //     <div className="mt-6">
  //       <button
  //         type="button"
  //         className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
  //       >
  //         {/* <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" /> */}
  //         New Project
  //       </button>
  //     </div>
  //   </div>
  //   )
  // }
  

  return (
    <section aria-labelledby="quick-links-title">
      <div className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-1 sm:gap-px">
        <h2 className="sr-only" id="quick-links-title">Quick links</h2>
        <div className="bg-white shadow overflow-hidden sm:rounded-md px-6 pt-6 pb-3">
          <div className="pb-5 border-b border-gray-200 sm:pb-0">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Roles</h3>
            <div className="mt-3 sm:mt-4">
              <div className="sm:hidden">
                <label htmlFor="current-tab" className="sr-only">Select a tab</label>
                <select
                  id="current-tab"
                  name="current-tab"
                  className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  value={currentFilter}
                  onChange={(e: any) => { setCurrentFilter(e.target.value) }}
                >
                  {tabs.map((tab) => (
                    <option key={tab.key}>{tab.name}</option>
                  ))}
                </select>
              </div>
              <div className="hidden sm:block">
                <nav className="-mb-px flex space-x-8">
                  {tabs.map((tab) => (
                    <button
                      key={tab.name}
                      onClick={() => { setCurrentFilter(tab.name) }}
                      className={classNames(
                        currentFilter === tab.name
                          ? 'border-indigo-500 text-indigo-600'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                        'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
                      )}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>


                    <ul className="divide-y divide-gray-200">
                      {displayedRoles.length === 0 &&
                        <div className="h-16"></div>
                      }
                      {displayedRoles.map((role) => (
                        <li key={role.id}>
                          <NavLink exact to={`/role/${role.id}`} className="block hover:bg-gray-50">
                            <div className="py-4 flex items-center">
                              <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                <div className="truncate">
                                  <div className="flex text-sm">
                                    <p className="font-medium text-indigo-600 truncate">{role.title}</p>
                                  </div>
                                  <div className="mt-2 flex">
                                    <div className="flex items-center text-sm text-gray-500">
                                      {role.archived_at &&
                                        <>
                                          <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                          <p>Archived <time dateTime={role.archived_at}>{moment(role.archived_at).fromNow()}</time></p>
                                        </>
                                      }
                                      {!role.archived_at &&
                                        <>
                                          <CollectionIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                          <p>{role.role_stages.length} stages</p>
                                        </>
                                      }
                                      
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                                <div className="flex items-center space-x-2 text-sm text-gray-500 font-medium">
                                  <div className="flex flex-shrink-0 overflow-hidden -space-x-1">
                                    {props.candidates.filter(c => c.role_id === role.id).slice(0,3).map((candidate) => (
                                      <img
                                        key={candidate.email}
                                        className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                                        src={candidate.avatar}
                                        alt={candidate.name}
                                      />
                                    ))}
                                  </div>
                                  {props.candidates.filter(c => c.role_id === role.id).length > 3 &&
                                    <span className="flex-shrink-0 text-xs leading-5 font-medium">
                                      +{props.candidates.filter(c => c.role_id === role.id).length - 3}
                                    </span>
                                  }
                                  </div>
                                </div>
                              </div>
                              <div className="ml-5 flex-shrink-0">
                                <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                              </div>
                            </div>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </section>
  )
}