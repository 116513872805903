import React, { useState } from 'react'
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useHistory } from 'react-router';
import { ExclamationIcon } from '@heroicons/react/outline';

function Pretend() {
  const [cookies] = useCookies();
  const [pretendCookie, setPretendCookie] = useState(cookies.pretend)
  const history = useHistory();

  const exitPretend = async () => {
    await axios.delete('/pretend')
    setPretendCookie(null)
    history.push('/')
  }
  
  if(!pretendCookie) { return null }

  return (
    <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5 z-20">
      <div className="max-w-xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="p-2 rounded-lg bg-red-600 shadow-lg sm:p-3">
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-0 flex-1 flex items-center">
              <span className="flex p-2 rounded-lg bg-red-800">
                <ExclamationIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </span>
              <p className="ml-3 font-medium text-white truncate">
                <span>Pretend Mode</span>
                {/* <span className="hidden md:inline">Pretend Mode! We're excited to announce a brand new product.</span> */}
              </p>
            </div>
            <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
              <button
                onClick={() => {exitPretend()}}
                className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-red-600 bg-white hover:bg-red-50"
              >
                Exit
              </button>
            </div>
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
              <button
                type="button"
                className="-mr-1 flex p-2 rounded-md hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-white"
              >
                <span className="sr-only">Dismiss</span>
                {/* <XIcon className="h-6 w-6 text-white" aria-hidden="true" /> */}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pretend;