import React, { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { NavLink } from 'react-router-dom'
import { useAuth } from '../Shared/AuthContext'
import Logo from './assets/logo.png'
import LogoText from './assets/logo-text.png'

export default function NavBar() {
  const {currentUser, logout} = useAuth()

  return (
    <Disclosure as="nav" className="bg-indigo-600 border-b border-indigo-300 border-opacity-25 sm:border-none">
      {({ open }) => (
        <>
        <div className="w-screen">
          <div className="max-w-7xl mx-auto px-2 sm:px-4 sm:px-8">
            <div className="relative h-16 flex items-center justify-between sm:border-b sm:border-indigo-400 sm:border-opacity-25">
              <div className="px-2 flex items-center sm:px-0">
                <div className="flex-shrink-0">
                  <NavLink exact to='/' className="hidden sm:block">
                    <img
                      className="block h-12 w-12"
                      src={Logo}
                      alt="Drumcircle"
                    />
                  </NavLink>
                  <NavLink exact to='/' className="sm:hidden">
                    <img
                      className="block h-8 w-auto"
                      src={LogoText}
                      alt="Drumcircle"
                    />
                  </NavLink>
                </div>
                <div className="hidden sm:block sm:ml-4">
                  <div className="flex space-x-4">
                    <NavLink exact to="/" className="text-white hover:bg-indigo-500 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm font-medium" activeClassName="bg-indigo-700 text-white rounded-md py-2 px-3 text-sm font-medium">Dashboard</NavLink>
                    <NavLink exact to="/integrations" className="text-white hover:bg-indigo-500 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm font-medium" activeClassName="bg-indigo-700 text-white rounded-md py-2 px-3 text-sm font-medium">Integrations</NavLink>
                    <NavLink exact to="/help" className="text-white hover:bg-indigo-500 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm font-medium" activeClassName="bg-indigo-700 text-white rounded-md py-2 px-3 text-sm font-medium">Help</NavLink>
                  </div>
                </div>
              </div>
              
              <div className="flex sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="bg-indigo-600 p-2 rounded-md inline-flex items-center justify-center text-indigo-200 hover:text-white hover:bg-indigo-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="hidden sm:block sm:ml-4">
                <div className="flex items-center">
                  

                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative flex-shrink-0">
                    <div>
                      <Menu.Button className="bg-indigo-600 rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white">
                        <span className="sr-only">Open user menu</span>
                        <img className="rounded-full h-8 w-8" src={currentUser?.avatar} alt="" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-32 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item key='profile'>
                          <NavLink to='/settings/profile' className='block py-2 px-4 text-sm text-gray-700'>Profile</NavLink>
                        </Menu.Item>
                        <Menu.Item key='branding'>
                          <NavLink to='/settings/branding' className='block py-2 px-4 text-sm text-gray-700'>Branding</NavLink>
                        </Menu.Item>
                        <Menu.Item key='team'>
                          <NavLink to='/settings/team' className='block py-2 px-4 text-sm text-gray-700'>Team</NavLink>
                        </Menu.Item>
                        <Menu.Item key='billing'>
                          <NavLink to='/settings/billing' className='block py-2 px-4 text-sm text-gray-700'>Billing</NavLink>
                        </Menu.Item>
                        <Menu.Item key='settings'>
                          <button onClick={(e) => {e.preventDefault(); logout()}} className='block py-2 px-4 text-sm text-gray-700'>Sign out</button>
                        </Menu.Item>  
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <Disclosure.Button as={NavLink} exact to="/" className="text-white hover:bg-indigo-500 hover:bg-opacity-75 block rounded-md py-2 px-3 text-base font-medium" activeClassName="bg-indigo-700 text-white block rounded-md py-2 px-3 text-base font-medium">Dashboard</Disclosure.Button>
              <Disclosure.Button as={NavLink} exact to="/integrations" className="text-white hover:bg-indigo-500 hover:bg-opacity-75 block rounded-md py-2 px-3 text-base font-medium" activeClassName="bg-indigo-700 text-white block rounded-md py-2 px-3 text-base font-medium">Integrations</Disclosure.Button>
              <Disclosure.Button as={NavLink} exact to="/help" className="text-white hover:bg-indigo-500 hover:bg-opacity-75 block rounded-md py-2 px-3 text-base font-medium" activeClassName="bg-indigo-700 text-white block rounded-md py-2 px-3 text-base font-medium">Help</Disclosure.Button>
            </div>
            <div className="pt-4 pb-3 border-t border-indigo-700">
              <div className="px-5 flex items-center">
                <div className="flex-shrink-0">
                  <img className="rounded-full h-10 w-10" src={currentUser?.avatar} alt="" />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-white">{`${currentUser?.first_name} ${currentUser?.last_name}`}</div>
                  <div className="text-sm font-medium text-indigo-300">{currentUser?.email}</div>
                </div>
              </div>
              <div className="mt-3 px-2 space-y-1">
                <Disclosure.Button as={NavLink} to='/settings/profile' className='block rounded-md py-2 px-3 text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75'>Profile</Disclosure.Button>
                <Disclosure.Button as={NavLink} to='/settings/branding' className='block rounded-md py-2 px-3 text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75'>Branding</Disclosure.Button>
                <Disclosure.Button as={NavLink} to='/settings/team' className='block rounded-md py-2 px-3 text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75'>Team</Disclosure.Button>
                <Disclosure.Button as={NavLink} to='/settings/billing' className='block rounded-md py-2 px-3 text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75'>Billing</Disclosure.Button>
                <button onClick={(e) => {e.preventDefault(); logout()}} className='block w-full text-left rounded-md py-2 px-3 text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75'>Sign out</button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}