import React from "react"
import { RoleStage, RoleStageFile } from "../../Shared/types"
import StageFileEditor from "./StageFileEditor"
import GreenhouseStageBadge from "./GreenhouseStageBadge"
import StageMessageEditor from "./StageMessageEditor"

interface StageSettingsProps {
  stage: RoleStage
  setStage: (stage: RoleStage) => void
  setStageTitle: (stageId: string, title: string) => void
  deleteStage: (stageIndex: number) => void
}

function StageSettings(props: StageSettingsProps) {
  const setStageMessage = (message: string | null) => {
    props.setStage({...props.stage, message: message})
  }

  const setStageMessageTitle = (title: string | null) => {
    props.setStage({...props.stage, message_title: title})
  }

  const setStageFiles = (files: Array<RoleStageFile>) => {
    props.setStage({...props.stage, files: files})
  }
  
  return (
    <div className="">
      <div className="bg-gray-50 px-4 py-3 border-b border-gray-200 text-center">
        <h3 className="text-sm leading-6 font-medium text-gray-900">Stage Settings</h3>
      </div>
      
      <div className="p-4 space-y-6">
        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-6">
            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">Name</label>
            <div className="mt-1">
              <input type="text" name="intro-text" id="intro-text" autoComplete="given-name"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                value={props.stage.title}
                onChange={(e) => { props.setStageTitle(props.stage.id, e.target.value)}}
              />
            </div>
          </div>
        </div>

        {props.stage.source && 
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                Greenhouse stage
              </label>
              <div className="mt-1">
                <GreenhouseStageBadge stage={props.stage} />
              </div>
            </div>
          </div>
        }

        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-6">
            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
              Message
            </label>
            <div className="mt-1">
              <StageMessageEditor stage={props.stage} messageTitle={props.stage.message_title} setMessageTitle={setStageMessageTitle} message={props.stage.message} setMessage={setStageMessage}/>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-6">
            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
              Files
            </label>
            <div className="mt-1">
              <StageFileEditor files={props.stage.files} setFiles={setStageFiles}/>
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="button"
            className="inline-flex items-center px-5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => { props.deleteStage(props.stage.index) }}
          >
            Delete stage
          </button>
        </div>

      </div>
    </div>
  )
}

export default StageSettings

