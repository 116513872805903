/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useState, Fragment } from 'react';
import { AssigneeOptions, Candidate, Role, User } from '../Shared/types';
import { Dialog, Listbox, RadioGroup, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { PlusSmIcon, SelectorIcon, CheckIcon } from '@heroicons/react/solid'
import { useAuth } from '../Shared/AuthContext';
import ConfirmEmailModal from '../Shared/ConfirmEmailModal';
import PaywallModal from '../Shared/PaywallModal';
import classNames from 'classnames';

interface CreateCandidateProps {
  candidates: Array<Candidate>
  addCandidate: (candidate: Candidate) => void
}

function CreateCandidate(props: CreateCandidateProps) {
  const [candidateFirstName, setCandidateFirstName] = useState('')
  const [candidateFirstNameRequired, setCandidateFirstNameRequired] = useState(false)

  const [candidateLastName, setCandidateLastName] = useState('')
  const [candidateLastNameRequired, setCandidateLastNameRequired] = useState(false)

  const [candidateEmail, setCandidateEmail] = useState('')
  const [candidateEmailRequired, setCandidateEmailRequired] = useState(false)
  
  const [open, setOpen] = useState(false)
  const [openConfirmEmail, setOpenConfirmEmail] = useState(false)
  const [openPaywall, setOpenPaywall] = useState(false)
  const [roles, setRoles] = useState(new Array<Role>());

  const [members, setMembers] = useState(Array<User>())

  const [assigneeOptions, setAssigneeOptions] = useState(Array<AssigneeOptions>())

  const [selected, setSelected] = useState(null as AssigneeOptions | null)
  const [roleId, setRoleId] = useState(null as string | null)
  const [subscribeEmail, setSubscribeEmail] = useState(false)

  const { currentUser, subscriptions } = useAuth()

  useEffect(() => { setCandidateFirstNameRequired(false) }, [candidateFirstName])
  useEffect(() => { setCandidateLastNameRequired(false) }, [candidateLastName])
  useEffect(() => { setCandidateEmailRequired(false) }, [candidateEmail])

  useEffect(() => {
    setSelected(assigneeOptions[0])
  }, [assigneeOptions])

  useEffect(() => { if(roles.length > 0) {setRoleId(roles[0].id) } }, [roles])

  const createCandidate = async () => {
    if(candidateFirstName === '') { setCandidateFirstNameRequired(true) }
    if(candidateLastName === '') { setCandidateLastNameRequired(true) }
    if(candidateEmail === '') { setCandidateEmailRequired(true) }

    if(candidateFirstName === '' || candidateLastName === '' || candidateEmail === ' ') { return }

    const response = await axios.post('/candidates', {subscribed_email: subscribeEmail, assignee_id: selected?.key as string, email: candidateEmail, first_name: candidateFirstName, last_name: candidateLastName, role_id: roleId})
    
    props.addCandidate(response.data)
    setCandidateFirstName('')
    setCandidateLastName('')
    setCandidateEmail('')
    setSubscribeEmail(false)
    
    setOpen(false)
  }

  const fetchRoles = () => {
    axios.get('/roles')
      .then(response => {
        setRoles(response.data.roles)
      })
      .catch(error => console.log(error))
  }

  const fetchMembers = () => {
    axios.get('/organization/members')
      .then(response => {
        setMembers(response.data.members)
      })
      .catch(error => console.log(error))
  }
  useEffect(() => { fetchRoles() }, []);
  useEffect(() => { fetchMembers() }, []);

  useEffect(() => {
    if(!roleId) { return }

    let newAssigneeOptions = Array<AssigneeOptions>()

    newAssigneeOptions.push({
      key: currentUser?.id!,
      text: `${currentUser?.first_name} ${currentUser?.last_name}`,
      value: currentUser?.id!,
      image: { avatar: true, src: currentUser?.avatar! }
    })

    members.forEach((member) => {
      if(member.id === currentUser?.id) { return }

      newAssigneeOptions.push({
        key: member.id,
        text: `${member.first_name} ${member.last_name}`,
        value: member.id,
        image: { avatar: true, src: member.avatar }
      })
    })

    setAssigneeOptions(newAssigneeOptions)
  }, [roleId, members])

  const openCreateCandidateModal = () => {
    if(currentUser && !currentUser.email_confirmed) { 
      setOpenConfirmEmail(true)
    } else if(subscriptions[0] && subscriptions[0].candidates_created >= subscriptions[0].candidate_count) {
      setOpenPaywall(true)
    } else {
      setOpen(true)
    }
  }


  return (
    <>
      <PaywallModal title="Upgrade your plan" description={`You have used all of your candidates this month. Upgrade or contact us at sales@drumcircle.io`} open={openPaywall} setOpen={setOpenPaywall}/>
      <ConfirmEmailModal open={openConfirmEmail} setOpen={setOpenConfirmEmail}/>
      <span className="block shadow-2xl">
        <button
          type="button"
          onClick={openCreateCandidateModal}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm text-white bg-indigo-500 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <PlusSmIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Candidate
        </button>
      </span>
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <form className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                  <div className="flex-1 h-0 overflow-y-auto">
                    <div className="py-6 px-4 bg-gray-800 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">Create Candidate</Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-gray-800 rounded-md text-indigo-200 hover:text-white focus:outline-none"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-gray-100">
                          Fill in the candidate information and select their role.
                        </p>
                      </div>
                    </div>
                    <div className="flex-1 flex flex-col justify-between">
                      <div className="px-4 divide-y divide-gray-200 sm:px-6">
                        <div className="space-y-6 pt-6">
                        <div className="grid grid-cols-12 gap-6">
                          <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                              First name
                            </label>
                            <input
                              type="text"
                              name="first-name"
                              id="first-name"
                              autoComplete="given-name"
                              required
                              value={candidateFirstName}
                              onChange={(e) => { setCandidateFirstName(e.target.value) }}
                              className={classNames(candidateFirstNameRequired ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500' : '', "mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm")}
                            />
                            {candidateFirstNameRequired &&
                              <p className="mt-2 text-sm text-red-600" id="first-name-error">
                                First name required
                              </p>
                            }
                          </div>

                          <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                              Last name
                            </label>
                            <input
                              type="text"
                              name="last-name"
                              id="last-name"
                              autoComplete="family-name"
                              value={candidateLastName}
                              onChange={(e) => { setCandidateLastName(e.target.value) }}
                              className={classNames(candidateLastNameRequired ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500' : '', "mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm")}
                            />
                            {candidateLastNameRequired &&
                              <p className="mt-2 text-sm text-red-600" id="last-name-error">
                                Last name required
                              </p>
                            }
                          </div>
                          </div>
                          <div>
                            <label htmlFor="project-name" className="block text-sm font-medium text-gray-900">
                              Email
                            </label>
                            <div className="mt-1">
                              <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                value={candidateEmail}
                                onChange={(e) => { setCandidateEmail(e.target.value) }}
                                className={classNames(candidateEmailRequired ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500' : '', "block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md")}
                              />
                              {candidateEmailRequired &&
                              <p className="mt-2 text-sm text-red-600" id="last-name-error">
                                Email required
                              </p>
                            }
                            </div>
                          </div>
                          
                          <fieldset>
                            <legend className="text-sm font-medium text-gray-900">Role</legend>
                            <RadioGroup value={roleId} onChange={setRoleId}>
                            <RadioGroup.Label className="sr-only">Role</RadioGroup.Label>
                            <div className="bg-white rounded-md -space-y-px mt-1">
                              {roles.map((role, roleIdx) => (
                                <RadioGroup.Option
                                  key={role.id}
                                  value={role.id}
                                  className={({ checked }) =>
                                    classNames(
                                      roleIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                                      roleIdx === roles.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                                      checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200',
                                      'relative border p-4 flex cursor-pointer focus:outline-none'
                                    )
                                  }
                                >
                                  {({ active, checked }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                                          active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                                          'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                                        )}
                                        aria-hidden="true"
                                      >
                                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                                      </span>
                                      <div className="ml-3 flex flex-col">
                                        <RadioGroup.Label
                                          as="span"
                                          className={classNames(checked ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium')}
                                        >
                                          {role.title}
                                        </RadioGroup.Label>
                                        <RadioGroup.Description
                                          as="span"
                                          className={classNames(checked ? 'text-indigo-700' : 'text-gray-500', 'block text-sm')}
                                        >
                                          {role.description}
                                        </RadioGroup.Description>
                                      </div>
                                    </>
                                  )}
                                </RadioGroup.Option>
                              ))}
                            </div>
                          </RadioGroup>
                          </fieldset>
                      {selected &&
                          <div>
                            <h3 className="text-sm font-medium text-gray-900">Assigned to</h3>
                            <div className="mt-1">
                            <Listbox value={selected} onChange={setSelected}>
                              <div className="mt-1 relative">
                                <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                  <span className="flex items-center">
                                    <img src={selected.image.src} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
                                    <span className="ml-3 block truncate">{selected.text}</span>
                                  </span>
                                  <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                  </span>
                                </Listbox.Button>

                                <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                                  <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                    {assigneeOptions.map((person) => (
                                      <Listbox.Option
                                        key={person.key}
                                        className={({ active }) =>
                                          classNames(
                                            active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                            'cursor-default select-none relative py-2 pl-3 pr-9'
                                          )
                                        }
                                        value={person}
                                      >
                                        {({ selected, active }) => (
                                          <>
                                            <div className="flex items-center">
                                              <img src={person.image.src} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
                                              <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}>
                                                {person.text}
                                              </span>
                                            </div>

                                            {selected ? (
                                              <span
                                                className={classNames(
                                                  active ? 'text-white' : 'text-indigo-600',
                                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                                )}
                                              >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </Listbox>
                            </div>
                          </div>
}
{roleId &&
                          <div className="">
                    {/* <div>
                      <h2 className="text-lg leading-6 font-medium text-gray-900">Settings</h2>
                    </div> */}
                    {/* <ul className="divide-y divide-gray-200">
                      <Switch.Group as="li" className="flex items-center justify-between">
                        <div className="flex flex-col">
                          <Switch.Label as="p" className="text-sm font-medium text-gray-900" passive>
                            Subscribe email
                          </Switch.Label>
                          <Switch.Description className="text-sm text-gray-500">
                            Automatically subscribe the candidate to email notifications for this experience.
                          </Switch.Description>
                        </div>
                        <Switch
                          checked={subscribeEmail}
                          onChange={setSubscribeEmail}
                          className={classNames(
                            subscribeEmail ? 'bg-teal-500' : 'bg-gray-200',
                            'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              subscribeEmail ? 'translate-x-5' : 'translate-x-0',
                              'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                            )}
                          />
                        </Switch>
                      </Switch.Group>
                    </ul> */}
                  </div>

}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                    <button
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={createCandidate}
                      className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </>
  )
}

export default CreateCandidate;
