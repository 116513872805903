import React, { useEffect, useState } from 'react'
import { UsersIcon, CodeIcon, CalendarIcon, LightningBoltIcon } from '@heroicons/react/outline'

import CalendlySidebar from './CalendlySidebar'
import WebhookSidebar from './WebhookSidebar'
import ZapierSidebar from './ZapierSidebar'
import { usePage } from '../../Shared/PageContext'
import GreenhouseModal from './GreenhouseModal'

function classNames(...classes: Array<any>) {
  return classes.filter(Boolean).join(' ')
}

export default function IntegrationsLanding() {
  const [openGreenhouse, setOpenGreenhouse] = useState(false)
  const [openCalendly, setOpenCalendly] = useState(false)
  const [openZapier, setOpenZapier] = useState(false)
  const [openWebhook, setOpenWebhook] = useState(false)

  const { setPage } = usePage()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { setPage({title: 'Integrations'}) }, []);

  const tiles = [
    {title: 'Greenhouse', action: () => { setOpenGreenhouse(true) }, icon: UsersIcon, iconForeground: 'text-teal-700', iconBackground: 'bg-teal-50', description: 'Create Roles from your Greenhouse roles and automatically transition candidates based on your Greenhouse workflows'},
    {title: 'Calendly', action: () => { setOpenCalendly(true) }, icon: CalendarIcon, iconForeground: 'text-purple-700', iconBackground: 'bg-purple-50', description: 'Seamlessly allow Candidates to schedule with your team and track upcoming events directly in Drumcircle.'},
    {title: 'Zapier - Coming Soon', action: () => { /*setOpenZapier(true)*/ }, icon: LightningBoltIcon, iconForeground: 'text-sky-700', iconBackground: 'bg-sky-50', description: 'Integrate with your applications and automate your custom workflows to make Drumcircle work for you.'},
    {title: 'Webhooks - Coming Soon', action: () => { /*setOpenWebhook(true)*/ }, icon: CodeIcon, iconForeground: 'text-rose-700', iconBackground: 'bg-rose-50', description: 'Developers can integrate with our Webhook integration to build custom workflows and integrations.' },
  ]
  
  return (
    <div className="px-3 pb-6">
      <div className="mx-auto max-w-sm md:max-w-lg lg:max-w-5xl rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 lg:divide-y-0 lg:grid lg:grid-cols-2 lg:gap-px">
        <GreenhouseModal open={openGreenhouse} setOpen={setOpenGreenhouse} />
        <CalendlySidebar open={openCalendly} setOpen={setOpenCalendly} />
        <ZapierSidebar open={openZapier} setOpen={setOpenZapier} />
        <WebhookSidebar open={openWebhook} setOpen={setOpenWebhook} />
        {tiles.map((tile, tileIndex) => (
          <div
            key={tile.title}
            className={classNames(
              tileIndex === 0 ? 'rounded-tl-lg rounded-tr-lg lg:rounded-tr-none' : '',
              tileIndex === 1 ? 'lg:rounded-tr-lg' : '',
              tileIndex === tiles.length - 2 ? 'lg:rounded-bl-lg' : '',
              tileIndex === tiles.length - 1 ? 'rounded-bl-lg rounded-br-lg lg:rounded-bl-none' : '',
              'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
            )}
          >
            <div>
              <span
                className={classNames(
                  tile.iconBackground,
                  tile.iconForeground,
                  'rounded-lg inline-flex p-3 ring-4 ring-white'
                )}
              >
                <tile.icon className="h-6 w-6" aria-hidden="true" />
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-lg font-medium">
                <button className="focus:outline-none" onClick={tile.action}>
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true" />
                  {tile.title}
                </button>
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                {tile.description}
              </p>
            </div>
            <span
              className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
              aria-hidden="true"
            >
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
              </svg>
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}
