import { Transition } from "@headlessui/react";
import { EyeIcon, XIcon } from "@heroicons/react/outline";
import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../Shared/AuthContext";
import { useExperience } from "../ExperienceContext";

export default function ExperiencePreviewBanner()  {
  const { currentUser } = useAuth()
  const { candidate } = useExperience()
  const [show, setShow] = useState(true)

  if(!currentUser) { return null }
  
  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="">
        <div className="relative bg-indigo-600">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-0 flex-1 flex items-center">
              <span className="flex p-2 rounded-lg bg-indigo-800">
                <EyeIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </span>
              <p className="ml-3 font-medium text-white truncate">
                <span className="md:hidden">Preview mode!</span>
                <span className="hidden md:inline">Preview mode! You are viewing the candidate experience.</span>
              </p>
            </div>
            <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
              <NavLink
                to={`/candidate/${candidate.slug}`}
                className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50"
              >
                View Candidate
              </NavLink>
            </div>
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
              <button
                onClick={() => { setShow(false) }}
                className="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
              >
                <span className="sr-only">Dismiss</span>
                <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </div>
          </div>
        </div>
      </div>
    </Transition>
  )
}