import { CheckIcon, PaperClipIcon } from "@heroicons/react/solid";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useMixPanel } from "react-mixpanel-provider-component";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { usePage } from "../../Shared/PageContext";
import { CandidateStagePreview, CandidateStage, CandidateActivity, Candidate } from "../../Shared/types";
import CandidateActivitySection from "../Shared/CandidateActivity";

interface CandidateParams {
  candidateSlug: string
}

function classNames(...classes: Array<any>) {
  return classes.filter(Boolean).join(' ')
}

export default function CandidateDetails() {
  const [loadingCandidate, setLoadingCandidate] = useState(true)
  const { candidateSlug } = useParams<CandidateParams>()
  const { setPage } = usePage()
  const { mixpanel } = useMixPanel()
  const history = useHistory()

  const [candidate, setCandidate] = useState({
    candidate_stage_previews: Array<CandidateStagePreview>(),
    candidate_stages: Array<CandidateStage>(),
    activity: Array<CandidateActivity>()
  } as Candidate)

  
  const fetchCandidate = async () => {
    const response = await axios.get(`/candidates/${candidateSlug}`)
    setCandidate(response.data)
    setLoadingCandidate(false)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchCandidate() }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { setPage({title:'Candidate'}) }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { setPage({title:'Candidate', controls: candidateControls()}) }, [candidate]);

  const copyLink = () => {
    const candidateLinkUrl = `${window.location.protocol}//${window.location.host}/e/${candidate.slug}`
    navigator.clipboard.writeText(candidateLinkUrl)
  }

  const activate = async () => {
    await axios.post(`/candidates/${candidate.slug}/activate`)
    setCandidate({...candidate, archived_at: undefined})
    mixpanel.track('Experience - Activate', {experienceId: candidate.id, flowId: candidate.role_id})
  }

  const deleteCandidate = async () => {
    await axios.delete(`/candidates/${candidate.slug}`)
    history.push('/')
  }

  const archive = async () => {
    const response = await axios.delete(`/candidates/${candidate.slug}`)
    setCandidate(response.data)
    mixpanel.track('Experience - Archive', {experienceId: candidate.id, flowId: candidate.role_id})
  }

  const candidateControls = () => {
    if(candidate.archived_at) {
      return (
        <>
          <span className="block shadow-2xl">
            <button
              type="button"
              onClick={activate}
              className="inline-flex items-center px-4 py-2 mr-2 border border-transparent rounded-md shadow-sm text-sm text-white bg-indigo-500 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {/* <PlusSmIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" /> */}
              Activate
            </button>
          </span>

          <span className="block shadow-2xl">
            <button
              type="button"
              onClick={deleteCandidate}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm text-white bg-indigo-500 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {/* <PlusSmIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" /> */}
              Delete
            </button>
          </span>
        </>
      )
    }

    return (
      <>
        <span className="block shadow-2xl">
          <button
            type="button"
            onClick={archive}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm text-white bg-indigo-500 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {/* <PlusSmIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" /> */}
            Archive
          </button>
        </span>
      </>
    )

  }
  

  if(loadingCandidate) {
    return <main className="-mt-24 pb-8"><div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8"></div></main>
  }

  const files = candidate.candidate_stages.flatMap((stage) => { return stage.files  })

  return (
    <>
      <main className="-mt-24 pb-8">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <h1 className="sr-only">Profile</h1>
          {/* Main 3 column grid */}
          <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
            {/* Left column */}
            <div className="grid grid-cols-1 gap-4 lg:col-span-2">
              <section aria-labelledby="applicant-information-title">
              <div className="bg-white shadow rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <div className="bg-white">
                    {candidate.archived_at &&
                      <span className="block sm:hidden ml-4 mt-1.5 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">Archived</span>
                    }
                    <div className="sm:flex sm:items-center sm:justify-between">
                      <div className="sm:flex sm:space-x-5">
                        <div className="flex-shrink-0">
                          <img className="mx-auto h-16 w-16 rounded-full" src={candidate.avatar} alt="" />
                        </div>
                        <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                          <p className="">
                            <span className="text-xl inline-flex font-bold text-gray-900 sm:text-2xl">{candidate.name}</span>
                            {candidate.archived_at &&
                              <span className="hidden sm:block float-right ml-4 mt-1.5 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">Archived</span>
                            }
                           
                          </p>
                          <p className="text-sm font-medium text-gray-600">{candidate.email}</p>
                        </div>
                      </div>
                      <div className="mt-5 flex justify-center sm:mt-0">
                        <NavLink
                          exact to={`/e/${candidate.slug}`}
                          className="flex justify-center items-center px-4 py-2 mr-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        >
                          View Experience
                        </NavLink>
                        <button
                          onClick={copyLink}
                          className="flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        >
                          Copy Link
                        </button>
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Role</dt>
                      <dd className="mt-1 text-sm text-gray-900">{candidate.role_title}</dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Stage</dt>
                      <dd className="mt-1 text-sm text-gray-900">{candidate.candidate_stages[candidate.stage].title}</dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Assignee</dt>
                      <dd className="mt-1 text-sm text-gray-900">{`${candidate.assignee.first_name} ${candidate.assignee.last_name}`}</dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Last Activity</dt>
                      <dd className="mt-1 text-sm text-gray-900">{moment(candidate.updated_at).fromNow()}</dd>
                    </div>
                  
                    {files.length > 0 &&
                      <div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-gray-500">Attachments</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                            {files.map((attachment) => (
                              <li
                                key={attachment.name}
                                className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                              >
                                <div className="w-0 flex-1 flex items-center">
                                  <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                                  <span className="ml-2 flex-1 w-0 truncate">{attachment.name}</span>
                                </div>
                                <div className="ml-4 flex-shrink-0">
                                  <a href={attachment.url} className="font-medium text-blue-600 hover:text-blue-500">
                                    Download
                                  </a>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </dd>
                      </div>
                    }
                  </dl>
                </div>
              </div>
            </section>

            <section aria-labelledby="notes-title">
              <div className="bg-white shadow rounded-lg sm:overflow-hidden">
                <div className="divide-y divide-gray-200">
                  <div className="px-4 py-5 sm:px-6">
                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                      Activity
                    </h2>
                  </div>
                  <div className="px-4 py-5 sm:px-6">
                    <CandidateActivitySection candidate={candidate} updateCandidate={setCandidate} disabled={!!candidate.archived_at}/>
                  </div>
                </div>
              </div>
            </section>

            </div>

            {/* Right column */}
            <CandidateTimeline candidate={candidate} setCandidate={setCandidate} />
          </div>
        </div>
      </main>
    </>
  )
}

interface CandidateTimelineProps {
  candidate: Candidate
  setCandidate: React.Dispatch<React.SetStateAction<Candidate>>
}
function CandidateTimeline(props: CandidateTimelineProps) {
  const { mixpanel } = useMixPanel()

  const advance = async () => {
    const response = await axios.put(`/candidates/${props.candidate.slug}`, {stage: props.candidate.stage + 1})
    props.setCandidate(response.data)
    mixpanel.track('Experience - Advance', {experienceId: props.candidate.id, flowId: props.candidate.role_id})
  }

  const archive = async () => {
    const response = await axios.delete(`/candidates/${props.candidate.slug}`)
    props.setCandidate(response.data)
    mixpanel.track('Experience - Archive', {experienceId: props.candidate.id, flowId: props.candidate.role_id})
  }

  const transitions = props.candidate.activity.filter((a) => a.activity_type === 'transition')


  const steps2 = props.candidate.candidate_stage_previews.map((preview) => {
    let status = 'upcoming'
    let timestamp

    if(props.candidate.stage > preview.index) { 
      status = 'complete'
      timestamp = transitions[preview.index]?.updated_at
    }
    if(props.candidate.stage === preview.index) {
      status = 'current'
      timestamp = transitions[preview.index]?.updated_at
  }
    
    return { name: preview.title, description: preview.description, status: status, timestamp: timestamp}
  })


  return (
    <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
      <div className="bg-white px-4 py-5 shadow rounded-lg sm:px-6">
        <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
          Timeline
        </h2>

        <div className="mt-6 flow-root">
        <nav aria-label="Progress">
      <ol className="overflow-hidden">
        {steps2.map((step, stepIdx) => (
          <li key={step.name} className={classNames(stepIdx !== steps2.length - 1 ? 'pb-10' : '', 'relative')}>
            {step.status === 'complete' ? (
              <>
                {stepIdx !== steps2.length - 1 ? (
                  <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-indigo-600" aria-hidden="true" />
                ) : null}
                <span className="relative flex items-start group">
                  <span className="h-9 flex items-center">
                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                      <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                    </span>
                  </span>
                  <div className="min-w-0 flex-1 flex justify-between">
                    <span className="ml-4 min-w-0 flex flex-col">
                      <span className="text-xs font-semibold tracking-wide uppercase">{step.name}</span>
                      <span className="text-sm text-gray-500">{step.description}</span>
                    </span>
                    <div className="text-right text-sm whitespace-nowrap text-gray-500">
                      <time dateTime={step.timestamp}>{moment(step.timestamp).format("MMM D")}</time>
                    </div>
                  </div>
                </span>
              </>
            ) : step.status === 'current' ? (
              <>
                {stepIdx !== steps2.length - 1 ? (
                  <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true" />
                ) : null}
                <span className="relative flex items-start group" aria-current="step">
                  <span className="h-9 flex items-center" aria-hidden="true">
                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full">
                      <span className="h-2.5 w-2.5 bg-indigo-600 rounded-full" />
                    </span>
                  </span>
                  <div className="min-w-0 flex-1 flex justify-between">
                    <span className="ml-4 min-w-0 flex flex-col">
                      <span className="text-xs font-semibold tracking-wide uppercase text-indigo-600">{step.name}</span>
                      <span className="text-sm text-gray-500">{step.description}</span>
                    </span>
                    <div className="text-right text-sm whitespace-nowrap text-gray-500">
                      <time dateTime={step.timestamp}>{moment(step.timestamp).format("MMM D")}</time>
                    </div>
                  </div>
                </span>
              </>
            ) : (
              <>
                {stepIdx !== steps2.length - 1 ? (
                  <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true" />
                ) : null}
                <span className="relative flex items-start group">
                  <span className="h-9 flex items-center" aria-hidden="true">
                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                      <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                    </span>
                  </span>
                  <span className="ml-4 min-w-0 flex flex-col">
                    <span className="text-xs font-semibold tracking-wide uppercase text-gray-500">{step.name}</span>
                    <span className="text-sm text-gray-500">{step.description}</span>
                  </span>
                </span>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>


        </div>
        <div className="mt-6 flex flex-col justify-stretch">
          {props.candidate.stage !== props.candidate.candidate_stage_previews.length - 1 && !props.candidate.archived_at &&
            <button
              type="button"
              onClick={advance}
              className="flex-shrink-0 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:flex-1"
            >
              Advance
            </button>
          }
          {props.candidate.stage === props.candidate.candidate_stage_previews.length -1 && !props.candidate.archived_at &&
            <button
              type="button"
              onClick={archive} 
              className="flex-shrink-0 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:flex-1"
            >
            Archive
          </button>
          }
        </div>
      </div>
    </section>
  )
}