import { LightningBoltIcon, ScaleIcon, UsersIcon, CalendarIcon, IdentificationIcon, ClockIcon, ColorSwatchIcon, PuzzleIcon } from '@heroicons/react/outline'

import {
  ChatAltIcon,
  DocumentReportIcon,
  HeartIcon,
  PencilAltIcon,
} from '@heroicons/react/outline'
import Layout from './Layout'
import { useState } from 'react'
import SampleImage3 from './assets/samplev2-4.png'
import SampleImage5 from './assets/samplev2-5.png'
import GreenhouseAvailability from './assets/greenhouse-availability.png'
import AvailabilityTile from './assets/availability-tile.png'
import GreenhouseMessage from './assets/greenhouse-message.png'
const blogPosts = [
  {
    id: 1,
    title: 'Greenhouse Integration',
    href: '/features/greenhouse',
    moreText: 'Learn more',
    imageUrl: 'https://images.unsplash.com/photo-1518893494013-481c1d8ed3fd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80',
    preview: 'Learn how our Greenhouse Integration lets your team create experiences directly from their existing workflow.',
  },
  {
    id: 2,
    title: 'Interview Scheduling',
    href: '/features/interviews',
    moreText: 'Learn more',
    imageUrl: 'https://images.unsplash.com/photo-1506784365847-bbad939e9335?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80',
    preview: 'Wether you are scheduling with Calendly, with Greenhouse, or manually via email, Drumcircle brings all invites together.',
  },
  {
    id: 3,
    title: 'Integrate with Zapier',
    href: null,
    moreText: 'Coming soon',
    imageUrl: 'https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80',
    preview: 'Build your own custom integrations by utilizing our Zapier hooks to connect with any system you need.',
  },
]

const features = [
  {
    name: 'Unlimited Users',
    description: 'Everyone on your team can have their own account.',
    // description: 'Invite everyone in your recruiting team to get the most out of your process',
    // description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
    icon: UsersIcon,
  },
  {
    name: 'Unlimited Roles',
    description: 'No matter how many roles you have open, your covered.',
    icon: ColorSwatchIcon,
  },
  {
    name: 'Integrations',
    description: 'Powerful integrations mean everything just works.',
    icon: PuzzleIcon,
  },
  {
    name: 'Automation',
    description: 'Automatically send messages and share files at the right stage.',
    icon: PencilAltIcon,
  },
  {
    name: 'File Sharing',
    description: 'Share and receive files so everyone has the files they need.',
    icon: DocumentReportIcon,
  },
  {
    name: 'Interviews',
    description: 'Give candidates clarity about upcoming interviews.',
    icon: CalendarIcon,
  },
  {
    name: 'Chat & Email',
    description: 'Chat and email support enables communication throughout.',
    icon: ChatAltIcon,
  },
  {
    name: 'Delight Candidates', //Employer Branding?
    description: 'Impress every candidate you interact with.',
    icon: HeartIcon,
  },
]

const steps = [
  { name: 'Application Review', description: 'Thank you for applying!', href: '#', status: 'complete' },
  {
    name: 'Talk with a Recruiter',
    description: 'Lets discuss the role in more detail.',
    href: '#',
    status: 'current',
  },
  { name: 'Coding Assignment', description: 'Small assignment to help us evaluate your skills.', href: '#', status: 'upcoming' },
  { name: 'Team Interview', description: 'Meet with the team and deep dive into the role as well as your skills and experience.', href: '#', status: 'upcoming' },
  { name: 'Final Decision', description: 'Let you know if we will extend an offer.', href: '#', status: 'upcoming' },
]

// const transferFeatures = [
//   {
//     id: 1,
//     name: 'Everything in one place',
//     description:
//       "Interviews are more complex than ever. Emails, file uploads, Zoom meetings, introductions and even parking instructions. Deliver a single delightful package for your candidates.",
//     icon: IdentificationIcon,
//   },
//   {
//     id: 2,
//     name: 'Engaging & transparent process',
//     description:
//       "In today's market, the best candidates demand a clear understanding of their interview experience and their progress.",
//     icon: ScaleIcon,
//   },
//   {
//     id: 3,
//     name: 'Time saving automation',
//     description:
//       "Stop wasting time and let Drumcircle automate your candidate experience process. Save time and reduce mistakes and confusion.",
//     icon: ClockIcon,
//   },
// ]
// const communicationFeatures = [
//   {
//     id: 1,
//     name: 'Greenhouse',
//     description:
//       'Automatically generate roles from your existing Greenhouse roles & manage candidates through the process directly in Greenhouse.',
//     icon: UsersIcon,
//   },
//   {
//     id: 2,
//     name: 'Calendly',
//     description:
//       'Enable seamless scheduling so you spend less time booking meetings and more time meeting with candidates.',
//     icon: CalendarIcon,
//   },
//   {
//     id: 2,
//     name: 'Zapier',
//     description:
//       'Easily create custom integrations using our powerful Zaps.',
//     icon: LightningBoltIcon,
//   },
// ]

export default function Features() {
  // const [currentStep, setCurrentStep] = useState(1)

  return (
    <Layout>
      <div className="py-16 bg-gray-50 overflow-hidden lg:pt-24 lg:pb-0">
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <svg
            className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
          </svg>

          <div className="relative pt-0 pb-24 sm:pb-60 lg:pb-44">
      <div className="relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-8 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div>
              <span className="rounded bg-indigo-50 px-2.5 py-1 text-xs font-semibold text-indigo-600 tracking-wide uppercase">
                    Drumcircle Features
                  </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Personal <em>and</em> automated
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Personalized interactive interview guides bring together all aspects of the interview process to a single hub.
                  <br/><br/>
                  Give every candidate a clear, transparent and delightful journey and stand out from the crowd.
                  
                {/* Interactive interview guides bring all the important information to a single hub to create clear and personalized experiences that stand out from the crowd. */}
                {/* <br/><br/> Integrated with your exisiting workflow to simplify your process and stand out from the crowd. */}
                  {/* Semper curabitur ullamcorper posuere nunc sed. Ornare iaculis bibendum malesuada faucibus lacinia
                  porttitor. Pulvinar laoreet sagittis viverra duis. In venenatis sem arcu pretium pharetra at. Lectus
                  viverra dui tellus ornare pharetra. */}
                </p>
              </div>
            </div>
            <div className="mt-8 border-t border-gray-200 pt-6">
              <blockquote>
                <div>
                  <p className="text-base text-gray-500">
                  &ldquo;Drumcircle delivers better hiring results for our team without complicating our recruiting process.&rdquo;
                  </p>
                </div>
                {/* <footer className="mt-3">
                  <div className="flex items-center space-x-3">
                    <div className="flex-shrink-0">
                      <img
                        className="h-6 w-6 rounded-full"
                        src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                        alt=""
                      />
                    </div>
                    <div className="text-base font-medium text-gray-700">Lorie Buckingham, CEO - One Planet Life</div>
                  </div>
                </footer> */}
              </blockquote>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="lg:pl-4 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="rounded-xl shadow-xl ring-1 ring-black ring-opacity-10"
                src={SampleImage3}
                alt="Inbox user interface"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div>
              <span className="rounded bg-indigo-50 px-2.5 py-1 text-xs font-semibold text-indigo-600 tracking-wide uppercase">
                    Streamlined Workflow
                  </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Simplify your recruiting process
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Our powerful Greenhouse integration means your existing recruiting process can automatically power Drumcircle candidate experiences.
                  <br/><br/>
                  Setup roles once and let Drumcircle take care of the rest.
                  {/* Semper curabitur ullamcorper posuere nunc sed. Ornare iaculis bibendum malesuada faucibus lacinia
                  porttitor. Pulvinar laoreet sagittis viverra duis. In venenatis sem arcu pretium pharetra at. Lectus
                  viverra dui tellus ornare pharetra. */}
                </p>
                <div className="mt-6">
                  {/* <a
                    href="#"
                    className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                  >
                    Learn more
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1 relative">
            <div className=" lg:m-0 lg:relative lg:h-full">
              <img
                className="hidden sm:block  sm:w-full max-w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5"
                src={GreenhouseAvailability}
                alt="Customer profile user interface"
              />

              

              <img
                className="w-full h-auto sm:w-auto sm:h-56 sm:absolute sm:-bottom-40 sm:right-0 md:-right-10 lg:-bottom-10 xl:-bottom-16 lg:-right-16 lg:h-56 lg:w-auto lg:max-w-none border border-gray-200 rounded-md shadow-md"
                src={AvailabilityTile}
                alt="Customer profile user interface"
              />

<img
                className="hidden sm:block w-auto h-96 sm:h-96 absolute top-36 -left-12 sm:top-40 sm:-left-32 md:-left-12 lg:top-40 lg:-left-20 lg:h-5/6 lg:w-auto lg:max-w-none"
                src={GreenhouseMessage}
                alt="Customer profile user interface"
              />
            </div>
          </div>
        </div>
      </div>
    </div>



    <div className="relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-8 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div>
              <span className="rounded bg-indigo-50 px-2.5 py-1 text-xs font-semibold text-indigo-600 tracking-wide uppercase">
                    Seamless Experience
                  </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Perfectly crafted journeys
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Deliver your perfect experience each and every time!
                  <br/><br/>
                  Automated messaging, file sharing, scheduling support, interviewer profiles and more let you easily create seamless experiences that reflect your team and culture.

                  
                  {/* Seamless experiences let your team deliver incredible results as you scale your organization.
                  Ensure candidates get your best experience every time. */}
                </p>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="lg:pl-4 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="rounded-xl shadow-xl ring-1 ring-black ring-opacity-10"
                src={SampleImage5}
                alt="Inbox user interface"
              />
            </div>
          </div>
        </div>
      </div>
      </div>

    <div className="bg-gradient-to-r from-purple-800 to-indigo-700 mt-20">
          <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
            <h2 className="text-3xl font-extrabold text-white tracking-tight">
              Candidate experience built for growing teams
              {/* Built for high-growth teams */}
              {/* Inbox support built for efficiency */}
              </h2>
            <p className="mt-4 max-w-3xl text-lg text-purple-200">
              Stop struggling to manage candidates and let your team focus on hiring the best. <br/>Drumcircle handles candidate experience so everyone is on the same page.
              {/* Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis. Blandit
              aliquam sit nisl euismod mattis in. */}
            </p>
            <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name}>
                  <div>
                    <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                      <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h3 className="text-lg font-medium text-white">{feature.name}</h3>
                    <p className="mt-2 text-base text-purple-200">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

{/* 
        <div className="relative bg-gray-50 py-16 sm:py-24 lg:py-32">
            <div className="relative">
              <div className="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                <h2 className="text-base font-semibold tracking-wider text-cyan-600 uppercase">Learn More</h2>
                <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                  Supercharge your candidate experience
                </p>
                <p className="mt-5 mx-auto max-w-prose text-xl text-gray-500">
                  Phasellus lorem quam molestie id quisque diam aenean nulla in. Accumsan in quis quis nunc, ullamcorper
                  malesuada. Eleifend condimentum id viverra nulla.
                </p>
              </div>
              <div className="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">
                {blogPosts.map((post) => (
                  <div key={post.id} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                    <div className="flex-shrink-0">
                      <img className="h-48 w-full object-cover" src={post.imageUrl} alt="" />
                    </div>
                    <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                      <div className="flex-1">
                        
                        {post.href ?
                          <a href={post.href} className="block mt-2">
                            <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                            <p className="mt-3 text-base text-gray-500">{post.preview}</p>
                          </a>
                        :
                          <div className="block mt-2">
                            <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                            <p className="mt-3 text-base text-gray-500">{post.preview}</p>
                          </div>
                        }
                      </div>
                      <p className="mt-6 text-sm font-medium text-cyan-600">
                      {post.href ?
                          <a href={post.href} className="hover:underline">
                            {post.moreText}
                          </a>
                        :
                          <p className="">
                            {post.moreText}
                          </p>
                        }

                          
                        </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div> */}
        
      </div>
    </Layout>
  )
}
