import axios from 'axios';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Webhook, WebhookLog } from '../../Shared/types';

import { Fragment } from 'react'
import { Menu, Switch, Transition } from '@headlessui/react'
import { DotsVerticalIcon } from '@heroicons/react/solid'
import moment from 'moment';
import { useMixPanel } from 'react-mixpanel-provider-component';

function classNames(...classes: Array<any>) {
  return classes.filter(Boolean).join(' ')
}

interface WebhookDetailsParams {
  webhook_id: string
}

export default function WebhookDetails() {
  const { webhook_id } = useParams<WebhookDetailsParams>()

  // const [loadingWebhookDetails, setLoadingWebhookDetails] = useState(true);
  const [webhookDetails, setWebhookDetails] = useState({} as Webhook);

  const fetchWebhookDetails = () => {
    axios.get(`/webhooks/${webhook_id}`, {params: {include_logs: true}})
      .then(response => {
        setWebhookDetails(response.data.webhook)
        // setLoadingWebhookDetails(false)
      })
      .catch(error => console.log(error))

  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchWebhookDetails() }, []);

  // const LoadingLogs = () => (
  //   <Segment>
  //     <Header>Webhook Logs</Header>
  //     <Dimmer active inverted>
  //       <Loader inverted />
  //     </Dimmer>
  //     <Image src='' />
  //   </Segment>
  // )

  // const LoadedLogs = () => (
  //   <Segment>
  //     <Header>Webhook Logs</Header>
  //     <Table celled>
  //       <Table.Header>
  //         <Table.Row key='header'>
  //           <Table.HeaderCell>Event</Table.HeaderCell>
  //           <Table.HeaderCell>Payload</Table.HeaderCell>
  //           <Table.HeaderCell>Response</Table.HeaderCell>
  //           <Table.HeaderCell>Updated At</Table.HeaderCell>
  //         </Table.Row>
  //       </Table.Header>

  //       <Table.Body>
  //         {webhookDetails.logs && webhookDetails.logs.map((webhook_log: WebhookLog) => (
  //           <Table.Row>
  //             <Table.Cell><Label>{webhook_log.event}</Label></Table.Cell>
  //             <Table.Cell style={{fontSize: '12px', lineHeight: '16px'}}>{webhook_log.payload}</Table.Cell>
  //             <Table.Cell style={{fontSize: '12px', lineHeight: '16px'}}>{JSON.stringify(webhook_log.response)}</Table.Cell>
  //             <Table.Cell>{webhook_log.updated_at}</Table.Cell>
  //           </Table.Row>
  //         ))}
  //       </Table.Body>
  //     </Table>
  //   </Segment>
  // )

  // const LoadingDetails = () => (
  //   <Segment>
  //     <Dimmer active inverted>
  //       <Loader inverted />
  //     </Dimmer>
  //     <Image src='' />
  //   </Segment>
  // )

  // const LoadedDetails = () => (
  //   <Segment>
  //     <Header>
  //       {webhookDetails.url}
  //       {webhookDetails.subscribed_events && webhookDetails.subscribed_events.map((event: string) => (<Label>{event}</Label>))}
  //     </Header>
  //     {webhookDetails.enabled ? <Label color='green'>Enabled</Label> : 'Disabled'}
  //   </Segment>
  // )

  return (
    <div className="pb-5 border-b border-gray-200">
       <main className="-mt-32">
        <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-lg shadow px-4 py-12 max-w-7xl mx-auto sm:px-6 lg:px-8">
            <WebhookDetailsHeader webhook={webhookDetails} />
            <WebhookLogs webhook={webhookDetails} />
          </div>
        </div>
      </main>
    </div>
  )
}


interface WebhookDetailsProps {
  webhook: Webhook
}

const WebhookLogs = (props: WebhookDetailsProps) => {
  const statusCode = (webhookLog: WebhookLog) => {
    const code = webhookLog.response.code
    if(!code) { return null }

    const colorCode = (code >= 200 && code < 300) ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'

    return <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${colorCode}`}>{code}</span>
  }

  return (
    <div className="mt-12 flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Event
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Code
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Payload
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Response
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                  >
                    Last Update
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {props.webhook.logs && props.webhook.logs.map((webhookLog: WebhookLog) => (
                  <tr key={webhookLog.created_at}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{webhookLog.event}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{statusCode(webhookLog)}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">View{/*webhookLog.payload*/}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">View{/*JSON.stringify(webhookLog.response)*/}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">{moment(webhookLog.updated_at).format("MMM Do, h:mm a")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

const WebhookDetailsHeader = (props: WebhookDetailsProps) => {
  const history = useHistory()
  const {mixpanel} = useMixPanel()
  
  const deleteWebhook = async () => {
    await axios.delete(`/webhooks/${props.webhook.id}`)
    mixpanel.track('Webhooks - Delete')
    history.push('/integrations')
  }

  const toggleWebhookStatus = async () => {
    // const newStatus = !props.webhook.enabled

    // Toggle Immediately
    
    // let items = Array.from(props.webhooks)
    // let index = items.findIndex((item) => item.id === webhook.id )
    // items[index].enabled = newStatus
    // props.setWebhooks(items)
    
    // // Save to Server
    // const response = await axios.put(`/webhooks/${webhook.id}`, {enabled: newStatus})
    // const updatedWebhooks = props.webhooks.map((original_webhook: Webhook) => {
    //   return original_webhook.id === webhook.id ? response.data.webhook : original_webhook
    // })
    // props.setWebhooks(updatedWebhooks)

    mixpanel.track('Webhooks - Toggle')
  }

  const WebhookStatus = () => {
    return (
      <Switch
        checked={props.webhook.enabled}
        onChange={toggleWebhookStatus}
        className={classNames(
          props.webhook.enabled ? 'bg-teal-500' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
        )}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={classNames(
            props.webhook.enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
    )
  }
  

  return (
    <div className="sm:flex sm:justify-between sm:items-baseline">
      <div className="sm:w-0 sm:flex-1">
        <h1 id="message-heading" className="text-lg font-medium text-gray-900">{props.webhook.url}</h1>
        <p className="mt-1 text-sm text-gray-500 overflow-hidden overflow-ellipsis">Checkout and Payments Team</p>
      </div>

      <div className="mt-4 flex items-center justify-between sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:justify-start">
        <WebhookStatus />
        <Menu as="div" className="ml-3 relative inline-block text-left">
          <div>
            <Menu.Button className="-my-2 p-2 rounded-full bg-white flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500">
              <span className="sr-only">Open options</span>
              <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type="button"
                      onClick={deleteWebhook}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'w-full flex justify-between px-4 py-2 text-sm'
                      )}
                    >
                      <span>Delete</span>
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  )
}
