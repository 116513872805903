import React from "react";
import { RoleAttachmentLink } from '../../types';

interface CallToActionTileProps {
  link: RoleAttachmentLink
  index: number
}

export default function CallToActionTile(props: CallToActionTileProps) {
  return (
    <>
      <div className="bg-indigo-50">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">
            <span className="block">{props.link.payload.intro_text}</span>
            <span className="block text-indigo-600">{props.link.payload.followup_text}</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <a
                href={props.link.payload.url} target='_blank' rel="noreferrer"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
              >
                {props.link.payload.button_text}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}