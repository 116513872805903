import { useAuth } from "../../Shared/AuthContext"
import { ExclamationIcon } from '@heroicons/react/solid'
import { NavLink } from "react-router-dom"

export default function CandidatePaywallAlert() {
  const { subscriptions } = useAuth()

  if(subscriptions[0] && subscriptions[0].candidates_created >= subscriptions[0].candidate_count) { 
    return (
      <div className="p-6 pb-0">
        <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 rounded-sm">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                You have used all of your candidates this month.{' '}
                <NavLink exact to='/settings/billing' className="font-medium text-yellow-700 underline hover:text-yellow-600">
                  Upgrade your account
                </NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
  return null
}