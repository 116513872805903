import React, { useCallback, useMemo, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { RoleAttachment, RoleAttachmentBlurb } from '../../types';
import './BlurbTileEditor.css'

const Size = Quill.import("formats/size");
Size.whitelist = ["small", "medium", "large", "h1", "h2", "h3", "h4"];
Quill.register(Size, true);

const BlockEmbed = Quill.import('blots/block/embed');
class DividerBlot extends BlockEmbed { }
DividerBlot.blotName = 'divider';
DividerBlot.tagName = 'hr';
Quill.register(DividerBlot);


interface BlurbTileEditorProps {
  blurb: RoleAttachmentBlurb
  updateAttachment: (attachment: RoleAttachment) => void
}


export default function BlurbTileEditor(props: BlurbTileEditorProps) {
  const quill = useRef<ReactQuill>(null);

  function insertHr() {
    if(quill && quill.current && quill.current.editor) { 
      const range = quill.current.editor.getSelection()
      if(range) {
        quill.current.editor.insertText(range.index, '\n', 'user')
        quill.current.editor.insertEmbed(range.index + 1, 'divider', true, 'user')
        quill.current.editor.setSelection(range.index + 2, 1, 'silent')
      }
    }
  }

  const module =  useMemo(() => { return {clipboard: { matchVisual: false }, toolbar: { container: "#toolbar", handlers: { insertHeart: insertHr}}}  },[]);
  const style =  useMemo(() => { return {border: 'none'}  },[]);
  const onChange = useCallback((content, delta, source, editor) => {
    if(source === 'user') {
      props.updateAttachment({...props.blurb, payload: {...props.blurb.payload, text: content}})
    }
  }, [props.updateAttachment])

  return (
    <div className="">
      <div className="p-4 space-y-6">

      <div className="cursor-text whitespace-normal">
      <CustomToolbar />
      <ReactQuill
        ref={quill}
        modules={module}
        className='rounded-md border-none font-sans custom-quill-editor'
        style={style}
        value={props.blurb.payload.text}
        onChange={onChange}
      />
    </div>

   

      </div>
    </div>
  )
}

const CustomToolbar = ({id = 'toolbar'}) => (
  <div id={id}>
    <span className="ql-formats">
      <select className="ql-header">
        <option value="1">Heading 1</option>
        <option value="2">Heading 2</option>
        <option value="3">Heading 3</option>
        <option value="6">Leading</option>
        <option selected>Normal</option>
      </select>
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
    </span>
    <span className="ql-formats">
      <select className="ql-align" />
      <button className="ql-list" value="ordered"></button>
      <button className="ql-list" value="bullet"></button>
      <button className="ql-link"></button>
      <button className="ql-insertHeart"><div className="border-t-4 w-3/5 border-indigo-600"></div></button>
    </span>
  </div>
)