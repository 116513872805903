import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import React, { useState } from "react";
import { RoleAttachmentFormElement, RoleAttachmentFormElementYesNo } from "../../../types";

interface FormElementYesNoProps {
  element: RoleAttachmentFormElementYesNo
  updateElement: (element: RoleAttachmentFormElement) => void
  nextAction: () => void
  hasNextStep: boolean
}

export default function FormElementYesNo(props: FormElementYesNoProps) {
  const [missingRequired, setMissingRequired] = useState(false)

  const next = () => {
    if(props.element.payload.required && !props.element.payload.value) {
      setMissingRequired(true)
    } else {
      props.nextAction()
    }
  }

  return (
    <>
      <div className="flex flex-col justify-center h-full mx-20 text-white">
        <h3 className="text-2xl">{props.element.payload.label}</h3>
        
        {props.element.payload.description &&
          <p className="mt-2.5 opacity-80 text-lg leading-7">{props.element.payload.description}</p>
        }

        <div>
          <div className="mt-1 focus-within:border-white mt-6">
            <RadioGroup value={props.element.payload.value} onChange={(value) => { props.updateElement({...props.element, payload: {...props.element.payload, value: value}}) }}>
              <RadioGroup.Option value="Yes">
                {({ checked }) => (
                  <div className={classNames(checked ? 'bg-opacity-40' : 'bg-opacity-20', 'inline-flex justify-between mb-2 w-28 bg-white border border-white px-4 py-2 rounded-md')}>
                    Yes
                    {checked && 
                      <CheckIcon className="ml-2 mt-0.5 -mr-1 h-5 w-5" aria-hidden="true" />
                    }
                  </div>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option value="No">
                {({ checked }) => (
                  <div className={classNames(checked ? 'bg-opacity-40' : 'bg-opacity-20', 'inline-flex justify-between w-28 bg-white border border-white px-4 py-2 rounded-md')}>
                    No
                    {checked && 
                      <CheckIcon className="ml-2 mt-0.5 -mr-1 h-5 w-5" aria-hidden="true" />
                    }
                  </div>
                )}
              </RadioGroup.Option>
            </RadioGroup>
          </div>
        </div>

        {props.hasNextStep ?
          <button 
            onClick={next}
            className="inline-flex mt-5 self-start px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-black text-opacity-60 bg-white hover:bg-gray-50 focus:outline-none"
          >
            Ok
            <CheckIcon className="ml-2 mt-0.5 -mr-1 h-5 w-5" aria-hidden="true" />
          </button>
        :
          <button 
            onClick={next}
            className="inline-flex mt-5 self-start px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-black text-opacity-60 bg-white hover:bg-gray-50 focus:outline-none"
          >
            Submit
          </button>
        }
      </div>
    </>
  )
}