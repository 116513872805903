import React, { Fragment, useEffect, useState } from 'react'
import { InlineWidget } from "react-calendly";
import { useExperience } from '../../../Candidate/ExperienceContext';
import camelCase from 'lodash/camelCase'
import { CalendarIcon } from '@heroicons/react/outline';


interface CalendlyWidgetProps {
  calendlyLink: string
  index: number
}

export default function CalendlyLinkAttachment(props: CalendlyWidgetProps) {
  const {candidate} = useExperience()
  const [embedHeight, setEmbedHeight] = useState('600px')

  useEffect(() => {
    const handler = (event: MessageEvent) => {
      if(event.data && event.data.event && event.data.event === 'calendly.date_and_time_selected') {
        setEmbedHeight('950px')
      }
    } 

    window.addEventListener("message", handler)
    return () => window.removeEventListener("message", handler)
  }, [])

  try {
    const calendlyUrl = new URL(props.calendlyLink)
    const params = new URLSearchParams(calendlyUrl.search)

    let utmParams = {} as any
    for(var [key, value] of params.entries()) {
      if(key.includes('utm_')) { utmParams[camelCase(key)] = value }
    }

    const email = candidate?.email
    const name = candidate?.name

    

    return (
      <>
      <InlineWidget url={props.calendlyLink} pageSettings={{backgroundColor: 'F4F4F5', hideEventTypeDetails: true, hideLandingPageDetails: true}} styles={{width: '100%', height: embedHeight}} prefill={{email: email, name: name}} utm={utmParams}/>
      </>
    )
  } catch(error) {
    return (
      <div className="bg-blue-200 flex justify-center items-center" style={{width: '100%', height: '455px'}}>
        <CalendarIcon className="w-24 h-24 text-white"/>
      </div>
    )
  }
}
