import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import { InformationCircleIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { ControlledTextArea } from "../../../ControlledTextArea";
import { RoleAttachmentFormElement, RoleAttachmentFormElementStatement } from "../../../types";

interface FormElementStatementEditorProps {
  element: RoleAttachmentFormElementStatement
  updateElement: (element: RoleAttachmentFormElement) => void
  removeElement: (element: RoleAttachmentFormElement) => void
  index: number
}

export default function FormElementStatementEditor(props: FormElementStatementEditorProps) {
  const [expanded, setExpanded] = useState(false)

  if(!expanded) {
    return (
      <>
        <div className="bg-blue-gray-50 rounded-md border borderblue-gray-200 px-2 py-2 border-b border-gray-200">
          <div className="-ml-1 -mt-1 flex justify-between items-center flex-wrap sm:flex-nowrap">
            <div className="ml-1 mt-1">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <InformationCircleIcon className="h-5 w-5 text-blue-gray-500" aria-hidden="true" />
                </div>
                <div className="ml-2">
                  <h3 className="text-xs font-medium text-blue-gray-700 line-clamp-2 mr-2">{props.element.payload.label || 'Statement'}</h3>
                </div>
              </div>
            </div>
            <div className="ml-1 mt-1 flex-shrink-0 flex">
              <button
                type="button"
                onClick={() => {setExpanded(!expanded)}}
                className="relative inline-flex items-center px-2 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <PencilAltIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
              </button>
              <button
                type="button"
                onClick={() => { props.removeElement(props.element) }}
                className="ml-2 relative inline-flex items-center px-2 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <TrashIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="bg-blue-gray-50 rounded-md border border-blue-gray-200 px-2 py-2 border-b border-gray-200">
        <div className="-ml-1 -mt-1 flex justify-between items-center flex-wrap sm:flex-nowrap">
          <div className="ml-1 mt-1">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-5 text-blue-gray-500" aria-hidden="true" />
              </div>
              <div className="ml-2">
                <h3 className="text-xs font-medium text-blue-gray-700 line-clamp-2 mr-2">{props.element.payload.label || 'Statement'}</h3>
              </div>
            </div>
          </div>
          <div className="ml-1 mt-1 flex-shrink-0 flex">
            <button
              type="button"
              onClick={() => {setExpanded(!expanded)}}
              className="relative inline-flex items-center px-2 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <PencilAltIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
            </button>
            <button
              type="button"
              onClick={() => { props.removeElement(props.element) }}
              className="ml-2 relative inline-flex items-center px-2 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <TrashIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
            </button>
          </div>
        </div>

        <div className="mt-2">
          <div className="p-2 space-y-6">
            <div>
              <label htmlFor="statement" className="block text-sm font-medium text-gray-700">
                Statement
              </label>
              <div className="mt-1">
                <ControlledTextArea
                  type="text"
                  name="statement"
                  id="statement"
                  value={props.element.payload.label}
                  onChange={(e) => { props.updateElement({...props.element, payload: {...props.element.payload, label: e.target.value}})} }
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  minRows={2}
                />
              </div>
            </div>

            <div>
              <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                Description (optional)
              </label>
              <div className="mt-1">
                <ControlledTextArea
                  type="text"
                  name="description"
                  id="description"
                  value={props.element.payload.description }
                  onChange={(e) => { props.updateElement({...props.element, payload: {...props.element.payload, description: e.target.value}})} }
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  minRows={2}
                />
              </div>
            </div>

            <div>
              <label htmlFor="button_text" className="block text-sm font-medium text-gray-700">
                Button text
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="button_text"
                  id="button_text"
                  value={props.element.payload.button_text}
                  onChange={(e) => { props.updateElement({...props.element, payload: {...props.element.payload, button_text: e.target.value}})} }
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}