import React, { useState } from "react";
import { RoleAttachment, RoleAttachmentForm, RoleAttachmentFormElement } from '../../types';
import { v4 as uuidv4 } from 'uuid';
import { Menu } from "@headlessui/react";
import { PlusSmIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import usePortal from "react-useportal";
import { usePopper } from "react-popper";
import FormElementStatementEditor from "./Elements/FormElementStatementEditor";
import FormElementLongText from "./Elements/FormElementLongText";
import FormElementShortTextEditor from "./Elements/FormElementShortTextEditor";
import { InformationCircleIcon, MenuAlt2Icon, MenuAlt4Icon, LinkIcon, LightningBoltIcon, ChartBarIcon, ThumbUpIcon  } from "@heroicons/react/solid";
import { Float } from '@headlessui-float/react'
import FormElementGreenhouseFieldEditor from "./Elements/FormElementGreenhouseFieldEditor";
import FormElementYesNoEditor from "./Elements/FormElementYesNoEditor";
import FormElementWebsiteEditor from "./Elements/FormElementWebsiteEditor";
import { ControlledTextArea } from "../../ControlledTextArea";

interface FormTileEditorProps {
  form: RoleAttachmentForm
  updateAttachment: (attachment: RoleAttachment) => void
}

export default function FormTileEditor(props: FormTileEditorProps) {
  const { Portal } = usePortal()
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  let { styles, attributes } = usePopper(referenceElement, popperElement)

  const [activeAttachment, setActiveAttachment] = useState('')


  const addElement = (type: string, payload: {}) => {
    const newId = uuidv4()

    props.updateAttachment({...props.form, 
      payload: {...props.form.payload,
        elements: [...props.form.payload.elements, {
          type: type,
          id: newId,
          payload: payload
        }] 
      }
    })
    setActiveAttachment(newId)
  }

  const updateElement = (element: RoleAttachmentFormElement) => {
    props.updateAttachment({...props.form, 
      payload: {...props.form.payload,
        elements: props.form.payload.elements.map((prevElement) => {
          return prevElement.id !== element.id ? prevElement : element
        }) 
      }
    })
  }

  const removeElement = (element: RoleAttachmentFormElement) => {
    props.updateAttachment({...props.form, 
      payload: {...props.form.payload,
        elements: props.form.payload.elements.filter((prevElement) => {
          return prevElement.id !== element.id
        }) 
      }
    })
  }

  const addStatement = () => {
    addElement('statement', {
      label: '',
      description: '',
      button_text: ''
    })
  }

  const addShortText = () => {
    addElement('short_text', {
      label: '',
      description: '',
      required: false,
    })
  }

  const addWebsite = () => {
    addElement('website', {
      label: '',
      description: '',
      required: false,
    })
  }

  const addYesNo = () => {
    addElement('yes_no', {
      label: '',
      description: '',
      required: false,
    })
  }

  const addLongText = () => {
    addElement('long_text', {
      label: '',
      description: '',
      require: false
    })
  }

  const addGreenhouseField = () => {
    addElement('greenhouse_field', {
      label: '',
      description: '',
      require: false,
      greenhouse_field: null
    })
  }

  const renderElement = (element: RoleAttachmentFormElement, index: number) => {
    switch(element.type) {
      case 'statement': return <FormElementStatementEditor element={element} updateElement={updateElement} index={index} removeElement={removeElement}/>
      case 'short_text': return <FormElementShortTextEditor element={element} updateElement={updateElement} index={index} removeElement={removeElement}/>
      case 'website': return <FormElementWebsiteEditor element={element} updateElement={updateElement} index={index} removeElement={removeElement}/>
      case 'yes_no': return <FormElementYesNoEditor element={element} updateElement={updateElement} index={index} removeElement={removeElement}/>
      // case 'long_text': return <FormElementLongText element={element} index={index} />
      case 'greenhouse_field': return <FormElementGreenhouseFieldEditor element={element} updateElement={updateElement} index={index} removeElement={removeElement}/>
      default: return null
    }
  }
  
  return (
    <>
      <div className="p-4 space-y-6">
        
        <div>
          <label htmlFor="height" className="block text-sm font-medium text-gray-700">
            Min Height (px)
          </label>
          <div className="mt-1">
            <input
              type="number"
              name="height"
              id="height"
              placeholder="300"
              value={props.form.payload.height}
              onChange={(e) => { props.updateAttachment({...props.form, payload: {...props.form.payload, height: e.target.value}})} }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>

        <div className="space-y-2">
          <div className="pb-1.5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
            <h3 className="text-sm font-medium text-gray-700">Elements</h3>
            <div className="mt-3 sm:mt-0 sm:ml-4">
            <Menu as="div" className="relative inline-block text-left">
              <Float portal placement="bottom-end" offset={4}>
                  <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-1 py-1 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                    <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>

                  <Menu.Items className="origin-top-right w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={addStatement}
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'group w-full flex items-center px-4 py-2 text-sm'
                            )}
                          >
                            <InformationCircleIcon
                              className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            Statement
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={addShortText}
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'group w-full flex items-center px-4 py-2 text-sm'
                            )}
                          >
                            <MenuAlt4Icon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                            Short Text
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'group w-full flex items-center px-4 py-2 text-sm'
                            )}
                          >
                            <MenuAlt2Icon
                              className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            Long Text
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={addWebsite}
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'group w-full flex items-center px-4 py-2 text-sm'
                            )}
                          >
                            <LinkIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                            Website
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={addYesNo}
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'group w-full flex items-center px-4 py-2 text-sm'
                            )}
                          >
                            <ThumbUpIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                            Yes / No
                          </button>
                        )}
                      </Menu.Item>
                      {/* <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'group w-full flex items-center px-4 py-2 text-sm'
                            )}
                          >
                            <ChartBarIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                            Opinion Scale
                          </button>
                        )}
                      </Menu.Item> */}
                    </div>
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={addGreenhouseField}
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'group w-full flex items-center px-4 py-2 text-sm'
                            )}
                          >
                            <LightningBoltIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                            Greenhouse Field
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Float>
              </Menu>
            </div>
          </div>
        
          {props.form.payload.elements.map((element, index) => (
            <>
              {renderElement(element, index)}
            </>
          ))}

          <div className="pt-2 pb-1.5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
            <h3 className="text-sm font-medium text-gray-700">Ending</h3>
            <div className="mt-3 sm:mt-0 sm:ml-4">
                 
            </div>
          </div>

          <div className="mt-2">
                <div className="space-y-4">
                  <div>
                    <label htmlFor="ending-text" className="block text-sm font-medium text-gray-700">
                      Text
                    </label>
                    <div className="mt-1">
                      <ControlledTextArea
                        type="text"
                        name="ending-text"
                        id="ending-text"
                        value={props.form.payload.ending?.label}
                        onChange={(e) => { props.updateAttachment({...props.form, payload: {...props.form.payload, ending: {label: e.target.value}}})} }
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        minRows={2}
                      />
                    </div>
                  </div>
                </div>
              </div>   


        </div>

      </div>
    </>
  )
}