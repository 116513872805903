import React, { useEffect, useState } from 'react'
import { Candidate, CandidateActivity, Role, UserNotification } from '../../Shared/types'
import GetStarted from '../Onboarding/GetStarted'
import CreateCandidate from '../CreateCandidate'
import { usePage } from '../../Shared/PageContext'
import CreateRole from '../CreateRole'
import FlowList from './FlowList'
import ProfileOverview from './ProfileOverview'
import CandidatesOverview from './CandidatesOverview'
import axios from 'axios'
import { useAuth } from '../../Shared/AuthContext'
import useIntervalWithFocus from '../../Shared/useIntervalWithFocus'
import ConfirmEmail from '../../Auth/ConfirmEmail'
import ConfirmEmailAlert from './ConfirmEmailAlert'
import CandidatePaywallAlert from './CandidatePaywallAlert'

interface DashboardData {
  roles: Array<Role>
  candidates: Array<Candidate>
  activity: Array<CandidateActivity>
  notifications: Array<UserNotification>
}

export default function Dashboard() {
  const [loading, setLoading] = useState(true)
  const [loadedArchived, setLoadedArchived] = useState(false)
  const [roles, setRoles] = useState(new Array<Role>());
  const [candidates, setCandidates] = useState(new Array<Candidate>());
  const [archivedCandidates, setArchivedCandidates] = useState(new Array<Candidate>());
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activity, setActivity] = useState(new Array<CandidateActivity>())
  const [notifications, setNotifications] = useState(new Array<UserNotification>());

  // const candidateTabs = [{name: 'My Candidates', key: 'mine'}, {name: 'All Candidates', key: 'all'}, {name: 'Archived', key: 'archived'}]
  const [candidateTab, setCandidateTab] = useState('mine')
  
  const { setPage } = usePage();

  const { currentUser } = useAuth()

  const fetch = (endpoint: RequestInfo) => {
    return window.fetch(endpoint)
      .then(response => response.json())
      .catch(error => console.log(error))
  }

  const fetchDashboardData = () => {
    fetch('/dashboard').then((data: DashboardData) => {
      setRoles(data.roles)
      setCandidates(data.candidates)
      setActivity(data.activity)
      setNotifications(data.notifications)
      setLoading(false)
    })
  }

  const fetchArchivedDasboardData = async () => {
    const response = await axios.get('/dashboard?archived=true')
    setRoles(response.data.roles)
    setArchivedCandidates(response.data.candidates)
    setLoadedArchived(true)
  }

  useEffect(() => {
    if(candidateTab === 'archived') {
      fetchArchivedDasboardData()
    } 
  }, [candidateTab])

  useIntervalWithFocus(() => {
    fetchDashboardData()
  }, 60*1000)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchDashboardData() }, []);


  const dashboardControls = () => {
    if(roles.length === 0 || loading) { return <></> }
    return (
      <>
        <CreateCandidate candidates={candidates} addCandidate={(candidate: Candidate) => { setCandidates([candidate, ...candidates]) }}/>
        <CreateRole roles={roles} />
      </>
    )
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { setPage({title: 'Dashboard', controls: dashboardControls()}) }, [candidates, roles, loading]);

  if(roles.length === 0 && !loading) {
    return (
      <main className="-mt-24 pb-8">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">

          {/* Left column */}
          <div className="grid grid-cols-1 gap-3 lg:col-span-3">
            <div className="rounded-lg bg-white overflow-hidden shadow">
            <GetStarted setRoles={setRoles} />
            </div>
          </div>

        </div>
      </div>
    </main>
    )
  }

  

  if(loading) { return null }


  let filteredCandidates = candidates
  
  if(candidateTab === 'mine') { 
    filteredCandidates = filteredCandidates.filter((c) => c.assignee.id === currentUser?.id)
  }

  if(candidateTab === 'archived') { 
    filteredCandidates = archivedCandidates
  }

  return (
    <main className="-mt-24 pb-8">
      <div className="mx-auto px-4 sm:px-6 xl:max-w-7xl lg:px-8">
        <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">

          {/* Left column */}
          <div className="grid grid-cols-1 gap-3 lg:col-span-2">
            <div className="rounded-lg bg-white overflow-hidden shadow">
              <ConfirmEmailAlert />
              <CandidatePaywallAlert />
              <ProfileOverview />
              <CandidatesOverview candidates={filteredCandidates} setCandidates={setCandidates} notifications={notifications} setNotifications={setNotifications} candidateTab={candidateTab} setCandidateTab={setCandidateTab} loadedArchived={loadedArchived} />
            </div>
          </div>

          {/* Right column */}
          <div className="grid grid-cols-1 gap-4">
            <FlowList candidates={candidates} />
          </div>
        </div>
      </div>
    </main>
  )
}