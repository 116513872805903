/* eslint-disable react-hooks/exhaustive-deps */
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { useAuth } from '../../Shared/AuthContext';
import { Switch } from '@headlessui/react';
import React, { useState } from 'react';
import axios from 'axios';
import SubscribeGrowth from './SubscribeGrowth';
import moment from 'moment';

let stripePublishKey = 'pk_test_51IdLvbIeGELaDfBxQBsUuORoCnX6U5npSmh0Dzp0OalntMkjuJJ4o9BIzGVEtsCwhHj4wW8EfJcFz4quRIj0JJJS00wm0JgAhh'
if(window.location.host === 'drumcircle.io') { stripePublishKey = 'pk_live_51IdLvbIeGELaDfBxf7e9Znsu2asqmXHsJI701qYbjfiVNNC5IGf8aPNo4Q1m5NMwj3DOws97myh7zlxrkR80hnnj00P8D1HGvQ' }

const stripePromise = loadStripe(stripePublishKey);

function classNames(...classes: Array<any>) {
  return classes.filter(Boolean).join(' ')
}
const plans = [
  {
    id: 1,
    name: 'Starter',
    key: 'starter',
    memory: '12',
    price: 'Free',
    priceMonthly: 'Free',
    isCurrent: false,
  },
  {
    id: 2,
    name: 'Growth',
    key: 'growth',
    memory: '100',
    price: '$99/mo',
    priceMonthly: '$119/mo',
    isCurrent: false,
  },
  {
    id: 3,
    name: 'Scale',
    key: 'scale',
    memory: '300',
    price: '$249/mo',
    priceMonthly: '$299/mo',
    isCurrent: false,
  },
  {
    id: 4,
    name: 'Enterprise',
    key: 'enterprise',
    memory: 'Unlimited',
    price: 'Custom',
    priceMonthly: 'Custom',
    isCurrent: false,
  },
]

function Billing() {
  const {subscriptions, setSubscriptions} = useAuth()
  const [annualBillingEnabled, setAnnualBillingEnabled] = useState(true)
  const [confirmUpgradeGrowth, setConfirmUpgradeGrowth] = useState(false)

  const [upgradePlan, setUpgradePlan] = useState('growth')

  const currentSub = subscriptions.find(sub => moment(sub.current_period_end).isAfter())
  const additionalSubs = subscriptions.filter((subs, index) => index !== 0)


  const showManageLink = currentSub && currentSub.plan === 'growth' && currentSub.status === 'active'
  const manageUrl = window.location.hostname === 'localhost' ? 'http://localhost:3001/subscriptions/manage' : `${window.location.origin}/subscriptions/manage`

  const selectPlan = async (selectedPlan: string) => {
    if(selectedPlan === 'starter') {
      const response = await axios.post('/subscriptions', {subscription: {plan: 'starter'}})
      setSubscriptions(response.data.subscriptions)
    }

    if(selectedPlan === 'growth') {
      setUpgradePlan('growth')
      setConfirmUpgradeGrowth(true)
    }

    if(selectedPlan === 'scale') {
      setUpgradePlan('scale')
      setConfirmUpgradeGrowth(true)
    }
  }

  function toCaps(string: string) {
    return string[0].toUpperCase() + string.slice(1);
  }

  const candidatePercent = (currentSub?.candidates_created || 0) / (currentSub?.candidate_count || 1) * 100
  const candidateWidth = candidatePercent < 5 ? 5 : candidatePercent

  const renewalText = () => {
    if(currentSub?.plan === 'starter') {
      return null
    }

    if(currentSub?.status === 'trial') {
      return <span>{' '} Your free trial will expire on {moment(currentSub?.current_period_end).format('MMMM Do, YYYY')}.</span>
    }

    if(additionalSubs.length === 0 || (additionalSubs.length > 0 && additionalSubs[0].plan === currentSub?.plan)) {
      return <span>{' '} Your subscription will renew on {moment(currentSub?.current_period_end).format('MMMM Do, YYYY')}.</span>
    }

    if(additionalSubs.length > 0) {
      return <span>{' '} Your plan will switch to {toCaps(additionalSubs[0].plan)} on {moment(currentSub?.current_period_end).format('MMMM Do, YYYY')}.</span> 
    }
    
    return null
  }

  const showSelectPlanButton = (planKey: string) => {
    if(currentSub?.status === 'trial') { return true }
    if(additionalSubs.length === 0 && currentSub?.plan === planKey) { return false }
    if(additionalSubs.length > 0 && additionalSubs[0].plan === planKey) { return false }

    //Update once plan upgrade/downgrades and future adjustment handling is done on the backend
    if(currentSub?.plan === 'growth' && planKey !== 'starter') { return false }

    return true
  }

  return (
    <>
    <Elements stripe={stripePromise}>
      <SubscribeGrowth annualBillingEnabled={annualBillingEnabled} open={confirmUpgradeGrowth} setOpen={setConfirmUpgradeGrowth} plan={upgradePlan} />
    </Elements>
    <div className="divide-y divide-gray-200 lg:col-span-9">
      <div className="py-6 px-4 sm:p-6">
        <div>
          <h2 className="text-lg leading-6 font-medium text-gray-900">Billing</h2>
          <p className="mt-1 text-sm text-gray-500">Your plan and billing details.</p>
        </div>
        </div>
        <div className="py-6 px-4 sm:p-6 lg:pb-8">
        <h1 className="text-xl font-semibold text-gray-900">Usage</h1>

        <div className="">
          <div className="flex justify-between mt-4 mb-2">
            <span className="text-sm leading-6 font-medium text-gray-700">Candidates per month</span>
            <span className="text-sm leading-6 font-medium text-gray-700">{currentSub?.candidate_count}</span>
          </div>
          <div className="w-full bg-gray-200 mt-2 rounded-full dark:bg-gray-700">
            <div className="bg-indigo-600 text-lg py-2 font-medium text-indigo-100 text-center p-0.5 leading-none rounded-full" style={{width: `${candidateWidth}%`}}>{currentSub?.candidates_created}</div>
          </div>
        </div>

        </div>
        <div className="py-6 px-4 sm:p-6 lg:pb-8">

      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Plans</h1>
          <p className="mt-2 text-sm text-gray-700">
            Your team is on the <strong className="font-semibold text-gray-900">{toCaps(currentSub?.plan || '')}</strong> plan. 
            {renewalText()}
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">

        { showManageLink && 
          <a href={manageUrl} className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none sm:w-auto">
            Payment Method & Invoices
          </a>
        }
          
        </div>
      </div>
      <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:-mx-6 md:mx-0 md:rounded-lg">
        
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Plan
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 table-cell"
              >
                Candidates
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Price
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
              </th>
            </tr>
          </thead>
          <tbody>
            {plans.map((plan, planIdx) => (
              <tr key={plan.id}>
                <td
                  className={classNames(
                    planIdx === 0 ? '' : 'border-t border-transparent',
                    'relative py-4 pl-4 sm:pl-6 pr-3 text-sm'
                  )}
                >
                  <div className="font-medium text-gray-900">
                    {plan.name}
                    {currentSub?.status === 'trial' && currentSub?.plan === plan.key &&
                      <span className="text-indigo-600">{' '}(Free Trial)</span>
                    }

                    {currentSub?.status !== 'trial' && currentSub?.plan === plan.key &&
                      <span className="text-indigo-600">{' '}(Current Plan)</span>
                    }
                  </div>
                  {planIdx !== 0 ? <div className="absolute right-0 left-6 -top-px h-px bg-gray-200" /> : null}
                </td>
                <td
                  className={classNames(
                    planIdx === 0 ? '' : 'border-t border-gray-200',
                    'px-3 py-3.5 text-sm text-gray-500 table-cell'
                  )}
                >
                  {plan.memory}
                </td>
                <td
                  className={classNames(
                    planIdx === 0 ? '' : 'border-t border-gray-200',
                    'px-3 py-3.5 text-sm text-gray-500'
                  )}
                >
                  <div className="block">{annualBillingEnabled ? plan.price : plan.priceMonthly}</div>
                </td>
                <td
                  className={classNames(
                    planIdx === 0 ? '' : 'border-t border-transparent',
                    'relative py-3.5 pl-3 pr-4 sm:pr-6 text-right text-sm font-medium'
                  )}
                >

                {plan.key === 'enterprise' ?
                  <a
                    href="/enterprise"
                    target="_blank"
                    rel="noreferrer"
                    className="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                  >
                    Contact<span className="sr-only">, {plan.name}</span>
                  </a>
                :
                <div>
                  {showSelectPlanButton(plan.key) &&  
                      <button
                      type="button"
                      className="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                      onClick={() => { selectPlan(plan.key) }}
                    >
                      Select<span className="sr-only">, {plan.name}</span>
                    </button>
                  }
                </div>
                  
                }
                {planIdx !== 0 ? <div className="absolute right-6 left-0 -top-px h-px bg-gray-200" /> : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <p className="mt-3 mb-5 text-sm text-gray-500">Need to update your paid plan or other help? Reach out to support@drumcircle.io</p>
      
      <Switch.Group as="div" className="flex justify-start">
                      <Switch
                        checked={annualBillingEnabled}
                        onChange={setAnnualBillingEnabled}
                        className={classNames(
                          annualBillingEnabled ? 'bg-indigo-500' : 'bg-gray-200',
                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer focus:outline-none transition-colors ease-in-out duration-200'
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            annualBillingEnabled ? 'translate-x-5' : 'translate-x-0',
                            'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                          )}
                        />
                      </Switch>
                      <Switch.Label as="span" className="ml-3">
                        <span className="text-sm font-medium text-gray-900">Annual billing</span>
                      </Switch.Label>
                    </Switch.Group>


        {/* <section className="" aria-labelledby="plan-heading">
        <span className="text-base leading-6 font-medium text-gray-700">Update Subscription</span>
              <form>
                <div className=" sm:overflow-hidden">
                  <div className="bg-white py-6 space-y-6">
                    
                  {currentSub?.status === 'trial' &&
                        <p className="mt-1 text-sm text-gray-500">You are currently on a free trial of our Growth plan until {moment(currentSub.current_period_end).format('MMMM Do')}.</p>
                      }
                      {additionalSubs.length > 0 &&
                        <>
                          <p className="mt-1 text-sm text-gray-500">Your {toCaps(currentSub?.plan || '')} plan expires on {moment(currentSub?.current_period_end).format('MMM Do YYYY')}. At that time, you plan will switch to {toCaps(additionalSubs[0].plan)}.</p>
                        </>
                      }

                    <RadioGroup value={selectedPlan} onChange={setSelectedPlan} disabled={additionalSubs.length > 0}>
                      <RadioGroup.Label className="sr-only">Pricing plans</RadioGroup.Label>
                      <div className="relative bg-white rounded-md -space-y-px">
                        {plans.map((plan, planIdx) => (
                          <RadioGroup.Option
                            key={plan.name}
                            value={plan.key}
                            disabled={plan.key === 'enterprise' }
                            className={({ checked }) =>
                              classNames(
                                planIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                                planIdx === plans.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                                checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200',
                                'relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none'
                              )
                            }
                          >
                            {({ active, checked }) => (
                              <>
                                <div className="flex items-center text-sm">
                                  <span
                                    className={classNames(
                                      checked ? 'bg-indigo-500 border-transparent' : 'bg-white border-gray-300',
                                      'h-4 w-4 rounded-full border flex items-center justify-center'
                                    )}
                                    aria-hidden="true"
                                  >
                                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                                  </span>
                                  <RadioGroup.Label as="span" className="ml-3 font-medium text-gray-900">
                                    {plan.name}
                                  </RadioGroup.Label>
                                </div>
                                <RadioGroup.Description className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                                  <span
                                    className={classNames(checked ? 'text-indigo-900' : 'text-gray-900', 'font-medium')}
                                  >
                                    ${annualBillingEnabled ? plan.priceMonthly : plan.monthlyPrice} / mo 
                                  </span>{' '}
                                  {annualBillingEnabled && 
                                    <span className={checked ? 'text-indigo-700' : 'text-gray-500'}>
                                      (${plan.priceYearly}/yr)
                                    </span>
                                  }
                                  <span className="text-gray-500">&nbsp;  per seat</span>
                                </RadioGroup.Description>
                                <RadioGroup.Description
                                  className={classNames(
                                    checked ? 'text-indigo-700' : 'text-gray-500',
                                    'ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right'
                                  )}
                                >
                                  {plan.limit}
                                </RadioGroup.Description>
                              </>
                            )}
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>

                    <Switch.Group as="div" className="flex items-center">
                      <Switch
                        checked={annualBillingEnabled}
                        onChange={setAnnualBillingEnabled}
                        className={classNames(
                          annualBillingEnabled ? 'bg-indigo-500' : 'bg-gray-200',
                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer focus:outline-none transition-colors ease-in-out duration-200'
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            annualBillingEnabled ? 'translate-x-5' : 'translate-x-0',
                            'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                          )}
                        />
                      </Switch>
                      <Switch.Label as="span" className="ml-3">
                        <span className="text-sm font-medium text-gray-900">Annual billing </span>
                      </Switch.Label>
                    </Switch.Group>
                  </div>
                </div>
        
              </form>
            </section> */}
      </div>
    </div>
    </>
  )
}

export default Billing;
