import React, { Fragment, useEffect } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import { Candidate, UserNotification } from '../../Shared/types'
import { NavLink } from 'react-router-dom'
import { DotsVerticalIcon } from '@heroicons/react/solid'
import CandidateActivitySection from '../Shared/CandidateActivityQuickView'
import axios from 'axios'
import { useMixPanel } from 'react-mixpanel-provider-component'
import useWindowSize from '../../Shared/useWindowSize'
import './CandidateQuickView.css'

interface CandidateQuickViewProps {
  candidate?: Candidate
  updateCandidate: (candidate: Candidate) => void
  notifications: Array<UserNotification>
  setNotifications: React.Dispatch<React.SetStateAction<UserNotification[]>>
  close: () => void
  // open: boolean
  // setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function classNames(...classes: Array<any>) {
  return classes.filter(Boolean).join(' ')
}


export default function CandidateQuickView(props: CandidateQuickViewProps) {
  const markNotificationsAsRead = async () => {
    if(!props.candidate) { return }
    const candidateNotifications = props.notifications.filter(notification => notification.candidate_id === props.candidate!.id)

    if(candidateNotifications.length === 0) { return }
    const notificationIds = candidateNotifications.map(notification => notification.id)

    const response = await axios.post('/notifications/read', { notifications: {ids: notificationIds} })
    props.setNotifications(response.data.notifications)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { if(props.candidate) { markNotificationsAsRead() } }, [props.candidate])

  return (
    <>
      <div
        aria-live="assertive"
        className="fixed bottom-0 right-0 flex items-end pointer-events-none sm:items-start max-h-screen"
      >
        <div className="flex flex-col items-center space-y-4 sm:items-end">
          <Transition
            show={!!props.candidate}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-x-2 opacity-0 sm:translate-x-0 sm:translate-y-24"
            enterTo="translate-x-0 opacity-100 sm:translate-y-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div>
              {props.candidate &&
                <CandidateDetails candidate={props.candidate} updateCandidate={props.updateCandidate} close={props.close} />
              }
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}


interface CandidateDetailsProps {
  candidate: Candidate
  updateCandidate: (candidate: Candidate) => void
  close: () => void
}

function CandidateDetails(props: CandidateDetailsProps) {
  const {mixpanel} = useMixPanel()

  const { height } = useWindowSize()

  let activityMaxCount = 4
  if(height < 1000) { activityMaxCount = 3}
  if(height < 800) { activityMaxCount = 2}
  if(height < 600) { activityMaxCount = 1}
  // const activate = async () => {
  //   await axios.post(`/candidates/${props.candidate.slug}/activate`)
  //   props.updateCandidate({...props.candidate, archived_at: undefined})
  //   mixpanel.track('Experience - Activate', {experienceId: props.candidate.id, flowId: props.candidate.role_id})
  // }

  const advance = async () => {
    const response = await axios.put(`/candidates/${props.candidate.slug}`, {stage: props.candidate.stage + 1})
    props.updateCandidate(response.data)
    mixpanel.track('Experience - Advance', {experienceId: props.candidate.id, flowId: props.candidate.role_id})
  }

  const archive = async () => {
    const response = await axios.delete(`/candidates/${props.candidate.slug}`)
    props.updateCandidate(response.data)
    mixpanel.track('Experience - Archive', {experienceId: props.candidate.id, flowId: props.candidate.role_id})
  }

  const deleteCandidate = async () => {
    await axios.delete(`/candidates/${props.candidate.slug}`)
    props.close()
  }

  const copyLink = () => {
    const candidateLinkUrl = `${window.location.protocol}//${window.location.host}/e/${props.candidate.slug}`
    navigator.clipboard.writeText(candidateLinkUrl)
  }
  
  return (
    <div className="quickview-container bg-transparent sm:m-6 pointer-events-none flex flex-col justify-end shadow-2xl ring-1 ring-black ring-opacity-5 rounded-lg">
      <div className="bg-white h-96 w-full pointer-events-auto rounded-t-lg">
        {/* Header Section */}
        <div className="pb-6">
        <div className="bg-gray-800 h-24 sm:h-20 lg:h-28 hover:bg-gray-900 cursor-pointer rounded-t-lg" onClick={props.close}>
          <div className="mr-6 h-24 sm:h-20 lg:h-28 flex justify-end">
            <button
              type="button"
              className="rounded-md text-indigo-200"
              onClick={props.close}
            >
              <span className="sr-only">Close panel</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
        <div className="-mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6 lg:-mt-15">
          <div>
            <div className="-m-1 flex">
              <div className="inline-flex rounded-lg overflow-hidden border-4 border-white">
                <img
                  className="flex-shrink-0 h-24 w-24 sm:h-40 sm:w-40 lg:w-48 lg:h-48"
                  src={props.candidate.avatar}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="mt-6 sm:ml-6 sm:flex-1">
            <div>
              <div className="flex items-center">
                <h3 className="font-bold text-xl text-gray-900 sm:text-2xl">{props.candidate.name}</h3>
                {props.candidate.archived_at ?
                  <span className="ml-2.5 bg-red-400 flex-shrink-0 inline-block h-2 w-2 rounded-full"></span>
                : 
                  <span className="ml-2.5 bg-green-400 flex-shrink-0 inline-block h-2 w-2 rounded-full"></span>
                }
              </div>
              <p className="text-sm text-gray-500">{props.candidate.email}</p>
            </div>
            <div className="mt-5 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
              {props.candidate.stage !== props.candidate.candidate_stage_previews.length - 1 && !props.candidate.archived_at &&
                <button
                  type="button"
                  onClick={advance}
                  className="flex-shrink-0 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:flex-1"
                >
                Advance
              </button>
              }
              {props.candidate.stage === props.candidate.candidate_stage_previews.length -1 && !props.candidate.archived_at &&
                <button
                  type="button"
                  onClick={archive}
                  className="flex-shrink-0 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:flex-1"
                >
                Archive
              </button>
              }

              {props.candidate.archived_at &&
                <button
                  type="button"
                  onClick={deleteCandidate}
                  className="flex-shrink-0 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:flex-1"
                >
                  Delete
                </button>
              }
              
              <NavLink to={`/candidate/${props.candidate.slug}`} className="flex-1 w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Details
              </NavLink>
              <span className="ml-3 inline-flex sm:ml-0">
                <Menu as="div" className="relative inline-block text-left">
                  <Menu.Button className="inline-flex items-center p-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-400 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span className="sr-only">Open options menu</span>
                    <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right z-50 absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <a href={`/e/${props.candidate.slug}`} target="_blank" rel="noreferrer" className="text-gray-700 block px-4 py-2 text-sm" >View experience</a>
                          )}
                        </Menu.Item>
                      </div>
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                            onClick={copyLink}
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm'
                              )}
                            >
                              Copy experience link
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </span>
            </div>
          </div>
        </div>
      </div>
      {height > 700 &&
            <div className="hidden sm:block sm:flex sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                Stage
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2">
              {props.candidate.role_title} - {props.candidate.candidate_stages[props.candidate.stage].title}
              </dd>
            </div>
          }
      </div>

      <CandidateActivitySection candidate={props.candidate} updateCandidate={props.updateCandidate} count={activityMaxCount} disabled={!!props.candidate.archived_at}/>
    </div>
  )
}