import { Switch } from "@headlessui/react";
import axios from "axios";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useMixPanel } from "react-mixpanel-provider-component";
import { useHistory } from "react-router-dom";
import { GreenhouseRole, Role } from "../../Shared/types";
import GreenhouseOnboarding from "./GreenhouseOnboarding";

export interface GreenhouseRoleSelection extends GreenhouseRole {
  checked: boolean
}

interface GetStartedProps {
  setRoles: React.Dispatch<React.SetStateAction<Role[]>>
}

function GetStarted(props: GetStartedProps) {
  const [roleName, setRoleName] = useState('')
  const [roleNameRequired, setRoleNameRequired] = useState(false)
  const [recruiterInterview, setRecruiterInterview] = useState(true)
  const [zoomInterview, setZoomInterview] = useState(true)
  const [assignment, setAssignment] = useState(true)
  const [onsiteInterview, setOnsiteInterview] = useState(true)

  const history = useHistory();
  const { mixpanel } = useMixPanel()

  const settings = [
    { name: 'Recruiter Interview', description: 'Initial recruiter screen to discuss the role.', key: 'recruiter_interview', checked: recruiterInterview, onChange: setRecruiterInterview },
    { name: 'Zoom Interview', description: 'Remote interview with the hiring manager.', key: 'zoom_interview', checked: zoomInterview, onChange: setZoomInterview },
    { name: 'Take-Home Assignment', description: 'Skills assessment for the candidate to complete.', key: 'assignment', checked: assignment, onChange: setAssignment },
    { name: 'Onsite Interview', description: 'Complete onsite interview experience.', key: 'onsite_interview', checked: onsiteInterview, onChange: setOnsiteInterview },
  ]

  const saveOnboarding = async () => {
    if(roleName === '') {
      setRoleNameRequired(true)
    } else {
      const response = await axios.post('/onboarding', {onboarding: {name: roleName, stages: {
        recruiter_interview: recruiterInterview,
        zoom_interview: zoomInterview,
        assignment: assignment,
        onsite_interview: onsiteInterview
      }}})

      history.push(`/role/${response.data.role.id}`)
    }

    mixpanel.track('Onboarding - Complete')
  }

  useEffect(() => { setRoleNameRequired(false) }, [roleName])
  
  return (
    <div className="py-4 sm:py-6 lg:py-8 bg-gray-50 overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8">
        <div className="text-base max-w-prose mx-auto lg:max-w-none">
          <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Get Started</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Create your first role
          </p>
        </div>
        <div className="relative z-10 text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
          <p className="text-lg text-gray-500">
            Roles define the interview experiences and usually will map one-to-one with your hiring roles. Once you have
            created a role, you can start inviting candidates.
          </p>
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
          <div className="relative z-10">
            <div className="text-gray-500 mx-auto lg:max-w-none">
              <div>
                <div className=''>
                  <h2 className="text-lg leading-6 font-medium text-gray-900">Name</h2>
                  <p className="mt-1 text-sm text-gray-500">
                    This will be visible to candidates.
                  </p>
                </div>
                <div className="mt-4">
                  <input
                    type="text"
                    name="project-name"
                    placeholder='Senior Project Manager'
                    id="project-name"
                    autoFocus
                    value={roleName}
                    onChange={(e) => { setRoleName(e.target.value)}}
                    className={classNames(roleNameRequired ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500' : '', "block w-2/3 shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md text-gray-900")}
                  />
                  {roleNameRequired &&
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      Please enter a name for this role.
                    </p>
                  }
                </div>
              </div>
              <div className='pt-6'>
                    <h2 className="text-lg leading-6 font-medium text-gray-900">Stages</h2>
                    <p className="mt-1 text-sm text-gray-500">
                      You can add, customize, and remove stages later to fit your needs.
                    </p>
                  </div>
              
            </div>
            <div>
              <div className="pb-6 divide-y divide-gray-200">
                  <div className="px-4 sm:px-6">
                    <ul className="mt-2 divide-y divide-gray-200">
                      {settings.map((setting) => 
                        <Switch.Group as="li" key={setting.key} className="py-4 flex items-center justify-between">
                          <div className="flex flex-col">
                            <Switch.Label as="a" className="text-sm font-medium text-gray-900" passive>
                              {setting.name}
                            </Switch.Label>
                            <Switch.Description className="text-sm text-gray-500">
                              {setting.description}
                            </Switch.Description>
                          </div>
                          <Switch
                            checked={setting.checked}
                            onChange={setting.onChange}
                            className={classNames(
                              setting.checked ? 'bg-indigo-500' : 'bg-gray-200',
                              'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                setting.checked ? 'translate-x-5' : 'translate-x-0',
                                'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                              )}
                            />
                          </Switch>
                        </Switch.Group>
                      )}
                    </ul>
                  </div>
                  </div>

                  {/* <div className="prose prose-indigo text-gray-500 mx-auto lg:max-w-none">
              <h3>Next Steps</h3>
              <p>
                Now you can customize your roles. Once your roles are ready, you can start inviting candidates, or preview the experience
                by creating yourself as a candidate.
              </p>
              </div> */}
            </div>
            <div className="mt-0 flex text-base max-w-prose mx-auto lg:max-w-none">
              <div className="rounded-md shadow">
                <button
                  // disabled={!(simpleInterview || softwareEngineerInteview || productManagerInterview)}
                  onClick={saveOnboarding}
                  className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Create role
                </button>
              </div>
              <div className="rounded-md shadow ml-4">
                <a
                  target="_blank"
                  rel='noreferrer'
                  href="https://help.drumcircle.io"
                  className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50"
                >
                  Learn more
                </a>
              </div>
            </div>
          </div>
          <div className="mt-12 relative text-base max-w-prose mx-auto lg:mt-0 lg:max-w-none">
            <svg
              className="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="bedc54bc-7371-44a2-a2bc-dc68d819ae60"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)" />
            </svg>
            <blockquote className="relative bg-white rounded-lg shadow-lg">
              <GreenhouseOnboarding/>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetStarted;
