import React, { Fragment, useState, useEffect } from 'react'
import { QuestionMarkCircleIcon } from '@heroicons/react/solid'
import { useMixPanel } from 'react-mixpanel-provider-component'
import axios from 'axios'
import WideSlideover from '../Shared/WideSlideover';
import { Webhook } from '../../Shared/types';
import { Switch } from '@headlessui/react';
import { NavLink } from 'react-router-dom';

function classNames(...classes: Array<any>) {
  return classes.filter(Boolean).join(' ')
}

interface WebhookSidebarProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const slideoverTitle = 'Webhook Integration'
const slideoverDescription = 'Connect Drumcircle with your applications and automate your custom workflows.'

export default function WebhookSidebar(props: WebhookSidebarProps) {
  const [webhooks, setWebhooks] = useState(Array<Webhook>());
  const [loadingWebhooks, setLoadingWebhooks] = useState(true);

  const fetchWebhooks = async () => {
    const response = await axios.get('/webhooks')
    setWebhooks(response.data.webhooks)
    setLoadingWebhooks(false)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchWebhooks() }, []);

  const addWebhook = (webhook: Webhook) => {
    setWebhooks([...webhooks, webhook])
  }

  if(loadingWebhooks) {
    return (<WideSlideover title={slideoverTitle} description={slideoverDescription} open={props.open} setOpen={props.setOpen}><></></WideSlideover>)
  }

  if(!loadingWebhooks && !webhooks) {
    return (<WideSlideover title={slideoverTitle} description={slideoverDescription} open={props.open} setOpen={props.setOpen}><div>Create Webhook</div></WideSlideover>)
  }

  return (
    <WideSlideover title={slideoverTitle} description={slideoverDescription} open={props.open} setOpen={props.setOpen}>
      <>
        <div className="space-y-6 pt-6 pb-5">
        <CreateWebhook addWebhook={addWebhook}/>
        <WebhookTable webhooks={webhooks} setWebhooks={setWebhooks}/>
        </div>
        <LearnMore/>
      </>    
    </WideSlideover>
  )
}



const LearnMore = () => {
  return (
    <div className="pt-4 pb-6">
      <div className="mt-4 flex text-sm">
        <a href="https://help.drumcircle.com/page/webhook-integration" className="group inline-flex items-center text-gray-500 hover:text-gray-900">
          <QuestionMarkCircleIcon
            className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
          <span className="ml-2">Learn more about our Webhook Integration</span>
        </a>
      </div>
    </div>
  )
}

interface WebhookTableProps {webhooks: Array<Webhook>, setWebhooks: React.Dispatch<React.SetStateAction<Webhook[]>> }
const WebhookTable = (props: WebhookTableProps) => {
  const {mixpanel} = useMixPanel()

  const toggleWebhookStatus = async (webhook: Webhook) => {
    const newStatus = !webhook.enabled

    // Toggle Immediately
    let items = Array.from(props.webhooks)
    let index = items.findIndex((item) => item.id === webhook.id )
    items[index].enabled = newStatus
    props.setWebhooks(items)
    
    // Save to Server
    const response = await axios.put(`/webhooks/${webhook.id}`, {enabled: newStatus})
    const updatedWebhooks = props.webhooks.map((original_webhook: Webhook) => {
      return original_webhook.id === webhook.id ? response.data.webhook : original_webhook
    })
    props.setWebhooks(updatedWebhooks)

    mixpanel.track('Webhooks - Toggle')
  }

  const webhookStatus = (webhook: Webhook) => {
    return (
      <Switch
        checked={webhook.enabled}
        onChange={() => toggleWebhookStatus(webhook) }
        className={classNames(
          webhook.enabled ? 'bg-teal-500' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
        )}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={classNames(
            webhook.enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
    )
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    URL
                  </th>
                  {/* <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Events
                  </th> */}
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {props.webhooks.map((webhook) => (
                  <tr key={webhook.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{webhook.url}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{webhookStatus(webhook)}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <NavLink exact to={`/webhooks/${webhook.id}`} className='text-indigo-600 hover:text-indigo-900'>View</NavLink>
                      </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

interface CreateWebhookData {url: string, subscribed_events: string}
interface CreateWebhookProps { addWebhook: (webhook: Webhook) => void }
const emptyCreateWebhookData: CreateWebhookData = {url: '', subscribed_events: ''}

const CreateWebhook = (props: CreateWebhookProps) => {
  const [submitting, setSubmitting] = useState(false);
  const [createWebhookData, setCreateWebhookData] = useState(emptyCreateWebhookData)

  const {mixpanel} = useMixPanel()
  
  const createWebhook = async () => {
    setSubmitting(true)
    const response = await axios.post('/webhooks/subscribe', createWebhookData)
    props.addWebhook(response.data.webhook)
    mixpanel.track('Webhooks - Create')
    setCreateWebhookData(emptyCreateWebhookData)
    setSubmitting(false)
  }

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Create Webhook</h3>
        <div className="mt-2 sm:flex sm:items-start sm:justify-between">
          <div className="max-w-xl text-sm text-gray-500">
            <p>
              Webhooks will make a POST request to the provided endpoint for events matching your configuration.
            </p>
          </div>
          <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
            <button
              type="button"
              disabled={submitting}
              onClick={createWebhook}
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
            >
              Create
            </button>
          </div>
        </div>
        <div className='mt-4'>
          <label htmlFor="url" className="block text-sm font-medium text-gray-700">
            URL
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="url"
              id="url"
              placeholder="https://www.example.com/webhooks"
              value={createWebhookData.url} 
              onChange={(e) => {setCreateWebhookData({...createWebhookData, url: e.target.value})}}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div className='mt-2'>
          <label htmlFor="events" className="block text-sm font-medium text-gray-700">
            Events
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="events"
              id="events"
              placeholder="*"
              value={createWebhookData.subscribed_events} 
              onChange={(e) => {setCreateWebhookData({...createWebhookData, subscribed_events: e.target.value})}}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
