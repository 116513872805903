import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useAuth } from "../Shared/AuthContext";
import LogoDark from './assets/logo-dark.png'
import queryString from 'query-string';
import { ReactComponent as GoogleLogo } from './assets/google.svg';

export default function Signup() {
  const location = useLocation();
  const parsedQueryString = queryString.parse(location.search)
  const initialEmailValue = parsedQueryString.email as string || ''

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState(initialEmailValue)
  const [password, setPassword] = useState("")
  const [invalidEmail, setInvalidEmail] = useState(false)
  
  const {signup} = useAuth()

  const handleSubmit = (event: { preventDefault: () => void; }) => {
    event.preventDefault()
    signup({firstName, lastName, email, password}).catch(err => {
      if(err.response.status === 422) {
        if(err.response.data.errors.email) {
          setInvalidEmail(true)
        }
      }
    }) 
  }
  
  useEffect(() => {setInvalidEmail(false)}, [email])
  
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <NavLink to='/'>
          <img
            className="mx-auto h-14 w-auto"
            src={LogoDark}
            alt="Drumcircle"
          />
        </NavLink>
        <h2 className="mt-8 text-center text-3xl font-extrabold text-gray-900">Create your free account</h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          or{' '}
          <NavLink to='/login' className="font-medium text-indigo-600 hover:text-indigo-500">
            sign in to your account
          </NavLink>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  First name
                </label>
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  autoFocus
                  required
                  onChange={e => setFirstName(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                  Last name
                </label>
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  autoComplete="family-name"
                  required
                  onChange={e => setLastName(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  className={classNames(invalidEmail ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500' : '', 'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm')}
                />
              </div>
              {invalidEmail &&
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  Your email address is not valid
                </p>
              }
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  minLength={8}
                  required
                  onChange={e => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Sign up
              </button>
            </div>
          </form>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">or continue with</span>
              </div>
            </div>

            <div className="mt-6 flex justify-center">
              <div>
                <a
                  href="/auth/google_oauth2"
                  className="inline-flex pt-2 pb-1 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Sign in with Twitter</span>
                  <GoogleLogo className="h-6 w-32"/>
                
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
