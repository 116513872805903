import { useEffect, useState } from 'react';
import { RoleStage } from '../../Shared/types';
import { useMixPanel } from 'react-mixpanel-provider-component';
import React from 'react';
import MessageEditor from './MessageEditor';

interface StageMessageEditorProps {
  stage: RoleStage
  message: string | null
  messageTitle: string | null
  setMessage: (message:string | null) => void
  setMessageTitle: (title:string | null) => void
}

function StageMessageEditor(props: StageMessageEditorProps) {
  const [message, setMessage] = useState(props.message)
  const {mixpanel} = useMixPanel()

  useEffect(() => {
    setMessage(props.message)
  }, [props])


  const renderMessageActionControls = () => {
    return (
      <button
        type="button"
        className="mx-2 mt-3 absolute right-0 top-0 flex-shrink-0 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        onClick={removeMessageAction}
      >
        Remove
      </button>
    )
  }
  
  const removeMessageAction = () => {
    props.setMessage(null)
    mixpanel.track('Role Action - Remove Message')
  }

  const addMessageAction = () => {
    props.setMessage('')
    mixpanel.track('Role Action - Add Message')
  }

  if(message === null) {
    return (
      <button
        type="button"
        className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={() => { addMessageAction() }}>
        Add message
      </button>
    )
  }

  return (
    <div className="send-message relative w-full justify-between items-center border border-gray-300 text-gray-700 rounded-md bg-white focus:outline-none overflow-hidden">
      <div className="border-0 border-b border-gray-300 text-gray-700">
        <input type="text" name="title" id="title" className="block w-full border-0 pt-2.5 text-lg font-medium placeholder-gray-500 focus:ring-0" placeholder="Title" value={props.messageTitle || ''} onChange={(e) => { props.setMessageTitle(e.target.value) }}/>
        {renderMessageActionControls()}
      </div>
      <div className="border-0 border-b border-gray-300 text-gray-700">
        <MessageEditor toolbarId={`message-${props.stage.id}`} placeholder="Write a new message..." value={props.message || ''} setValue={(stageId, text) => { props.setMessage(text) }} stageId={props.stage.id}/>
      </div>
      <div className="bg-gray-100 text-gray-700 rounded-b-md">
        <p className="text-xs text-gray-500 py-2 pl-2">Variables: <span className="text-gray-500">&#123;&#123;first_name&#125;&#125;</span>, &#123;&#123;last_name&#125;&#125;, &#123;&#123;url&#125;&#125;</p>
      </div>
    </div>
  )
}

export default React.memo(StageMessageEditor);
