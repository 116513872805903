import axios from 'axios';
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { GreenhouseRoleSelection } from './GreenhouseModal'

interface GreenhouseModalStep3Props {
  greenhouseRoles: Array<GreenhouseRoleSelection>
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function GreenhouseModalStep3(props: GreenhouseModalStep3Props) {
  const [roles, setRoles] = useState(props.greenhouseRoles)
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => { setRoles(props.greenhouseRoles) }, [props.greenhouseRoles])

  const updateGreenhouseRoleSelection = (role: GreenhouseRoleSelection) => {
    setRoles(roles.map((ghRole) => {
      if(ghRole.id === role.id && !ghRole.existing_role_status) { return {...role, selected: !role.selected} }
      return ghRole
    }))
  }

  const createRoles = async () => {
    setSubmitting(true)
    const newRoles = roles.filter(role => !role.existing_role_status && role.selected)
    await axios.post('/greenhouse/roles', {greenhouse: newRoles.map(role => role.id)} )
    window.location.reload()
  }

  const sortFunction = (a: GreenhouseRoleSelection, b: GreenhouseRoleSelection) => {
    if(a.existing_role_status && !b.existing_role_status) { return 1 }
    if(!a.existing_role_status && b.existing_role_status) { return -1 }
    return 0
  }

  return (
    <div className="mt-3 sm:mt-6">
      <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">Create roles from Greenhouse jobs</h3>

      <p className="mt-4 text-base text-gray-500">
        Select the Greenhouse jobs you would like to sync to Drumcircle. <br/><br/>
        
        Synced roles will be generated based on their Interview Plans and will automatically support two-way stage syncing.<br/>
        Once created you can adjust the stages and fine-tune the candidate experience.
      </p>

      <div className="px-8 mt-10 ">
      <div>

      <div className="grid grid-cols-3 gap-3 sm:grid-cols-3 sm:gap-10">
        {roles.sort(sortFunction).map((role) =>
          <div key={role.id} onClick={() => { updateGreenhouseRoleSelection(role) }} className={classNames(
            role.existing_role_status ? 'cursor-not-allowed bg-indigo-100 border-transparent text-indigo-700 hover:bg-indigo-200' : 'cursor-pointer focus:outline-none',
            role.selected ? 'bg-indigo-600 border-transparent text-white hover:bg-indigo-700' : 'bg-white border-gray-200 hover:bg-gray-50',
            'border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium sm:flex-1')}>
              <p>{role.name}</p>
          </div>
        )}
      </div>
    </div>
        
      </div>


      <div className="mt-12 sm:flex sm:justify-end">
        <button
          type="button"
          disabled={submitting}
          className="mt-3 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
          onClick={createRoles}
        >
          Create Roles
        </button>
      </div>
      </div>
  )
}