import React, { Fragment, useState } from 'react'
import Layout from './Layout'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import { MinusIcon, CheckIcon } from '@heroicons/react/solid'

const tiers = [
  { name: 'Starter', href: '/signup', price: {monthly: 0, annual: 0}, description: 'The essentials to provide great candidate experience.' },
  {
    name: 'Growth',
    href: '/signup',
    price: {monthly: 119, annual: 99},
    description: 'A plan built to power your rapidly growing business.',
  },
  {
    name: 'Scale',
    href: '/signup',
    price: {monthly: 299, annual: 249},
    description: 'Priority support and power features to scale your organization.',
  },
]
const sections = [
  {
    name: 'Features',
    features: [
      { name: 'Candidates', tiers: { Starter: '12 per month', Growth: '100 per month', Scale: '300 per month' } },
      { name: 'Unlimited users', tiers: { Starter: true, Growth: true, Scale: true } },
      { name: 'Unlimited roles', tiers: { Starter: true, Growth: true, Scale: true } }
    ],
  },
  {
    name: 'Integrations',
    features: [
      { name: 'Calendly', tiers: { Starter: true, Growth: true, Scale: true } },
      { name: 'Greenhouse', tiers: { Growth: true, Scale: true } },
      //{ name: 'Zapier', tiers: { Growth: true, Scale: true } },
      //{ name: 'Webhooks', tiers: { Scale: true } },
    ],
  },
  {
    name: 'Support',
    features: [
      { name: 'Email support', tiers: { Starter: true, Growth: true, Scale: true } },
      { name: 'Chat support', tiers: { Starter: true, Growth: true, Scale: true } },
      { name: 'Priority support', tiers: { Scale: true } },
    ],
  },
]

export default function Pricing() {
  const [billingPeriod, setBillingPeriod] = useState('annual')



  const priceText = (price: {monthly: number, annual: number}) => {
    const activePrice = billingPeriod === 'annual' ? price.annual : price.monthly
    return activePrice === 0 ? 'Free' : `$${activePrice}`
  }

  return (
    <Layout>
      <>
        <div className="overflow-hidden">
          
        <div className="max-w-7xl mx-auto py-24 px-4 bg-white sm:px-6 lg:px-8 relative">
         
        <div className="bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:flex lg:justify-between">
        <div className="max-w-xl">
          <h2 className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Pricing Plans
          </h2>
          <p className="mt-5 text-xl text-gray-500">
            Sign up now and get our Scale plan <em>totally free</em> for 6 months! Deliver incredible candidate experiences risk free.
            {/* Sign up today for free and get 6-months of our Scale plan.  Nail your candidate experience now and only pay later. */}
          {/* Sign up for free and start with a 14-day trial of our Growth plan. After your trial ends, enjoy the free Starter plan forever. */}
          </p>
        </div>
        <div className="mt-10 w-full max-w-xs">
        <div className="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
              <button
                type="button"
                onClick={ () => setBillingPeriod('annual')}
                className={classNames(
                  billingPeriod === 'annual'
                    ? 'bg-white border border-gray-200 shadow-sm text-gray-900'
                    : 'border border-transparent text-gray-700',
                  'relative w-1/2 rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none sm:w-auto sm:px-8'
                )}
              >
                Annual billing
              </button>
              <button
                type="button"
                onClick={ () => setBillingPeriod('monthly')}
                className={classNames(
                  billingPeriod === 'monthly'
                    ? 'bg-white border border-gray-200 shadow-sm textz-gray-900'
                    : 'border border-transparent text-gray-700',
                  'ml-0.5 relative w-1/2 rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none sm:w-auto sm:px-8'
                )}
              >
                Monthly billing
              </button>
            </div>
        </div>
      </div>
    </div>

          <div className="bg-white">
      <div className="max-w-7xl mx-auto bg-white pt-16 sm:pt-24 sm:px-6 lg:px-8">
        {/* xs to lg */}
        <div className="max-w-2xl mx-auto space-y-16 lg:hidden">
          {tiers.map((tier, tierIdx) => (
            <section key={tier.name}>
              <div className="px-4 mb-8">
                <h2 className="text-lg leading-6 font-medium text-gray-900">{tier.name}</h2>
                <p className="mt-4">
                  <span className="text-4xl font-extrabold text-gray-900">{priceText(tier.price) }</span>{' '}
                  <span className="text-base font-medium text-gray-500">/mo</span>
                </p>
                <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
                <NavLink
                  exact to={tier.href}
                  className="mt-6 block border border-gray-800 rounded-md bg-gray-800 w-full py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                >
                  Get started
                </NavLink>
              </div>

              {sections.map((section) => (
                <table key={section.name} className="w-full">
                  <caption className="bg-gray-50 border-t border-gray-200 py-3 px-4 text-sm font-medium text-gray-900 text-left">
                    {section.name}
                  </caption>
                  <thead>
                    <tr>
                      <th className="sr-only" scope="col">
                        Feature
                      </th>
                      <th className="sr-only" scope="col">
                        Included
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {section.features.map((feature: any) => (
                      <tr key={feature.name} className="border-t border-gray-200">
                        <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                          {feature.name}
                        </th>
                        <td className="py-5 pr-4">
                          {typeof feature.tiers[tier.name] === 'string' ? (
                            <span className="block text-sm text-gray-700 text-right">{feature.tiers[tier.name]}</span>
                          ) : (
                            <>
                              {feature.tiers[tier.name] === true ? (
                                <CheckIcon className="ml-auto h-5 w-5 text-green-500" aria-hidden="true" />
                              ) : (
                                <MinusIcon className="ml-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                              )}

                              <span className="sr-only">{feature.tiers[tier.name] === true ? 'Yes' : 'No'}</span>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))}
            </section>
          ))}
        </div>

        {/* lg+ */}
        <div className="hidden lg:block">
          <table className="w-full h-px table-fixed">
            <caption className="sr-only">Pricing plan comparison</caption>
            <thead>
              <tr>
                <th className="pb-4 px-6 text-sm font-medium text-gray-900 text-left" scope="col">
                  <span className="sr-only">Feature by</span>
                  <span>Plans</span>
                </th>
                {tiers.map((tier) => (
                  <th
                    key={tier.name}
                    className="w-1/4 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left"
                    scope="col"
                  >
                    {tier.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="border-t border-gray-200 divide-y divide-gray-200">
              <tr>
                <th className="py-8 px-6 text-sm font-medium text-gray-900 text-left align-top" scope="row">
                  Pricing
                </th>
                {tiers.map((tier) => (
                  <td key={tier.name} className="h-full py-8 px-6 align-top">
                    <div className="relative h-full table">
                      <p>
                        <span className="text-4xl font-extrabold text-gray-900">{priceText(tier.price) }</span>{' '}
                        {tier.price.monthly !== 0 &&
                          <span className="text-base font-medium text-gray-500">/mo</span>
                        }
                        
                      </p>
                      <p className="mt-4 mb-16 text-sm text-gray-500">{tier.description}</p>
                      <NavLink
                        exact to={tier.href}
                        className="absolute bottom-0 flex-grow block w-full bg-gray-800 border border-gray-800 rounded-md 5 py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                      >
                        Get started
                      </NavLink>
                    </div>
                  </td>
                ))}
              </tr>
              {sections.map((section) => (
                <Fragment key={section.name}>
                  <tr>
                    <th
                      className="bg-gray-50 py-3 pl-6 text-sm font-medium text-gray-900 text-left"
                      colSpan={4}
                      scope="colgroup"
                    >
                      {section.name}
                    </th>
                  </tr>
                  {section.features.map((feature: any) => (
                    <tr key={feature.name}>
                      <th className="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
                        {feature.name}
                      </th>
                      {tiers.map((tier) => (
                        <td key={tier.name} className="py-5 px-6">
                          {typeof feature.tiers[tier.name] === 'string' ? (
                            <span className="block text-sm text-gray-700">{feature.tiers[tier.name]}</span>
                          ) : (
                            <>
                              {feature.tiers[tier.name] === true ? (
                                <CheckIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
                              ) : (
                                <MinusIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                              )}

                              <span className="sr-only">
                                {feature.tiers[tier.name] === true ? 'Included' : 'Not included'} in {tier.name}
                              </span>
                            </>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>
    <div className="bg-indigo-50">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
          <span className="block">Need even more</span>
          <span className="block text-indigo-600">Get the Enterprise plan!</span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
            <NavLink
              exact to='/enterprise'
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Contact us
            </NavLink>
          </div>
        </div>
      </div>
    </div>
        
          
        </div>
      </>
    </Layout>
  )
}
