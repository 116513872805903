import { RoleStage } from "../../Shared/types";
import GreenhouseIcon from '../assets/greenhouse-icon.png';

interface GreenhouseStageSyncProps {
  stage: RoleStage
}

export default function GreenhouseStageBadge(props: GreenhouseStageSyncProps) {
  if(!props.stage.source) { return null }

  return (
    <div className="relative flex">
      <div className="inline-flex flex-1 shadow-sm rounded-md divide-x divide-green-600 float-right">
        <div className="relative z-0 inline-flex flex-1 shadow-sm rounded-md divide-x divide-green-600">
          <div className="relative inline-flex flex-1 items-center bg-greenhouse-green py-2 pl-3 pr-4 border border-transparent rounded-md shadow-sm text-white">
            <img src={GreenhouseIcon} alt="Greenhouse Sync" className="h-5 w-5"/>
            <p className="ml-2.5 text-sm font-medium">{props.stage.source.name}</p>
          </div>
          {/* <button className="relative inline-flex items-center bg-greenhouse-green p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-greenhouse-green-dark focus:outline-none focus:z-10">
            <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
          </button> */}
        </div>
      </div>
    </div>
  )
}