import { PlusIcon } from '@heroicons/react/solid';
import axios from 'axios';
import React, { useState } from 'react';
import { useMixPanel } from 'react-mixpanel-provider-component';
import { OrganizationInvite } from '../../Shared/types';

interface CreateInviteProps {
  addInvite: (invite: OrganizationInvite) => void
}

function InviteMember(props: CreateInviteProps): JSX.Element {
  const [email, setEmail] = useState('')

  const {mixpanel} = useMixPanel()

  const createInvite = async () => {
    const response = await axios.post('/organization/invite', {email: email})
    props.addInvite(response.data.invite)
    mixpanel.track('Organization - Invite')
    setEmail('')
  }

  return (
    <div className="space-y-1">
      <div className="mt-6 grid grid-cols-12 gap-6">
        <div className="col-span-8">
          <div className="flex">
            <div className="flex-grow">
              <input
                type="text"
                name="add-team-members"
                id="add-team-members"
                className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border-gray-300 rounded-md"
                placeholder="Email address"
                value={email}
                onChange={e => setEmail(e.target.value)}
                aria-describedby="add-team-members-helper"
              />
            </div>
            <span className="ml-3">
              <button
                type="button"
                onClick={createInvite}
                className="bg-white inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
              >
                <PlusIcon className="-ml-2 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                <span>Add</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InviteMember;
