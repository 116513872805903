/* eslint-disable react-hooks/exhaustive-deps */
import { Transition } from "@headlessui/react"
import React, { Fragment, useEffect, useState } from "react"
import { CalendarIcon } from '@heroicons/react/outline'
import Linkify from 'react-linkify';
import moment from 'moment';
import { CandidateInvite, CandidateInviteLocation, CandidateNotification } from "../../Shared/types"
import { useExperience } from "../ExperienceContext"

interface InviteNotificationProps {
  notification: CandidateNotification
}

export default function InviteNotification(props: InviteNotificationProps) {
  const [show, setShow] = useState(true)
  const {markNotificationAsRead} = useExperience()
  const invite = JSON.parse(props.notification.payload) as CandidateInvite

  useEffect(() => { if(!show) { markNotificationAsRead(props.notification)} }, [show])
  
  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5">
        <div className="w-0 flex-1 p-4 self-center">
          <div className="flex items-start">
            <div className="flex-shrink-0 pt-0.5">
              <CalendarIcon className="w-10 h-10 p-1.5 rounded-full text-sky-50 bg-sky-500 border-2 border-transparent" />
            </div>
            <div className="ml-3 w-0 flex-1">
              <p className="text-sm font-medium text-gray-900">{invite.summary}</p>
              <p className="mt-1 text-sm text-gray-500">{moment(invite.event_start).format("MMM Do, h:mm a")} ({getMinutes(invite)} min)</p>
              <LocationDetails location={invite.location} />
              {/* <p className="mt-1 text-sm text-gray-500">{invite.description}</p> */}
            </div>
          </div>
        </div>
        <div className="flex border-l border-gray-200">
            <button
              className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onClick={() => {
                setShow(false)
              }}
            >
              Close
            </button>
          </div>
      </div>
    </Transition>
  )
}

function getMinutes(invite: CandidateInvite) {
  const diff = moment.duration(moment(invite.event_end).diff(invite.event_start))
  return (diff.hours() * 60) + diff.minutes()
}

const LocationDetails = (props: {location: CandidateInviteLocation}) => {
  const locationLinkDecorator = (text: string) => {
    if(text.startsWith('https://zoom.us')) { return 'Zoom Meeting' }
    if(text.startsWith('https://meet.google.com/')) { return 'Google Meet' }
    return 'Meeting Location'
  }
  //todo - divider line?
  //todo - cancel/reschedule if present?
  //todo - how to indicate canceled
  //todo - indicate past event?
  

  switch(props.location.type) {
    case 'unknown': 
      return (
        <Linkify textDecorator={locationLinkDecorator} componentDecorator={(decoratedHref, decoratedText, key) => (
          <a target="blank" href={decoratedHref} key={key}>
            {decoratedText}
          </a>
        )} ><p className="line-clamp-2 text-sm text-indigo-600">{props.location.text}</p></Linkify>
      )
    case 'google_conference':
    case 'meet':
      return (
        <a target="_blank" rel="noreferrer" href={props.location.join_url} className="line-clamp-2 text-sm text-indigo-600">Google Meet</a>
      )
    case 'zoom':
      const zoomText = props.location.data.password ? `Zoom Meeting - Password: ${props.location.data.password}` : 'Zoom Meeting'
      return (
        <a target="_blank" rel="noreferrer" href={props.location.join_url} className="line-clamp-2 text-sm text-indigo-600">{zoomText}</a>
      )
    case 'microsoft_teams_conference':
      return (
        <a target="_blank" rel="noreferrer" href={props.location.join_url} className="line-clamp-2 text-sm text-indigo-600">MS Teams Meeting</a>
      )
    default:
      if(props.location.join_url) {
        return (
          <a target="_blank" rel="noreferrer" href={props.location.join_url} className="line-clamp-2 text-sm text-indigo-600">Join Meeting</a>
        )
      } else {
        const locationText = props.location.location || props.location.text
        if(!locationText) { return null }

        return (
          <Linkify textDecorator={locationLinkDecorator} componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )} ><p className="line-clamp-2 text-sm text-indigo-600">{locationText}</p></Linkify>
        )
      }
  }
}
