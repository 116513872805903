import axios from "axios";
import React from "react";
import { RoleAttachment, RoleAttachmentYoutubeLink } from '../../types';

interface YoutubeTileEditorProps {
  link: RoleAttachmentYoutubeLink
  updateAttachment: (attachment: RoleAttachment) => void
}

export default function YoutubeTileEditor(props: YoutubeTileEditorProps) {
  const generateEmbedUrl = async () => {
    const response = await axios.get(`https://www.youtube.com/oembed?url=${props.link.payload.url}&format=json`)

    props.updateAttachment({...props.link, payload: {...props.link.payload, embedHtml: response.data.html}})
  }

  return (
    <div className="">
      <div className="p-4 space-y-6">
        <div>
          <label htmlFor="url" className="block text-sm font-medium text-gray-700">
            Video URL
          </label>
          <div className="mt-1">
            <input
              type="url"
              name="url"
              id="url"
              value={props.link.payload.url}
              onChange={(e) => { props.updateAttachment({...props.link, payload: {...props.link.payload, url: e.target.value}})} }
              onBlur={() => { generateEmbedUrl() }}
              autoComplete="url"
              placeholder='https://youtube.com/watch?v='
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
      </div>
    </div>
  )
}