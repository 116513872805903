import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { GreenhouseCustomField } from "../../Shared/types";

interface GreenhouseContextInterface {
  customFields: Array<GreenhouseCustomField>
}

export const GreenhouseContext = createContext({} as GreenhouseContextInterface);
export const useGreenhouse: () => GreenhouseContextInterface = () => useContext(GreenhouseContext)

interface Role {
  id: string
  source?: {
    id: string
    external_id: string
    name: string
    provider: string
  }
}

interface GreenhouseProviderProps {
  role: Role
  children: JSX.Element
}

export const GreenhouseProvider = (props: GreenhouseProviderProps) => {
  const [loadingCustomFields, setLoadingCustomFields] = useState(true)
  const [customFields, setCustomFields] = useState(Array<GreenhouseCustomField>())

  const fetchGreenhouseCustomFields = async () => {
    if(props.role.source) {
      const response = await axios.get(`/greenhouse/custom-fields`)
      setCustomFields(response.data.custom_fields)
      setLoadingCustomFields(false)
    } else {
      setLoadingCustomFields(false)
    }
  }

  useEffect(() => {
    if(props.role.source) { fetchGreenhouseCustomFields() }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.role.source])

  const readyToShow = !loadingCustomFields || !props.role.source
  
  return (
    <GreenhouseContext.Provider value={{ customFields }}>
      {readyToShow && props.children}
    </GreenhouseContext.Provider>
  )
}