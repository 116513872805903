import { Draggable, Droppable } from 'react-beautiful-dnd';
import { BrandingTheme, RoleAttachment, RoleStageFile } from '../../Shared/types';
import { useMixPanel } from 'react-mixpanel-provider-component';
import { XIcon } from '@heroicons/react/solid';
import React from 'react';
import CallToActionTile from '../../Shared/Tiles/CallToActionTile/CallToActionTile';
import UserProfileTile from '../../Shared/Tiles/UserProfileTile/UserProfileTile';
import classNames from 'classnames';
import CalendlyTile from '../../Shared/Tiles/CalendlyTile/CalendlyTile';
import FileRequestTile from '../../Shared/Tiles/FileRequestTile/FileRequestTile';
import LocationTile from '../../Shared/Tiles/LocationTile/LocationTile';
import BlurbTile from '../../Shared/Tiles/BlurbTile/BlurbTile';
import YoutubeTile from '../../Shared/Tiles/YoutubeTile/YoutubeTile';
import AddTile from './AddTile';
import QuoteTile from '../../Shared/Tiles/QuoteTile/QuoteTile';
import SampleAvatar from '../assets/molly.png'
import { DocumentTextIcon } from '@heroicons/react/outline';
import TypeformTile from '../../Shared/Tiles/TypeformTile/TypeformTile';
import FormTile from '../../Shared/Tiles/FormTile/FormTile';

interface RoleStageTilesProps {
  roleTitle: string
  stageTitle: string
  attachments: Array<RoleAttachment>
  stageId: string
  setAttachments: (stageId: string, attachments: Array<RoleAttachment>) => void
  activeAttachment: string
  setActiveAttachment: React.Dispatch<React.SetStateAction<string>>
  files: Array<RoleStageFile>
  branding: BrandingTheme
}

function RoleStageTiles(props: RoleStageTilesProps) {
  const {mixpanel} = useMixPanel()

  const deleteAttachment = (index: number) => {
    mixpanel.track(`Role Attachment - Delete`)

    if(props.attachments[index].id === props.activeAttachment) {
      props.setActiveAttachment('')
    }

    props.setAttachments(props.stageId, props.attachments.filter((attachment, i) => i !== index ))
  }

  const renderAttachmentControls = (index: number) => {
    return (
      <div className="hidden group-hover:block ml-3 absolute right-2 top-2 flex-shrink-0 z-20">
        <button
          onClick={(e) => { e.stopPropagation(); deleteAttachment(index) }}
          className="inline-flex items-center p-px rounded-full text-sm shadow font-medium bg-white border border-gray-100 text-indigo-400 hover:text-indigo-600 focus:outline-none"
        >
          <span className="sr-only">Delete tile</span>
          <XIcon className="h-3 w-3" aria-hidden="true" />
        </button>
      </div>
    )
  }
  
  const renderAttachment = (attachment: RoleAttachment, index: number) => {
    switch(attachment.type) {
      case 'link': return <CallToActionTile link={attachment} index={index} />
      case 'calendly_link': return <CalendlyTile calendlyLink={attachment} index={index} />
      case 'location': return <LocationTile location={attachment} index={index} />
      case 'user_profile': return <UserProfileTile userProfile={attachment} index={index} /> 
      case 'youtube_link': return <YoutubeTile link={attachment} index={index} /> 
      case 'file_request': return <FileRequestTile fileRequest={attachment} index={index} />
      case 'blurb': return <BlurbTile blurb={attachment} index={index} /> 
      case 'quote': return <QuoteTile quote={attachment} index={index} />
      case 'typeform': return <TypeformTile typeform={attachment} index={index} sandbox={true} />
      case 'form': return <FormTile form={attachment} index={index} />
      default: return null
    }
  }

  return (
    <div className="flex-col w-full p-2 sm:p-4 lg:px-8 lg:py-12">
      {/* {props.branding.logo &&
        <div className="flex justify-center pb-2 sm:pb-4 lg:pb-8">
          <img
            className="inline-block max-w-xs max-h-40"
            src={props.branding.logo}
            alt=""
          />
        </div>
      } */}
      <div className="mx-auto max-w-2xl rounded-lg overflow-hidden bg-white shadow divide-y divide-gray-200">
        
        {/* Candidate Header */}
        <div className="py-6 space-y-6">
          <div className="md:flex md:items-center md:justify-between md:space-x-5 px-4 sm:px-6">
            <div className="flex items-start space-x-5">
              <div className="flex-shrink-0">
                <div className="relative">
                  <img
                    className="h-16 w-16 rounded-full"
                    src={SampleAvatar}
                    alt="Candidate Avatar"
                  />
                  <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
                </div>
              </div>
              <div className="pt-1.5">
                <h1 className="text-2xl font-bold text-gray-900">Candidate Name</h1>
                <p className="text-sm font-medium text-gray-500">
                  for{' '}{props.roleTitle}
                </p>
              </div>
            </div>

            <div className="mt-6 relative flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
              <div className="relative">
                <div className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-6 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
                  {props.stageTitle}
                </div>
              </div>
            </div>
          </div>

          {/* Shared Files Preview */}
          {props.files.length > 0 &&
            <div className="px-4 sm:px-6">
              <ul className="border border-gray-200 divide-y divide-gray-200 rounded-md bg-gray-50">
                {props.files.map((attachment, index) => (
                  <li className="px-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <DocumentTextIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span className="ml-2 flex-1 w-0 truncate text-sm text-gray-600">{attachment.name}</span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                    <span className="font-medium text-gray-600">
                      Download
                    </span>
                    </div>
                  </li>
                  
                ))}
              </ul>
            </div>
          }
        </div>

        {/* Add Tile Button */}
        <AddTile attachments={props.attachments} stageId={props.stageId} setAttachments={props.setAttachments} setActiveAttachment={props.setActiveAttachment}/>

        {/* Tile Stack */}
        <Droppable droppableId={`attachments.${props.stageId}`} type='attachment'>
          {(provided) => (
            <div className="attachments overflow-hidden divide-y divide-gray-200" {...provided.droppableProps} ref={provided.innerRef}>
                {props.attachments.map((attachment, index) => (
                  <Draggable key={attachment.id} draggableId={attachment.id} index={index}>
                    {(provided) => (
                      <div {...provided.draggableProps} ref={provided.innerRef as any}>
                        <div className="relative w-full group" {...provided.dragHandleProps} onClick={() => { props.setActiveAttachment(attachment.id) }}>
                          <div className={classNames(props.activeAttachment === attachment.id ? 'border-4 border-sky-500' : '', "transparent absolute top-0 left-0 right-0 bottom-0 z-10")}></div>
                          {renderAttachment(attachment, index)}
                          {renderAttachmentControls(index)}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  )
}

// export default React.memo(Attachments2);
export default RoleStageTiles;
