import { PaperClipIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useExperience } from '../../../Candidate/ExperienceContext';
import { RoleAttachmentFileRequest } from '../../types';

interface FileRequestTileProps {
  fileRequest: RoleAttachmentFileRequest
  index: number
}
export default function FileRequestTile(props: FileRequestTileProps) {
  const {uploadFile} = useExperience()

  return (
    <div className="relative bg-white">
      <div className="relative bg-indigo-600 items-center">
        <div className="absolute inset-0 overflow-hidden rounded-md block" aria-hidden="true">
          <svg
            className="absolute bottom-full left-full transform translate-y-1/3 -translate-x-20 sm:-translate-x-1/4"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-indigo-500" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
          </svg>
          <svg
            className="hidden sm:block absolute top-full transform -translate-y-1/3 -translate-x-3/4"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-indigo-500" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
          </svg>
        </div>
        <div className="relative mx-auto py-4 sm:py-12 sm:pb-8 px-4 sm:pl-24 sm:pr-12 space-y-6">
          <h2 className={classNames(props.fileRequest.payload.description ? 'text-3xl font-extrabold' : 'text-2xl font-bold', "text-white")}>
            {props.fileRequest.payload.title}
          </h2>
          {props.fileRequest.payload.description &&
            <p className="text-lg text-white">
              {props.fileRequest.payload.description}
            </p>
          }
          {props.fileRequest.payload.file ?
            <a
              href={props.fileRequest.payload.file.url}
              target='_blank'
              rel="noreferrer"
              className="break-inside relative mb-8 w-full sm:w-auto shadow-md inline-flex justify-center items-center py-3 px-5 border border-transparent  text-base font-medium rounded-md text-indigo-700 bg-white hover:bg-gray-50 focus:outline-none"
            >
              <PaperClipIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              <span>{props.fileRequest.payload.file.name}</span>
            </a>
          :
            <label
              htmlFor={props.fileRequest.id}
              className="break-inside relative mb-8 w-full sm:w-auto shadow-md inline-flex justify-center items-center py-3 px-5 border border-transparent  text-base font-medium rounded-md text-indigo-700 bg-white hover:bg-opacity-90 focus:outline-none cursor-pointer"
            >
              {props.fileRequest.payload.upload_link ?
                <a href={props.fileRequest.payload.upload_link} target="_blank" rel="noreferrer">{props.fileRequest.payload.button_text || 'Upload File'}</a>
              :
                <>
                  <span>{props.fileRequest.payload.button_text || 'Upload File'}</span>
                  <input
                    name="file-request"
                    id={props.fileRequest.id}
                    type="file"
                    onChange={(e) => { uploadFile(e, props.fileRequest) }}
                    className="hidden absolute opacity-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                  />
                </>
              }
              
            </label>
          }
          
        </div>
      </div>
    </div>
  )
}