import React, { useEffect, useState } from 'react'
import { QuestionMarkCircleIcon } from '@heroicons/react/solid'
import WideSlideover from '../Shared/WideSlideover';
import axios from 'axios';
import { useMixPanel } from 'react-mixpanel-provider-component';

interface CalendlySidebarProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const slideoverTitle = 'Calendly Integration'
const slideoverDescription = 'Connect Drumcircle with Calendly to seamlessly allow Candidates to schedule with your team.'

export default function CalendlySidebar(props: CalendlySidebarProps) {
  const [integrationStatus, setIntegrationStatus] = useState(false)
  const [loadingIntegrationStatus, setLoadingIntegrationStatus] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  
  const {mixpanel} = useMixPanel()

  const fetchIntegrationStatus = async () => {
    const response = await axios.get('/calendly')
    setIntegrationStatus(response.data.calendly)
    setLoadingIntegrationStatus(false)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchIntegrationStatus() }, []);

  const deleteCalendly = async () => {
    setSubmitting(true)
    await axios.delete('/calendly')
    setIntegrationStatus(false)
    mixpanel.track('Calendly - Disconnect Integration')
    setSubmitting(false)
  }

  if(loadingIntegrationStatus) {
    return (<WideSlideover title={slideoverTitle} description={slideoverDescription} open={props.open} setOpen={props.setOpen}><></></WideSlideover>)
  }

  if(!loadingIntegrationStatus && integrationStatus) {
    return (
      <WideSlideover title={slideoverTitle} description={slideoverDescription} open={props.open} setOpen={props.setOpen}>
        <>
          <div className="space-y-6 pt-6 pb-5">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <button
                  type="button"
                  onClick={() => { deleteCalendly() }}
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                >
                  Disconnect Calendly Account
                </button>
              </div>
            </div>
          </div>
          <LearnMore/>
        </>    
      </WideSlideover>
    )
  }

  return (
    <WideSlideover title={slideoverTitle} description={slideoverDescription} open={props.open} setOpen={props.setOpen}>
      <>
        <div className="space-y-6 pt-6 pb-5">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <a href="/auth/calendly" className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Connect Calendly Account</a>
              <div className="mt-4 max-w-xl text-sm text-gray-500">
                <p>
                  You must be on a paid Calendly plan to enable this feature.
                </p>
              </div>
            </div>
          </div>
        </div>
        <LearnMore/>
      </>    
    </WideSlideover>
  )
}

const LearnMore = () => {
  return (
    <div className="pt-4 pb-6">
      <div className="mt-4 flex text-sm">
        <a href="https://help.drumcircle.io/article/10-calendly-integration" target="_blank" rel="noreferrer" className="group inline-flex items-center text-gray-500 hover:text-gray-900">
          <QuestionMarkCircleIcon
            className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
          <span className="ml-2">Learn more about our Calendly Integration</span>
        </a>
      </div>
    </div>
  )
}
