import React, { useCallback, useMemo, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import './MessageEditor.css'

interface EditorProps {
  toolbarId: string
  placeholder: string
  value: string
  stageId: string
  setValue: (stageId: string, text: string) => void
}
const Size = Quill.import("formats/size");
Size.whitelist = ["small", "medium", "large", "h1", "h2", "h3", "h4"];
Quill.register(Size, true);

const BlockEmbed = Quill.import('blots/block/embed');
class DividerBlot extends BlockEmbed { }
DividerBlot.blotName = 'divider';
DividerBlot.tagName = 'hr';
Quill.register(DividerBlot);


const MessageEditor = (props: EditorProps) => {
  const quill = useRef<ReactQuill>(null);

  function insertHr() {
    if(quill && quill.current && quill.current.editor) { 
      const range = quill.current.editor.getSelection()
      if(range) {
        quill.current.editor.insertText(range.index, '\n', 'user')
        quill.current.editor.insertEmbed(range.index + 1, 'divider', true, 'user')
        quill.current.editor.setSelection(range.index + 2, 1, 'silent')
      }
    }
  }

  const module =  useMemo(() => { return {clipboard: { matchVisual: false }, toolbar: { container: "#" + props.toolbarId, handlers: { insertHeart: insertHr}}}  },[]);
  const style =  useMemo(() => { return {border: 'none'}  },[]);
  const onChange = useCallback((content, delta, source, editor) => { if(source === 'user') { console.log('onchange', source); props.setValue(props.stageId, content) } }, [props.stageId, props.setValue])

  return (
    <div>
      <ReactQuill
        ref={quill}
        modules={module}
        className='rounded-md border-none font-sans message-quill-editor'
        style={style}
        value={props.value}
        placeholder={props.placeholder}
        onChange={onChange}
      />
      <CustomToolbar id={props.toolbarId} />
    </div>
  )
}

export default React.memo(MessageEditor)

const CustomToolbar = ({id = 'toolbar'}) => (
  <div id={id}>
    <span className="ql-formats">
      <select className="ql-header">
        <option value="1">Heading 1</option>
        <option value="2">Heading 2</option>
        <option value="3">Heading 3</option>
        <option selected>Normal</option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered"></button>
      <button className="ql-list" value="bullet"></button>
    </span>
    <span className="ql-formats">
      <button className="ql-link"></button>
    </span>
  </div>
)