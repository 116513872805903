import React from "react";
import { RoleAttachment, RoleAttachmentTypeform } from '../../types';

interface TypeformTileEditorProps {
  typeform: RoleAttachmentTypeform
  updateAttachment: (attachment: RoleAttachment) => void
}

export default function TypeformTileEditor(props: TypeformTileEditorProps) {
  return (
    <>
      <div className="p-4 space-y-6">
        <div>
          <label htmlFor="form_id" className="block text-sm font-medium text-gray-700">
            Form ID
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="form_id"
              id="form_id"
              value={props.typeform.payload.form_id}
              onChange={(e) => { props.updateAttachment({...props.typeform, payload: {...props.typeform.payload, form_id: e.target.value}})} }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>

        <div>
          <label htmlFor="height" className="block text-sm font-medium text-gray-700">
            Height (px)
          </label>
          <div className="mt-1">
            <input
              type="number"
              name="height"
              id="height"
              placeholder="400"
              value={props.typeform.payload.height}
              onChange={(e) => { props.updateAttachment({...props.typeform, payload: {...props.typeform.payload, height: e.target.value}})} }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
      </div>
    </>
  )
}