import axios from 'axios';
import moment from 'moment';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMixPanel } from 'react-mixpanel-provider-component';
import { useHistory } from 'react-router-dom';
import { Subscription, User } from './types';

interface LoginParams {
  email: string
  password: string
}

interface SignupParams {
  firstName: string
  lastName: string
  email: string
  password: string
  inviteToken?: string
}

interface AuthContextInterface {
  fetchLoginStatus: () => Promise<void>
  currentUser: User | null
  subscriptions: Array<Subscription>
  setSubscriptions: (subscriptions: Array<Subscription>) => void
  setCurrentUser: (newUser: User) => void
  logout: () => void
  login: (user: LoginParams) => Promise<void>
  signup: (user: SignupParams) => Promise<void>
}

interface AuthProviderProps {
  children: JSX.Element
}

interface AuthData {
  user: User | null
  subscriptions: Array<Subscription>
}

const AuthContext = createContext({} as AuthContextInterface);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [authData, setAuthData] = useState({user: null, subscriptions: []} as AuthData)
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  const history = useHistory()
  const { mixpanel } = useMixPanel()

  const fetchLoginStatus = async () => {
    setIsCheckingAuth(true)
    
    const response = await axios.get('/logged_in')
    setAuthData({user: response.data.user, subscriptions: response.data.subscriptions})

    setIsCheckingAuth(false)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchLoginStatus() }, []);

  const identifyUser = () => {
    if(authData.user) {
      mixpanel.identify(authData.user.id)
      mixpanel.people.set({
        $name: `${authData.user.first_name} ${authData.user.last_name}`,
        $email: authData.user.email,
        bio: authData.user.bio,
        role: authData.user.role,
        orgId: authData.user.org_id,
        subscriptionPlan: currentSubscription()?.plan,
        subscriptionStatus: currentSubscription()?.status,
        susbcriptionPeriodStart: currentSubscription()?.current_period_start,
        subscriptionPeriodEnd: currentSubscription()?.current_period_end
      })
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { identifyUser() }, [authData])
  
  const currentSubscription = () => {
    return authData.subscriptions.find(sub => moment(sub.current_period_end).isAfter())
  }

  const setCurrentUser = (newUser: User) => {
    setAuthData({...authData, user: newUser})
  }

  const setSubscriptions = (subscriptions: Array<Subscription>) => {
    setAuthData({...authData, subscriptions: subscriptions})
  }

  const logout = async () => {
    await axios.delete('/logout')
    setAuthData({user: null, subscriptions: []})
    mixpanel.track('Logout')
    mixpanel.reset()
    history.push('/')
  }

  const login = async (user: LoginParams) => {
    const postBody = {user: {email: user.email, password: user.password}}

    const response = await axios.post('/login', postBody)
    setAuthData(response.data)
    mixpanel.track('Login')
    history.push('/')
  }

  const signup = async (user: SignupParams) => {
    const postBody = {
      user: {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        password: user.password,
        invite_token: user.inviteToken
      }
    }

    // try {
      const response = await axios.post('/users', postBody)
      mixpanel.alias(response.data.user.id)
      setAuthData(response.data)
      mixpanel.track('Signup')
      history.push('/')
    // } catch(error) {
      // throw error
    // }
  }

  if(isCheckingAuth) { return null }

  return (
    <AuthContext.Provider value={{ fetchLoginStatus, currentUser: authData.user, subscriptions: authData.subscriptions, setSubscriptions, setCurrentUser, logout, login, signup}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth: () => AuthContextInterface = () => useContext(AuthContext)