
import React, { useState } from 'react'
import { Switch } from '@headlessui/react'

import { useAuth } from '../../Shared/AuthContext'
import { useMixPanel } from 'react-mixpanel-provider-component'
import axios from 'axios'
import SuccessNotification from '../Shared/SuccessNotification'

interface AvatarFile {
  name: string
  type: string
  url: string
  file?: File
}
function classNames(...classes: Array<any>) {
  return classes.filter(Boolean).join(' ')
}

export default function Profile() {
  const {currentUser, setCurrentUser} = useAuth()
  const { mixpanel } = useMixPanel()
  const [saveSuccess, setSaveSuccess] = useState(false)
  
  const [firstName, setFirstName] = useState(currentUser?.first_name)
  const [lastName, setLastName] = useState(currentUser?.last_name)
  const [bio, setBio] = useState(currentUser?.bio || '')
  const [avatar, setAvatar] = useState<AvatarFile>({name: '', type: '', url: currentUser?.avatar!})

  const [emailNotifications, setEmailNotifications] = useState(currentUser?.email_notifications || false)
  const [dailyDigest] = useState(false)

  const updateUser = async (e: any) => {
    e.preventDefault();
    if(!currentUser) { return }

    let formData = new FormData();
    if(avatar && avatar.file) {
      formData.append('user[avatar]', avatar.file)
    }

    if(firstName) { formData.append('user[first_name]', firstName) }
    if(lastName) { formData.append('user[last_name]', lastName) }
    if(bio) { formData.append('user[bio]', bio) }
    formData.append('user[email_notifications]', JSON.stringify(emailNotifications))
    
    const response = await axios.put(`/users/${currentUser.id}`, formData)
    setCurrentUser(response.data.user)
    setAvatar(response.data.user.avatar)
    setSaveSuccess(true)
    mixpanel.track('Profile - Update')
  }

  const selectFileHandler = (e:any) => {
    const avatarFile = e.target.files[0];

    setAvatar({
      name: avatarFile.name,
      type: avatarFile.type,
      url: URL.createObjectURL(avatarFile),
      file: avatarFile
    })
  }

  const avatarImageUrl = avatar && avatar.url ? avatar.url : currentUser?.avatar
    
  return (
    <>
    <SuccessNotification show={saveSuccess} setShow={setSaveSuccess} title="Successfully saved!" subtitle="Your profile has been updated." />
      <form className="divide-y divide-gray-200 lg:col-span-9" onSubmit={updateUser}>
        <div className="py-6 px-4 sm:p-6 lg:pb-8">
          <div>
            <h2 className="text-lg leading-6 font-medium text-gray-900">Profile</h2>
            <p className="mt-1 text-sm text-gray-500">
              This information will be displayed publicly so be careful what you share.
            </p>
          </div>

          <div className="mt-6 flex flex-col lg:flex-row">
            <div className="flex-grow space-y-6">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                    First name
                  </label>
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-12 sm:col-span-6">
                  <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                    Last name
                  </label>
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                  Bio
                </label>
                <div className="mt-1">
                  <textarea
                    id="about"
                    name="about"
                    rows={3}
                    value={bio}
                    onChange={e => setBio(e.target.value)}
                    className="shadow-sm focus:ring-sky-500 focus:border-sky-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  Brief description for your profile. URLs are hyperlinked.
                </p>
              </div>
            </div>

            <div className="mt-6 flex-grow lg:mt-0 lg:ml-6 lg:flex-grow-0 lg:flex-shrink-0">
              <p className="text-sm font-medium text-gray-700" aria-hidden="true">
                Photo
              </p>
              <div className="mt-1 lg:hidden">
                <div className="flex items-center">
                  <div
                    className="flex-shrink-0 inline-block rounded-full overflow-hidden h-12 w-12"
                    aria-hidden="true"
                  >
                    <img className="rounded-full h-full w-full" src={avatarImageUrl} alt="" />
                  </div>
                  <div className="ml-5 rounded-md shadow-sm">
                    <div className="group relative border border-gray-300 rounded-md py-2 px-3 flex items-center justify-center hover:bg-gray-50 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-sky-500">
                      <label
                        htmlFor="mobile-user-photo"
                        className="relative text-sm leading-4 font-medium text-gray-700 pointer-events-none"
                      >
                        <span>Change</span>
                        <span className="sr-only"> user photo</span>
                      </label>
                      <input
                        id="mobile-user-photo"
                        name="user-photo"
                        type="file"
                        onChange={selectFileHandler}
                        className="absolute w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="hidden relative rounded-full overflow-hidden lg:block">
                <img className="relative rounded-full w-40 h-40" src={avatarImageUrl} alt="" />
                <label
                  htmlFor="desktop-user-photo"
                  className="absolute inset-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center text-sm font-medium text-white opacity-0 hover:opacity-100 focus-within:opacity-100"
                >
                  <span>Change</span>
                  <span className="sr-only"> user photo</span>
                  <input
                    type="file"
                    id="desktop-user-photo"
                    name="user-photo"
                    onChange={selectFileHandler}
                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-6 divide-y divide-gray-200">
          <div className="px-4 sm:px-6">
          <div>
            <h2 className="text-lg leading-6 font-medium text-gray-900">Notifications</h2>
            <p className="mt-1 text-sm text-gray-500">
              Control how you want to be notified
            </p>
          </div>
          <ul className="mt-2 divide-y divide-gray-200">
            <Switch.Group as="li" className="py-4 flex items-center justify-between">
              <div className="flex flex-col">
                <Switch.Label as="p" className="text-sm font-medium text-gray-900" passive>
                  All Activity
                </Switch.Label>
                <Switch.Description className="text-sm text-gray-500">
                  Email notifications for all activity.
                </Switch.Description>
              </div>
              <Switch
                checked={emailNotifications}
                onChange={setEmailNotifications}
                className={classNames(
                  emailNotifications ? 'bg-teal-500' : 'bg-gray-200',
                  'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    emailNotifications ? 'translate-x-5' : 'translate-x-0',
                    'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </Switch.Group>
            <Switch.Group as="li" className="py-4 flex items-center justify-between">
              <div className="flex flex-col">
                <Switch.Label as="p" className="text-sm font-medium text-gray-900" passive>
                  Daily Digest
                </Switch.Label>
                <Switch.Description className="text-sm text-gray-500">
                  Coming Soon: Email a daily digest of all recent activity.
                </Switch.Description>
              </div>
              <Switch
                checked={false}
                onChange={() => {}}
                className={classNames(
                  dailyDigest ? 'bg-teal-500' : 'bg-gray-200',
                  'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    dailyDigest ? 'translate-x-5' : 'translate-x-0',
                    'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </Switch.Group>
          </ul>
        </div>
        <div className="mt-4 py-4 px-4 flex justify-end sm:px-6">
          <button
            type="button"
            className="bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-5 bg-sky-700 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
          >
            Save
          </button>
        </div>
      </div>
      </form>
    </>
  )
}
