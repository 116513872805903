import { RoleAttachment, RoleAttachmentLink } from '../../types';

interface CallToActionTileProps {
  link: RoleAttachmentLink
  updateAttachment: (attachment: RoleAttachment) => void
}

export default function CallToActionTileEditor(props: CallToActionTileProps) {
  return (
    <div className="">
      <div className="p-4 space-y-6">
        <div>
          <label htmlFor="primary-text" className="block text-sm font-medium text-gray-700">
            Primary text
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="primary-text"
              id="primary-text"
              value={props.link.payload.intro_text}
              onChange={(e) => { props.updateAttachment({...props.link, payload: {...props.link.payload, intro_text: e.target.value}})} }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>

        <div>
          <label htmlFor="supporting-text" className="block text-sm font-medium text-gray-700">
            Supporting text
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="supporting-text"
              id="supporting-text"
              value={props.link.payload.followup_text}
              onChange={(e) => { props.updateAttachment({...props.link, payload: {...props.link.payload, followup_text: e.target.value}})} }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>

        <div>
          <label htmlFor="url" className="block text-sm font-medium text-gray-700">
            Link
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="url"
              id="url"
              autoComplete="url"
              value={props.link.payload.url}
              onChange={(e) => { props.updateAttachment({...props.link, payload: {...props.link.payload, url: e.target.value}})} }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>

        <div>
          <label htmlFor="button-text" className="block text-sm font-medium text-gray-700">
            Button text
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="button-text"
              id="button-text"
              value={props.link.payload.button_text}
              onChange={(e) => { props.updateAttachment({...props.link, payload: {...props.link.payload, button_text: e.target.value}})} }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>

      </div>
    </div>
  )
}