import React, { useState } from "react";
import { RoleAttachmentFormElement, RoleAttachmentFormElementGreenhouseField } from "../../../types";
import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import { LightningBoltIcon, CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { ControlledTextArea } from "../../../ControlledTextArea";
import { Switch, Combobox } from "@headlessui/react";
import classNames from "classnames";
import { useGreenhouse } from "../../../../Recruiter/Role/GreenhouseContext";
import usePortal from "react-useportal";
import { usePopper } from "react-popper";

interface FormElementGreenhouseFieldEditorProps {
  element: RoleAttachmentFormElementGreenhouseField
  updateElement: (element: RoleAttachmentFormElement) => void
  removeElement: (element: RoleAttachmentFormElement) => void
  index: number
}


export default function FormElementGreenhouseFieldEditor(props: FormElementGreenhouseFieldEditorProps) {
  const [expanded, setExpanded] = useState(false)

  const [required, setRequired] = useState(false)

  const [query, setQuery] = useState('')
  const [selectedPerson, setSelectedPerson] = useState()

  const {customFields} = useGreenhouse()
  
  const { Portal } = usePortal()
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  let { styles, attributes } = usePopper(referenceElement, popperElement)

  const customFieldsWithoutDrumcircle = customFields.filter((field) => { return field.name !== 'Drumcircle Candidate URL' })
  const filteredFields =
    query === ''
      ? customFieldsWithoutDrumcircle
      : customFieldsWithoutDrumcircle.filter((field) => {
          return field.name.toLowerCase().includes(query.toLowerCase())
        })

  if(!expanded) {
    return (
      <>
        <div className="bg-blue-gray-50 rounded-md border borderblue-gray-200 px-2 py-2 border-b border-gray-200">
          <div className="-ml-1 -mt-1 flex justify-between items-center flex-wrap sm:flex-nowrap">
            <div className="ml-1 mt-1">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <LightningBoltIcon className="h-5 w-5 text-blue-gray-500" aria-hidden="true" />
                </div>
                <div className="ml-2">
                  <h3 className="text-xs font-medium text-blue-gray-700 line-clamp-2 mr-2">{props.element.payload.label || 'Greenhouse Field'}</h3>
                </div>
              </div>
            </div>
            <div className="ml-1 mt-1 flex-shrink-0 flex">
              <button
                type="button"
                onClick={() => {setExpanded(!expanded)}}
                className="relative inline-flex items-center px-2 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <PencilAltIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
              </button>
              <button
                type="button"
                onClick={() => { props.removeElement(props.element) }}
                className="ml-2 relative inline-flex items-center px-2 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <TrashIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="bg-blue-gray-50 rounded-md border border-blue-gray-200 px-2 py-2 border-b border-gray-200">
        <div className="-ml-1 -mt-1 flex justify-between items-center flex-wrap sm:flex-nowrap">
          <div className="ml-1 mt-1">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <LightningBoltIcon className="h-5 w-5 text-blue-gray-500" aria-hidden="true" />
              </div>
              <div className="ml-2">
                <h3 className="text-xs font-medium text-blue-gray-700 line-clamp-2 mr-2">{props.element.payload.label || 'Greenhouse Field'}</h3>
              </div>
            </div>
          </div>
          <div className="ml-1 mt-1 flex-shrink-0 flex">
            <button
              type="button"
              onClick={() => {setExpanded(!expanded)}}
              className="relative inline-flex items-center px-2 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <PencilAltIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
            </button>
            <button
              type="button"
              onClick={() => { props.removeElement(props.element) }}
              className="ml-2 relative inline-flex items-center px-2 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <TrashIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
            </button>
          </div>
        </div>

        <div className="mt-2">
          <div className="p-2 space-y-6">
          <div>
              <Combobox as="div" value={props.element.payload.greenhouse_field} onChange={(ghField) => {
                props.updateElement({...props.element, payload: {...props.element.payload, greenhouse_field: ghField}})
              }}>
                {/* <Float portal placement="bottom-end" offset={4}> */}
                <Combobox.Label className="block text-sm font-medium text-gray-700">Greenhouse field</Combobox.Label>
                <div className="relative mt-1" ref={setReferenceElement}>
                  <Combobox.Input
                    className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                    onChange={(event: any) => setQuery(event.target.value)}
                    displayValue={(person: any) => person?.name}
                  />
                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </Combobox.Button>

                  {filteredFields.length > 0 && (
                    <Portal>
                    <Combobox.Options ref={setPopperElement} style={{ ...styles.popper, width: referenceElement?.scrollWidth }} {...attributes.popper} className=" z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {filteredFields.map((person) => (
                        <Combobox.Option
                        key={person.id}
                        value={person}
                        className={({ active }) =>
                          classNames(
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                            active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                          )
                        }
                      >
                        {({ active, selected }) => (
                          <>
                            <div className="flex">
                              <span className={classNames('truncate', selected && 'font-semibold')}>{person.name}</span>
                              <span
                                className={classNames(
                                  'ml-2 truncate text-gray-500',
                                  active ? 'text-indigo-200' : 'text-gray-500'
                                )}
                              >
                                {person.field_type}
                              </span>
                            </div>
        
                            {selected && (
                              <span
                                className={classNames(
                                  'absolute inset-y-0 right-0 flex items-center pr-4',
                                  active ? 'text-white' : 'text-indigo-600'
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            )}
                          </>
                        )}
                      </Combobox.Option>
                      ))}
                    </Combobox.Options>
                    </Portal>
                  )}
                </div>
                {/* </Float> */}
              </Combobox>
            </div>


            <div>
              <label htmlFor="question" className="block text-sm font-medium text-gray-700">
                Question
              </label>
              <div className="mt-1">
                <ControlledTextArea
                  type="text"
                  name="question"
                  id="question"
                  value={props.element.payload.label}
                  onChange={(e) => { props.updateElement({...props.element, payload: {...props.element.payload, label: e.target.value}})} }
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  minRows={2}
                />
              </div>
            </div>

            <div>
              <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                Description (optional)
              </label>
              <div className="mt-1">
                <ControlledTextArea
                  type="text"
                  name="description"
                  id="description"
                  value={props.element.payload.description }
                  onChange={(e) => { props.updateElement({...props.element, payload: {...props.element.payload, description: e.target.value}})} }
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  minRows={2}
                />
              </div>
            </div>
            
            <div>
              <Switch.Group as="div" className="flex items-center justify-between">
                <span className="flex-grow flex flex-col">
                  <Switch.Label as="span" className="text-sm font-medium text-gray-700" passive>
                    Required
                  </Switch.Label>
                </span>
                <Switch
                  checked={required}
                  onChange={setRequired}
                  className={classNames(
                    required ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      required ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>

            


          </div>
        </div>        

      </div>
    </>
  )
}