import { useEffect, useRef } from 'react'
import useWindowFocus from './useWindowFocus';

function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback)
  const windowFocused = useWindowFocus();

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (delay === null) { return }
    if (!windowFocused) { return }

    const id = setInterval(() => savedCallback.current(), delay)
    return () => clearInterval(id)
  }, [delay, windowFocused])
}

export default useInterval
