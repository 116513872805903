import React, { Fragment, useState, useEffect } from 'react'
import { QuestionMarkCircleIcon, PlusIcon } from '@heroicons/react/solid'
import { useMixPanel } from 'react-mixpanel-provider-component'
import axios from 'axios'
import WideSlideover from '../Shared/WideSlideover';

interface ZapierSidebarProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const slideoverTitle = 'Zapier Integration'
const slideoverDescription = 'Connect Drumcircle with your applications and automate your custom workflows.'

export default function ZapierSidebar(props: ZapierSidebarProps) {
  const [zapApiKey, setZapApiKey] = useState(null);
  const [loadingZapApiKey, setLoadingZapApiKey] = useState(true);
  const [confirmDeleteApiKey, setConfirmDeleteApiKey] = useState(false)
  const [confirmRegenerateApiKey, setConfirmRegenerateApiKey] = useState(false)
  
  const fetchZapApiKey = async () => {
    const response = await axios.get('/zapier')
    setZapApiKey(response.data.zap_api_key)
    setLoadingZapApiKey(false)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchZapApiKey() }, []);
  useEffect(() => { if(confirmRegenerateApiKey) { setConfirmDeleteApiKey(false)} }, [confirmRegenerateApiKey])
  useEffect(() => { if(confirmDeleteApiKey) { setConfirmRegenerateApiKey(false)} }, [confirmDeleteApiKey])

  if(loadingZapApiKey) {
    return (<WideSlideover title={slideoverTitle} description={slideoverDescription} open={props.open} setOpen={props.setOpen}><></></WideSlideover>)
  }

  if(!loadingZapApiKey && !zapApiKey) {
    return (<WideSlideover title={slideoverTitle} description={slideoverDescription} open={props.open} setOpen={props.setOpen}><CreateApiKey setZapApiKey={setZapApiKey}/></WideSlideover>)
  }

  return (
    <WideSlideover title={slideoverTitle} description={slideoverDescription} open={props.open} setOpen={props.setOpen}>
      <>
        <div className="space-y-6 pt-6 pb-5">
          <ApiKeyField zapApiKey={zapApiKey || ''} />
          <div className="mt-5 flex-shrink-0 px-4 py-4 flex justify-end">                     
            <button
              type="button"
              onClick={() => { setConfirmRegenerateApiKey(!confirmRegenerateApiKey) }}
              className="inline-flex items-center mr-4 px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
            >
              Regenerate API Key
            </button>
            <button
              type="button"
              onClick={() => { setConfirmDeleteApiKey(!confirmDeleteApiKey) }}
              className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
            >
              Delete API Key
            </button>
          </div>
          <DeleteApiKey open={confirmDeleteApiKey} setOpen={setConfirmDeleteApiKey} setZapApiKey={setZapApiKey}/>
          <RegenerateApiKey open={confirmRegenerateApiKey} setOpen={setConfirmRegenerateApiKey} setZapApiKey={setZapApiKey} />
        </div>
        <LearnMore/>
      </>    
    </WideSlideover>
  )
}

interface CreateApiKeyProps {
  setZapApiKey: React.Dispatch<React.SetStateAction<null>>
}
const CreateApiKey = (props: CreateApiKeyProps) => {
  const [submitting, setSubmitting] = useState(false);
  const {mixpanel} = useMixPanel()

  const createApiKey = async () => {
    setSubmitting(true)
    const response = await axios.post('/zapier')
    props.setZapApiKey(response.data.zap_api_key)
    setSubmitting(false)
    mixpanel.track('Zapier - Create API Key')
  }

  return (
    <>
      <div className="text-center">
        <div className="my-6">
          <button
            type="button"
            onClick={createApiKey}
            disabled={submitting}
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Create API Key
          </button>
        </div>
      </div>
      <LearnMore/>
    </>
  )
}

interface ConfirmActionProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setZapApiKey: React.Dispatch<React.SetStateAction<null>>
}

const RegenerateApiKey = (props: ConfirmActionProps) => {
  const [submitting, setSubmitting] = useState(false);

  const {mixpanel} = useMixPanel()

  const regenerateApiKey = async () => {
    setSubmitting(true)
    const response = await axios.post('/zapier')
    props.setZapApiKey(response.data.zap_api_key)
    setSubmitting(false)
    mixpanel.track('Zapier - Regenerate API Key')
    props.setOpen(false) 
  }

  if(!props.open) { return null }

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Are you sure?</h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>Regenerating this API key will break any existing Zaps that use this key.</p>
        </div>
        <div className="mt-5">
          <button
            type="button"
            disabled={submitting}
            onClick={regenerateApiKey}
            className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
          >
            Regenerate API Key
          </button>
          <button
            type="button"
            disabled={submitting}
            onClick={() => {props.setOpen(false)} }
            className="inline-flex items-center ml-4 px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}


const DeleteApiKey = (props: ConfirmActionProps) => {
  const [submitting, setSubmitting] = useState(false);
  const {mixpanel} = useMixPanel()

  const deleteApiKey = async () => {
    setSubmitting(true)
    await axios.delete('/zapier')
    props.setZapApiKey(null)
    setSubmitting(false)
    mixpanel.track('Zapier - Delete API Key')
    props.setOpen(false)
  }

  if(!props.open) { return null }

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Are you sure?</h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>Deleting this API key will break any existing Zaps that use this key.</p>
        </div>
        <div className="mt-5">
          <button
            type="button"
            disabled={submitting}
            onClick={deleteApiKey}
            className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
          >
            Delete API Key
          </button>
          <button
            type="button"
            disabled={submitting}
            onClick={() => {props.setOpen(false)} }
            className="inline-flex items-center ml-4 px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}

const ApiKeyField = (props: {zapApiKey: string}) => {
  return (
    <div>
    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
      API Key
    </label>
    <div className="mt-1 flex rounded-md shadow-sm">
      <div className="relative flex items-stretch flex-grow focus-within:z-10">
        <input
          type="text"
          name="email"
          id="email"
          readOnly
          value={props.zapApiKey}
          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
        />
      </div>
      <button
        type="button"
        onClick={() => { navigator.clipboard.writeText(props.zapApiKey) }}
        className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
      >
        <span>Copy</span>
      </button>
    </div>
  </div>
  )
}

const LearnMore = () => {
  return (
    <div className="pt-4 pb-6">
      <div className="mt-4 flex text-sm">
        <a href="https://help.drumcircle.com/page/zapier-integration" className="group inline-flex items-center text-gray-500 hover:text-gray-900">
          <QuestionMarkCircleIcon
            className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
          <span className="ml-2">Learn more about our Zapier Integration</span>
        </a>
      </div>
    </div>
  )
}

