/* eslint-disable react-hooks/exhaustive-deps */
import { AssigneeOptions2, RoleAttachment, RoleAttachmentUserProfile, User } from "../../../Shared/types"
import { Fragment, useEffect, useState } from "react";
import { Md5 } from 'ts-md5/dist/md5';
import axios from "axios";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { useAuth } from "../../../Shared/AuthContext";
import { ControlledTextArea } from "../../../Shared/ControlledTextArea";
import { ControlledInput } from "../../../Shared/ControlledInput";

function classNames(...classes: Array<any>) {
  return classes.filter(Boolean).join(' ')
}

interface UserProfileAttachmentProps {
  userProfile: RoleAttachmentUserProfile,
  updateAttachment: (attachment: RoleAttachment) => void
}

export default function UserProfileTileEditor(props: UserProfileAttachmentProps) {
  const [userProfile, setUserProfile] = useState(props.userProfile)
  const [members, setMembers] = useState(Array<User>())


  const { currentUser } = useAuth()
  const [assigneeOptions, setAssigneeOptions] = useState(Array<AssigneeOptions2>())

  const [selected, setSelected] = useState(null as AssigneeOptions2 | null)

  useEffect(() => {
    setUserProfile(props.userProfile)
  }, [props.userProfile])

  useEffect(() => {
    if(!selected) { return }

    if(selected.key === 'other') {
      props.updateAttachment({...userProfile, payload: {
        name: userProfile.payload.name,
        email: userProfile.payload.email,
        bio: userProfile.payload.bio,
        avatar: userProfile.payload.avatar
      }})
    } else {
      props.updateAttachment({...userProfile, payload: {
        name: selected.text,
        email: selected.email,
        bio: selected.bio,
        avatar: selected.image.src
      }})
    }
  }, [selected])



  const previewGravatar = (userProfile: RoleAttachmentUserProfile) => {
    const emailHash = Md5.hashStr(userProfile.payload.email.toLowerCase())

    props.updateAttachment({...userProfile, payload: {...userProfile.payload, avatar: `https://www.gravatar.com/avatar/${emailHash}`}})
  }

  const fetchMembers = async () => {
    const response = await axios.get('/organization/members')
    setMembers(response.data.members)
  }
  useEffect(() => { fetchMembers() }, [])

  useEffect(() => {
    const matchingAssignee = assigneeOptions.find((assignee) => assignee.email === userProfile.payload.email )
    const otherOption = assigneeOptions[assigneeOptions.length - 1]
    const defaultOption = assigneeOptions[0]

    if(matchingAssignee) {
      setSelected(matchingAssignee)
    } else if(userProfile.payload.email !== '' ) {
      setSelected(otherOption)
    } else {
      setSelected(defaultOption)
    }
  }, [assigneeOptions])

  useEffect(() => {
    let newAssigneeOptions = Array<AssigneeOptions2>()

    if(currentUser) {
      newAssigneeOptions.push({
        key: currentUser.id,
        text: `${currentUser.first_name} ${currentUser.last_name}`,
        email: currentUser.email,
        bio: currentUser.bio,
        value: currentUser.id,
        image: { avatar: true, src: currentUser.avatar }
      })
    }

    members.forEach((member) => {
      if(member.id === currentUser?.id) { return }

      newAssigneeOptions.push({
        key: member.id,
        text: `${member.first_name} ${member.last_name}`,
        email: member.email,
        bio: member.bio,
        value: member.id,
        image: { avatar: true, src: member.avatar }
      })
    })

    newAssigneeOptions.push({
      key: 'other',
      text: 'Custom',
      value: 'other',
      email: '',
      bio: '',
      image: { avatar: false, src: '' }
    })

    setAssigneeOptions(newAssigneeOptions)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members])

  const MemberSelect = () => {
    if(!selected) { return null }
    
    return (
      <>
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative w-full">
          <Listbox.Button className="relative w-full border border-gray-300 rounded-md shadow-sm overflow-hidden pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            <span className="flex items-center">
              {/* {selected.image.avatar ?
                <img src={selected.image.src} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
              :
                <UserCircleIcon className="flex-shrink-0 h-6 w-6 rounded-full text-orange-50 bg-orange-400 border-2 border-transparent" />
              } */}
              
              <span className="block truncate">{selected.text}</span>
            </span>
            <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
    
            <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {assigneeOptions.map((person) => (
                  <Listbox.Option
                    key={person.key}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-9'
                      )
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          {/* {person.image.avatar ?
                            <img src={person.image.src} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
                          :
                            <UserCircleIcon className="flex-shrink-0 h-6 w-6 rounded-full text-orange-50 bg-orange-400 border-2 border-transparent" />
                          } */}
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                            {person.text}
                          </span>
                        </div>
    
                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </>
    )
  }


  return (
    <>
      <div className="p-4 space-y-6">
        <div className="sm:col-span-6">
          <div className="mt-1">
            <MemberSelect />
          </div>
        </div>
      
        {selected && selected.key === 'other' &&
          <div className="space-y-6">
            <div className="sm:col-span-6">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <div className="mt-1">
                <ControlledInput
                  type="text"
                  name="name"
                  id="name"
                  value={userProfile.payload.name}
                  onChange={(e) => { props.updateAttachment({...userProfile, payload: {...userProfile.payload, name: e.target.value}})} }
                  autoComplete="given-name"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1">
                <ControlledInput
                  type="text"
                  name="email"
                  id="email"
                  value={userProfile.payload.email}
                  onChange={(e) => { props.updateAttachment({...userProfile, payload: {...userProfile.payload, email: e.target.value}})} }
                  onBlur={() => { previewGravatar(userProfile) }}
                  autoComplete="email"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="bio" className="block text-sm font-medium text-gray-700">
                Bio
              </label>
              <div className="mt-1">
                <ControlledTextArea
                  name="bio"
                  id="bio"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  value={userProfile.payload.bio}
                  onChange={(e) => { props.updateAttachment({...userProfile, payload: {...userProfile.payload, bio: e.target.value}})} }
                  minRows={2}
                />
              </div>
            </div>

          </div>
        }      
      </div>
    </>
  )
}
