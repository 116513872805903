/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import CalendlyWidget from './CalendlyWidget';
import { RoleAttachmentCalendlyLink } from '../../types';

interface CalendlyTileProps {
  calendlyLink: RoleAttachmentCalendlyLink
  index: number
}

export default function CalendlyTile(props: CalendlyTileProps) {

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const parsedQueryString = queryString.parse(location.search)
    const calendlyUrl = parsedQueryString.calendly as string || ''
    if(calendlyUrl === props.calendlyLink.payload.url) {
      // setOpen(true)
    }
  }, [])

  useEffect(() => {
    const handler = (event: MessageEvent) => {
      // if(open) {
        if(event.data && event.data.event && event.data.event === 'calendly.event_scheduled') {
          history.replace({search: ''})
          // setTimeout(() => { setOpen(false) }, 2000)
        // }
      }
    } 

    window.addEventListener("message", handler)
    return () => window.removeEventListener("message", handler)
  }, [])
  
  return (
    <div className="bg-gray-100">
      <main className="md:grid md:grid-cols-2 py-16 md:py-0">
        <div className="mx-auto max-w-7xl w-full text-center md:text-left flex">
          <div className="px-4 sm:px-6 md:px-8 w-full md:mt-36">
            <h1 className="text-3xl tracking-tight font-extrabold text-gray-900">
              <span className="block text-indigo-600 xl:inline">{props.calendlyLink.payload.description}</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              {props.calendlyLink.payload.details}
            </p>
          </div>
        </div>
        <CalendlyWidget calendlyLink={props.calendlyLink.payload.url} index={props.index} />
      </main>
    </div>
  )
}
