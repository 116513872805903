import React, { createContext, useContext, useState } from 'react';


interface Page {
  title: string
  controls?: JSX.Element
}

interface PageContextInterface {
  page: Page
  setPage: (page: Page) => void
}

interface PageProviderProps {
  children: JSX.Element
}

export const PageContext = createContext({} as PageContextInterface);

export const PageProvider = ({ children }: PageProviderProps) => {
  const [page, setPage] = useState({} as Page)

  return (
    <PageContext.Provider value={{ page, setPage }}>
      {children}
    </PageContext.Provider>
  );
}

export const usePage: () => PageContextInterface = () => useContext(PageContext)