import { Listbox, Portal, Transition } from "@headlessui/react"
import classNames from "classnames"
import { useExperience } from "./ExperienceContext"
import React, { Fragment, useState } from "react"
import { usePopper } from "react-popper"

export default function ExperienceStageSelector() {
  const {candidate, stage, setStage, stageAvailable} = useExperience()

  const popperElRef = React.useRef(null);
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  let { styles, attributes } = usePopper(referenceElement, popperElement)

  return (
    <Listbox value={stage.index} onChange={setStage}>
    {({ open }) => (
      <>
        <Listbox.Label className="sr-only">Change published status</Listbox.Label>
          <div className="relative">
        <Listbox.Button ref={setReferenceElement} className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-6 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
            {stage.title}
        </Listbox.Button>
        <Portal>
          <div
            ref={popperElRef}
            style={styles.popper}
            {...attributes.popper}
          >
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => setPopperElement(null)}
            >
              <Listbox.Options static className="mt-2 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
                {candidate.candidate_stage_previews.map((stagePreview, index) => (
                  <Listbox.Option
                    key={stagePreview.title}
                    className={({ active }) =>
                      classNames(
                        stageAvailable(stagePreview.index) ? 'cursor-pointer' : 'cursor-default',
                        active ? 'text-white bg-indigo-500' : 'text-gray-900',
                        'select-none relative p-4 text-sm'
                      )
                    }
                    value={stagePreview.index}
                    disabled={!stageAvailable(stagePreview.index)}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-start items-center">  
                          <span className={classNames(
                            !stageAvailable(stagePreview.index) ? 'border-gray-300' : '',
                            selected && !active ? 'border-gray-600' : '',
                            active ? 'border-white' : '',
                            !active && !selected && stageAvailable(stagePreview.index) ? 'border-gray-500' : '',

                            // (selected && !active) ? 'border-gray-600' : '',
                            // stageAvailable(stagePreview.index) && !active ? 'border-white' : '',
                            // !selected && !active ? 'border-gray-300': '',
                            "flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 rounded-full"
                          )}>
                            <span className={classNames(
                              !stageAvailable(stagePreview.index) ? 'text-gray-500' : '',
                              selected && !active ? 'text-gray-900' : '',
                              active ? 'text-white' : '',
                              )}
                            >{stagePreview.index + 1}</span>
                          </span>
                          <p className={classNames(
                            !stageAvailable(stagePreview.index) ? 'text-gray-500' : '',
                            // stageAvailable(stagePreview.index) && selected ? 'text-white' : 'text-gray-500',
                            selected ? 'font-semibold' : 'font-normal', 'ml-4')}
                          >{stagePreview.title}</p>
                        </div>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Portal>
      </div>
    </>
    )}
  </Listbox>
  )
}