
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';
import { RoleAttachmentLocation } from '../../types';
import React from 'react';
import { LocationMarkerIcon } from '@heroicons/react/outline';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});
L.Marker.prototype.options.icon = DefaultIcon;

interface LocationTileProps {
  location: RoleAttachmentLocation
  index: number
}

export default function LocationTile(props: LocationTileProps) {
  const fullAddress = `${props.location.payload.address1} ${props.location.payload.city}, ${props.location.payload.state} ${props.location.payload.zipcode}`
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(fullAddress)}`

  let line2 = [props.location.payload.city, props.location.payload.state].filter(x => x).join(', ')
  line2 = [line2, props.location.payload.zipcode].filter(x => x).join(' ')

  return (
    <div className="bg-gray-800 overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4 lg:min-h-60">
      <div className="pt-10 pb-12 px-6 sm:px-12 lg:pt-12 lg:pb-6 lg:pr-0">
        <div className="lg:self-center">
          <h2 className="text-3xl font-extrabold text-white sm:text-3xl">
            <span className="block">{props.location.payload.description}</span>
          </h2>
          <a href={googleMapsUrl} target="_blank" rel="noreferrer">
            <p className="mt-4 text-lg leading-6 text-indigo-200">{props.location.payload.address1}</p>
            <p className="mt-0 text-lg leading-6 text-indigo-200">{line2}</p>
          </a>
          <p className="mt-4 text-sm leading-6 text-white">{props.location.payload.notes}</p>
        </div>
      </div>
      <div className="-mt-6 lg:aspect-w-2 lg:aspect-h-1 lg:min-h-56">
        <div className="overflow-hidden transform lg:rounded-tl-md object-cover object-left-top lg:translate-y-20">
          {props.location.payload.latitude && props.location.payload.longitude ?
            <MapContainer style={{ minHeight: '13rem', isolation: 'isolate'}} center={[props.location.payload.latitude!, props.location.payload.longitude!]} zoom={16} scrollWheelZoom={false}>
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={[props.location.payload.latitude!, props.location.payload.longitude!]}>
              </Marker>
            </MapContainer>
            :
            <div className="bg-green-300 flex justify-center items-center" style={{ minHeight: '13rem', isolation: 'isolate'}}>
              <LocationMarkerIcon className="w-20 h-20 text-white" />
            </div>
          }
        </div>
      </div>
    </div>
  )
}