/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { NavLink, useHistory, useParams } from 'react-router-dom';

import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import { v4 as uuidv4 } from 'uuid';

import { BrandingTheme, GreenhouseStage, RoleAttachment, RoleStage } from '../../Shared/types';
import { useMixPanel } from 'react-mixpanel-provider-component';
import { usePage } from '../../Shared/PageContext';
import { DotsVerticalIcon, PlusIcon } from '@heroicons/react/solid';
import { Popover, Transition } from '@headlessui/react';
import SuccessNotification from '../Shared/SuccessNotification';
import TemplateIcon from '@heroicons/react/outline/TemplateIcon';
import RoleStageTiles from './RoleStageTiles';
import RoleStageSidebar from './RoleStageSidebar';
import TileEditor from './TileEditor';
import StageSettings from './StageSettings';
import { GreenhouseProvider } from './GreenhouseContext';

interface Role {
  title: string,
  description: string,
  id: string
  role_stages: Array<RoleStage>
  source?: {
    id: string
    external_id: string
    name: string
    provider: string
  }
  branding: BrandingTheme
  archived_at?: string
  created_at: string
}

interface RoleParams {
  role_id: string
}

function RoleDetails() {
  const history = useHistory()
  const { role_id } = useParams<RoleParams>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openDeleteRole, setOpenDeleteRole] = useState(false)
  const [loadingRole, setLoadingRole] = useState(true);
  const [savingRole, setSavingRole] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false)
  const [confirmArchiveRole, setConfirmArchiveRole] = useState(false)
 
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isEdited, setIsEdited] = useState(false)
  const [role, setRole] = useState({title: '', description: '', id: '', created_at: '', role_stages: Array<RoleStage>(), branding: {background_color: '#D9E3F0'}, attachments: Array<RoleAttachment>()} as Role);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [greenhouseStages, setGreenhouseStages] = useState(Array<GreenhouseStage>())

  const {mixpanel} = useMixPanel()
  const { setPage } = usePage();


  const [activeStage, setActiveStage] = useState(0)
  const [activeAttachment, setActiveAttachment] = useState('')

  useEffect(() => { setActiveAttachment('') }, [activeStage])
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { setPage({title: role.title, controls: renderControls()}) }, [role/*role.title*/, role.archived_at, loadingRole, confirmArchiveRole]);

  const fetchRole = () => {
    axios.get(`/roles/${role_id}`)
      .then(response => {
        setRole(response.data.role)
        setLoadingRole(false)
      })
      .catch(error => console.log(error))
  }
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchRole() }, []);

  useEffect(() => {
    if(loadingRole || savingRole) {return}
    setIsEdited(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role])

  const fetchGreenhouseStages = async () => {
    if(role.source) {
      const response = await axios.get(`/greenhouse/roles/${role.source.external_id}/stages`)
      setGreenhouseStages(response.data.stages)
    }
  }

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    if(result.type === 'stages') {
      const items = Array.from(role.role_stages);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      const indexedStages = items.map((item, index) => { return {...item, index: index} })

      setRole({...role, role_stages: indexedStages})
      setActiveStage(result.destination.index)
    }

    if(result.type === 'attachment') {
      const sourceId = result.source.droppableId.split('.').pop()
      const destinationId = result.destination.droppableId.split('.').pop()

      const roleStages = Array.from(role.role_stages)
      const sourceIndex = roleStages.findIndex(stage => parseInt(stage.id) === parseInt(sourceId))
      const destinationIndex = roleStages.findIndex(stage => parseInt(stage.id) === parseInt(destinationId))

      const [pluckedItem] = roleStages[sourceIndex].attachments.splice(result.source.index, 1)
      roleStages[destinationIndex].attachments.splice(result.destination.index, 0, pluckedItem)

      setRole({...role, role_stages: roleStages})
    }
  }

  const handleSave = (e: any) => {
    setSavingRole(true)
    axios.put(`/roles/${role_id}`, role)
      .then(response => {
        const stages = role.role_stages 
        setRole(response.data.role)
        
        setSavingRole(false)
        setIsEdited(false) // todo, i think this will have problems, but will get redone anyways
        
        stages.map(stage => {
          return stage.files.filter(file => file.file).map((file) => {
            const data = new FormData()
            data.append('file', file.file!)
            return axios.post(`/roles/${role_id}/stage/${stage.index}/files`, data)
              .then(response => { setRole(response.data.role)})
              .catch(error => console.log(error))
          })
        })

        setSaveSuccess(true)
      })
      .catch(error => console.log(error))
  }

  const archiveRole = async () => {
    await axios.delete(`/roles/${role_id}`)
    mixpanel.track('Role - Archive')
    history.push('/')
  }

  const deleteRole = async () => {
    await axios.delete(`/roles/${role_id}`)
    mixpanel.track('Role - Delete')
    history.push('/')
  }

  const addNewStage = () => {
    const newStage: RoleStage = {
      id: '',
      temp_id: uuidv4(),
      title: `Stage ${role.role_stages.length+1}`,
      description: '',
      details: '',
      index: role.role_stages.length,
      role_id: role.id,
      files: [],
      message_title: null,
      message: null,
      action_items: [],
      invites: [],
      attachments: []
    }

    setRole({...role, role_stages: [...role.role_stages, newStage]})
    setActiveStage(role.role_stages.length)
    mixpanel.track('Role - Add Stage')
  }

  const setStageTitle = useCallback((stageId: string, title: string) => {
    setRole(prevRole => { return {...prevRole, role_stages: prevRole.role_stages.map((prevStage) => {
        return prevStage.id !== stageId ? prevStage : {...prevStage, title: title}
      })
    }})
  }, [])

  const setStageAttachments = useCallback((stageId: string, attachments: Array<RoleAttachment>) => {
    setRole(prevRole => { return {...prevRole, role_stages: prevRole.role_stages.map((prevStage) => {
        return prevStage.id !== stageId ? prevStage : {...prevStage, attachments: attachments}
      })
    }})
  }, [])

  const setStage = useCallback((stage: RoleStage) => {
    setRole(prevRole => {
      const temp = prevRole.role_stages.map((prevStage) => {
        return prevStage.index !== stage.index ? prevStage : stage
      })
      return {...prevRole, role_stages: temp}
    })
  }, [])

  const deleteStage = useCallback((stageIndex: number) => {
    setRole(prevRole => {
      const items = Array.from(prevRole.role_stages);
      items.splice(stageIndex, 1)
      const indexedStages = items.map((item, index) => { return {...item, index: index} })
      
      return {...prevRole, role_stages: indexedStages} 
    })
    mixpanel.track('Role - Delete Stage')
  }, [])

  const activateRole = async () => {
    const response = await axios.post(`/roles/${role.id}/activate`)
    setRole(response.data.role)
    mixpanel.track('Role - Activate', {roleId: response.data.role.id})
  }

  const renderArchivedControls = () => {
    return (
      <>
        <span className="block shadow-2xl">
          <button
            type="button"
            onClick={activateRole}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm text-white bg-white bg-indigo-500 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Activate
          </button>
        </span>
        <span className="block shadow-2xl ml-2 mr-6">
          <button
            type="button"
            onClick={deleteRole}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm text-white bg-white bg-indigo-500 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Delete
          </button>
        </span>
      </>
    )
  }

  const renderActiveControls = () => {
    return (
      <>
        <span className="block shadow-2xl ml-0 lg:ml-2 mr-2 md:mr-4 lg:mr-6">
          <button
            type="button"
            onClick={handleSave}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm text-white bg-white bg-indigo-500 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save
          </button>
          
        </span>

        <span className="block shadow-2xl mr-2 md:mr-4 lg:mr-6">
          <NavLink
            exact to={`/role/${role.id}/preview`}
            target='_blank'
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm text-white bg-white bg-indigo-500 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Preview
          </NavLink>
        </span>
        <span className="block shadow-2xl mr-2 md:mr-4 lg:mr-6">
          <button
            type="button"
            onClick={addNewStage}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm text-white bg-white bg-indigo-500 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Add Stage
          </button>
        </span>
        <Popover className="sm:relative">
          {({ open }) => (
            <>
              <Popover.Button>
                <span className="block shadow-2xl">
                  <div className="inline-flex items-center px-2 py-2 border border-transparent rounded-md shadow-sm text-sm text-white bg-white bg-indigo-500 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span className="sr-only">Open options</span>
                    <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </div>
                </span>
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 left-0 sm:left-full sm:transform sm:-translate-x-full mt-3 px-2 w-screen max-w-md sm:px-0">
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="relative bg-white px-5 py-6 sm:p-8">
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Settings</h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Additional management controls.
                        </p>
                      </div>
                      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4">
                        <div className="">
                          <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                            Name
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="username"
                              id="username"
                              autoComplete="username"
                              value={role.title}
                              onChange={(e: any) => {setRole({...role, title: e.target.value}) }}
                              className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            />
                          </div>
                        </div>
                        {/* <GreenhouseRoleBadge role={role} /> */}
                      </div>
                      
                    </div>
                    <div className="p-5 bg-gray-50 sm:p-8">
                      <button onClick={() => { setConfirmArchiveRole(!confirmArchiveRole) }} className="w-full text-left -my-3 py-3 -ml-3 pl-3 flow-root rounded-md hover:bg-gray-100 transition ease-in-out duration-150">
                        <span className="flex items-center">
                          <span className="text-base font-medium text-gray-900">Archive</span>
                          <span className="ml-3 inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium leading-5 bg-red-100 text-red-800">
                            Danger
                          </span>
                        </span>
                        <span className="mt-1 block text-sm text-gray-500">
                          Archive and disable this role.
                        </span>
                      </button>
                    </div>
                    <ConfirmArchiveRole open={confirmArchiveRole} setOpen={setConfirmArchiveRole} archiveRole={archiveRole} />
                    
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </>
    )
  }

  const renderControls = () => {
    if(loadingRole) { return <></>}
    return role.archived_at ? renderArchivedControls() : renderActiveControls()
  }

  if(loadingRole) {
    return null
  }

  if(role.archived_at) {
    // return <ArchivedRole role={role} />
  }

  if(role.role_stages.length > 0 && !role.role_stages[activeStage]) { setActiveStage(role.role_stages.length - 1); return null }
  const stage = role.role_stages[activeStage]

  if(role.role_stages.length === 0 ) {
    return (
      <div className="text-center bg-white w-10/12 md:w-1/2 2xl:w-1/3 mx-auto mt-12 py-8 rounded-lg shadow-lg">
        <TemplateIcon className="mx-auto h-12 w-12 text-gray-400"/>
        <h3 className="mt-4 text-sm font-medium text-gray-900">You haven't created any stages yet</h3>
        <div className="mt-6">
          <button
            type="button"
            onClick={addNewStage}
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            New Stage
          </button>
        </div>
      </div>
    )
  }

  let windowStyle = {}
  if(role.branding.background_image) {
    windowStyle = {backgroundImage: `url(${role.branding.background_image})`}
  } else {
    windowStyle = {backgroundColor: role.branding.background_color}
  }

  return (
    <GreenhouseProvider role={role}>
      <>
        <SuccessNotification show={saveSuccess} setShow={setSaveSuccess} title="Successfully saved!" subtitle="Your updates will take affect for all future candidates." />

        <main className="mx-auto px-2 sm:px-6 lg:px-8 xl:max-w-7xl pb-32">
          <div className="relative min-h-96 grid block sm:grid-cols-3 md:grid-cols-3">
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <div className="flex rounded-lg overflow-hidden shadow block sm:col-span-2 md:col-span-2 bg-fixed bg-cover" style={windowStyle}>
                <RoleStageSidebar stages={role.role_stages} setActiveStage={setActiveStage} currentStageIndex={activeStage}/>
                <RoleStageTiles stageTitle={stage.title} roleTitle={role.title} stageId={stage.id} attachments={stage.attachments} setAttachments={setStageAttachments} activeAttachment={activeAttachment} setActiveAttachment={setActiveAttachment} files={stage.files} branding={role.branding}/>
              </div>

              <div className="block sm:col-span-1 flex sm:ml-4">
                <div className="flex-1 flex flex-col justify-between ">
                  <div className="bg-white shadow rounded-lg overflow-hidden shadow bg-white min-h-96">
                    {activeAttachment ?
                      <TileEditor stageId={stage.id} attachments={stage.attachments} setAttachments={setStageAttachments} activeAttachment={activeAttachment} setActiveAttachment={setActiveAttachment}/>
                    :
                      <StageSettings stage={stage} setStageTitle={setStageTitle} setStage={setStage} deleteStage={deleteStage}/>
                    }
                  </div>
                </div>
              </div>
            </DragDropContext>
          </div>
        </main>
      </>
    </GreenhouseProvider>
  )
}

export default RoleDetails;

interface ConfirmActionProps {
  archiveRole: () => Promise<void>
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ConfirmArchiveRole = (props: ConfirmActionProps) => {
  if(!props.open) { return null }

  return (
      <div className="bg-gray-50 px-7 pb-5">
        <span className="text-base font-medium text-gray-900">Are you sure?</span>
        <div className="mt-5">
          <button
            type="button"
            onClick={props.archiveRole}
            className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
          >
            Archive Role
          </button>
          <button
            type="button"
            onClick={() => {props.setOpen(false)} }
            className="inline-flex items-center ml-4 px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
          >
            Cancel
          </button>
        </div>
      </div>
  )
}