import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import { useMixPanel } from 'react-mixpanel-provider-component'
import Home from './Home/Home'
import SignupInvite from './Auth/SignupInvite'
import { ExperiencePage, ExperienceSamplePage, PreviewRolePage } from './Candidate/Experience'
import Enterprise from './Home/Enterprise'
import Pricing from './Home/Pricing'
import Privacy from './Home/Privacy'
import Security from './Home/Security'
import Terms from './Home/Terms'
import { useAuth } from './Shared/AuthContext'
import Error404 from './Shared/Error404'
import WebhookDetails from './Recruiter/Integrations/WebhookDetails'
import Role from './Recruiter/Role/Role'
import Pretend from './Shared/Pretend'
import HelpScout from './Shared/HelpScout'
import Features from './Home/Features'
import Login from './Auth/Login'
import Signup from './Auth/Signup'
import Layout from './Recruiter/Layout'
import Settings from './Recruiter/Settings/Settings'
import Dashboard from './Recruiter/Dashboard/Dashboard'
import IntegrationsLanding from './Recruiter/Integrations/IntegrationsLanding'
import { PageProvider } from './Shared/PageContext'
import Candidate from './Recruiter/Candidate/Candidate'
import ScrollToTop from './Shared/ScrollToTop'
import Help from './Recruiter/Help/Help'
import ForgotPassword from './Auth/ForgotPassword'
import ResetPassword from './Auth/ResetPassword'
import ConfirmEmail from './Auth/ConfirmEmail'
import axios from 'axios'
import { useErrorHandler } from 'react-error-boundary'
import Greenhouse from './Home/Greenhouse'
import Interviews from './Home/Interviews'

function App() {
  const { mixpanel } = useMixPanel();
  const { currentUser } = useAuth()
  const location = useLocation();

  const handleError = useErrorHandler()
  
  axios.interceptors.response.use(
    response => response,
    error => {
      if(error.response.status >= 500) {
        handleError(error)
        return Promise.reject(error)
      } else {
        throw error
      }
    }
  )
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { mixpanel.track('Page View') }, [location])

  const routes = currentUser ? <RecruiterRoutes/> : <PublicRoutes/>

  return (
    <div className="h-full">
      <Pretend/>
      <HelpScout/>
      <ScrollToTop/>
      {routes}
    </div>
  )
}

export default App


function PublicRoutes() {
  return (
    <Switch>
      <Route path='/' exact component={Home} />
      <Route path='/login' exact component={Login} />
      <Route path='/signup' exact component={Signup} />
      <Route path='/forgot-password' exact component={ForgotPassword} />
      <Route path='/reset-password/:reset_token' exact component={ResetPassword} />
      <Route path='/confirm-email/:email_token' exact component={ConfirmEmail} />
      <Route path='/accept-invite/:invite_token' exact component={SignupInvite} />
      <Route path='/features' exact component={Features} />
      <Route path='/features/greenhouse' exact component={Greenhouse} />
      <Route path='/features/interviews' exact component={Interviews} />
      <Route path='/pricing' exact component={Pricing} />
      <Route path='/privacy' exact component={Privacy} />
      <Route path='/terms' exact component={Terms} />
      <Route path='/security' exact component={Security} />
      <Route path='/enterprise' exact component={Enterprise} />
      <Route path='/app*'><Redirect to="/login" /> </Route>
      <Route path='/sample-experience' component={ExperienceSamplePage} />
      <Route path='/e/:slug' component={ExperiencePage} />
      <Route component={Error404} />
    </Switch>
  )
}

function RecruiterRoutes() {
  return (
    <PageProvider>
        <Switch>
          <Route path='/e/:slug' component={ExperiencePage} />
          <Route path='/sample-experience' component={ExperienceSamplePage} />
          <Route path='/role/:role_id/preview' component={PreviewRolePage} />
          <Route path='/enterprise' exact component={Enterprise} />
          <Route path='/confirm-email/:email_token' exact component={ConfirmEmail} />
          <Layout><><Switch>
            <Route path='/' exact component={Dashboard} />
            <Route path='/integrations' exact component={IntegrationsLanding} />
            <Route path='/help' exact component={Help} />
            <Route path='/webhooks/:webhook_id' exact component={WebhookDetails} />
            <Route path='/settings' component={Settings} />
            <Route path='/role/:role_id' component={Role} />
            <Route path='/candidate/:candidateSlug' component={Candidate} />
            <Route component={Error404} />
          </Switch></></Layout>
        </Switch>
    </PageProvider>
  )
}