import { RoleAttachmentUserProfile } from "../../types";

interface UserProfileTileProps {
  userProfile: RoleAttachmentUserProfile
  index: number
}
export default function UserProfileTile(props: UserProfileTileProps) {
  const {avatar, name, email, bio} = props.userProfile.payload

  return (
    <div className="p-4 flex items-center space-x-4 lg:space-x-6">
      <img className="rounded-lg w-20 h-20 shadow" src={avatar} alt="" />
      <div className="leading-6 space-y-1">
        <h4 className="text-lg font-bold">{name}</h4>
        {bio &&
          <p className="text-gray-500 font-light">{bio}</p>
        }
      </div>
    </div>
  )
}