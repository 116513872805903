import React from "react";
import { RoleAttachmentGreenhouseForm } from '../../types';
import { useExperience } from "../../../Candidate/ExperienceContext";

interface GreenhouseFormTileProps {
  greenhouse_form: RoleAttachmentGreenhouseForm
  index: number
}

function GreenhouseFormTile(props: GreenhouseFormTileProps) {
  const {updateTypeform, isPreview} = useExperience()

  if(props.greenhouse_form.payload.submitted) {
    return null
  }

  if(!props.greenhouse_form.payload.url) {
    return (
      <div className="bg-indigo-50" style={{height: `${props.greenhouse_form.payload.height || 400}px`}}>
      </div>
    )
  }

  return (
    <>
      <iframe title={`greenhouse-form-${props.index}`}src={props.greenhouse_form.payload.url} style={{width: '100%', height: `${props.greenhouse_form.payload.height || 400}px`}} />
    </>
  )
}

export default React.memo(GreenhouseFormTile);