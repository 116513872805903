import { useAuth } from "../../Shared/AuthContext"
import { ExclamationIcon } from '@heroicons/react/solid'

export default function ConfirmEmailAlert() {
  const { currentUser } = useAuth()

  if(!currentUser || currentUser.email_confirmed) { return null }
  
  return (
    <div className="p-6 pb-0">
      <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 rounded-sm">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700">
              Confirm your email address to start creating candidates.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}