import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useExperience } from "../../../Candidate/ExperienceContext";
import { RoleAttachmentForm, RoleAttachmentFormElement, RoleAttachmentFormElementGreenhouseField } from '../../types';
import FormElementLongText from "./Elements/FormElementLongText";
import FormElementShortText from "./Elements/FormElementShortText";
import FormElementStatement from "./Elements/FormElementStatement";
import FormElementWebsite from "./Elements/FormElementWebsite";
import FormElementYesNo from "./Elements/FormElementYesNo";

interface FormTileProps {
  form: RoleAttachmentForm
  index: number
}

function FormTile(props: FormTileProps) {
  const [currentStep, setCurrentStep] = useState(0)
  
  const {setCandidate, isPreview} = useExperience()

  const fetchGreenhouseValues = async () => {}

  //todo && has greenhouse fields
  // const hasGreenhouseFields = props.form.payload.elements
  useEffect(() => { if(!isPreview) { fetchGreenhouseValues() } }, [isPreview])

  const updateForm = (form: RoleAttachmentForm) => {
    setCandidate(prevCandidate => {
      const stages = prevCandidate.candidate_stages.map((stage) => {
        const attachments = stage.attachments.map((attachment) => {
          return attachment.id === form.id ? form : attachment
        })

        return {...stage, attachments: attachments}
      })

      return {...prevCandidate, candidate_stages: stages}
    })
  }

  const updateElement = (element: RoleAttachmentFormElement) => {
    const newForm = {...props.form, payload: {...props.form.payload, elements: props.form.payload.elements.map((prevElement) => {
      return prevElement.id === element.id ? element : prevElement
    } )}}

    updateForm(newForm)
  }
  

  const canGoPrevious = currentStep > 0
  const canGoNext = currentStep < props.form.payload.elements.length - 1

  if(props.form.payload.submitted) {
    return (
      <>
        <div className="bg-gradient-to-br from-rose-500 via-red-500 to-rose-500 relative py-12 flex flex-col justify-center" style={{minHeight: `${props.form.payload.height || 300}px`}}>
          <div className="flex flex-col text-center justify-center h-full mx-20 text-white">
            <h3 className="text-2xl">{props.form.payload.ending?.label || 'Thank you!'}</h3>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="bg-gradient-to-br from-rose-500 via-red-500 to-rose-500 relative py-12 flex flex-col justify-center" style={{minHeight: `${props.form.payload.height || 300}px`}}>
        <CurrentElement currentStep={currentStep} setCurrentStep={setCurrentStep} updateElement={updateElement} form={props.form}/>
        <div className="absolute bottom-3 right-3">
          <span className="relative z-20 inline-flex shadow-sm rounded-md">
            <button
              type="button"
              disabled={!canGoPrevious}
              onClick={() => { setCurrentStep(currentStep - 1) }}
              className={classNames(canGoPrevious ? 'hover:bg-gray-50 text-gray-500' : 'text-gray-300', "relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium focus:z-10 focus:outline-none")}
            >
              <span className="sr-only">Previous</span>
              <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              disabled={!canGoNext}
              onClick={() => { setCurrentStep(currentStep + 1) }}
              className={classNames(canGoNext ? 'hover:bg-gray-50 text-gray-500' : 'text-gray-300', "-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium focus:z-10 focus:outline-none")}
            >
              <span className="sr-only">Next</span>
              <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </span>
        </div>
      </div>
    </>
  )
}

interface CurrentElementProps {
  currentStep: number
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
  updateElement: (element: RoleAttachmentFormElement) => void
  form: RoleAttachmentForm
}

const CurrentElement = (props: CurrentElementProps) => {
  const currentElement = props.form.payload.elements[props.currentStep]

  const {submitForm} = useExperience()

  if(!currentElement) { return null }
  const hasNextStep = props.currentStep < props.form.payload.elements.length - 1

  const nextAction = async () => {
    if(props.currentStep >= props.form.payload.elements.length - 1) {
      submitForm(props.form)
    } else {
      props.setCurrentStep(props.currentStep + 1)
    }
  }

  switch(currentElement.type) {
    case 'statement': return <FormElementStatement element={currentElement} nextAction={nextAction} />
    case 'short_text': return <FormElementShortText element={currentElement} updateElement={props.updateElement} nextAction={nextAction} hasNextStep={hasNextStep}/>
    case 'website': return <FormElementWebsite element={currentElement} updateElement={props.updateElement} nextAction={nextAction} hasNextStep={hasNextStep}/>
    case 'yes_no': return <FormElementYesNo element={currentElement} updateElement={props.updateElement} nextAction={nextAction} hasNextStep={hasNextStep}/>
    case 'long_text': return <FormElementLongText element={currentElement} updateElement={props.updateElement} nextAction={nextAction} hasNextStep={hasNextStep}/>
    case 'greenhouse_field': 
      switch(currentElement.payload.greenhouse_field?.value_type) {
        case 'url': return <FormElementWebsite element={currentElement} updateElement={props.updateElement} nextAction={nextAction} hasNextStep={hasNextStep}/>
        case 'short_text': return <FormElementShortText element={currentElement} updateElement={props.updateElement} nextAction={nextAction} hasNextStep={hasNextStep}/>
        case 'yes_no': return <FormElementYesNo element={currentElement} updateElement={props.updateElement} nextAction={nextAction} hasNextStep={hasNextStep}/>
        case 'long_text': return <FormElementLongText element={currentElement} updateElement={props.updateElement} nextAction={nextAction} hasNextStep={hasNextStep}/>
        // case 'single_select': return 
        // case 'multi_select': return 
        default: return <FormElementShortText element={currentElement} updateElement={props.updateElement} nextAction={nextAction} hasNextStep={hasNextStep}/>
      }
    default: return null
  }
}

export default React.memo(FormTile);