import { NewspaperIcon, ChatIcon, SupportIcon } from "@heroicons/react/outline"
import { useEffect } from "react";
import { usePage } from "../../Shared/PageContext"

declare global {
  interface Window { Beacon: any; }
}

const openChat = () => {
  if(window.Beacon) {
    window.Beacon('open')
  }
}

const supportLinks = [
  {
    name: 'Chat',
    href: '#',
    action: openChat,
    cta: 'Contact us',
    description:
      'Fastest way to get your question answer by reaching out directly to the team.',
    icon: ChatIcon,
  },
  {
    name: 'Knowledge Base',
    href: 'https://help.drumcircle.io',
    cta: 'Learn more',
    description:
      'Dive into our knowledge base to learn more about Drumcircle and find tips to get the most out of your account.',
    icon: SupportIcon,
  },
  {
    name: 'Email',
    href: 'mailto:support@drumcircle.io',
    cta: 'Contact us',
    description:
      'Prefer email?  Contact our team directly at support@drumcircle.io and let us help.',
    icon: NewspaperIcon,
  },
]

export default function Help() {
  const { setPage } = usePage()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { setPage({title: 'How can we help?'}) }, []);

  return (
    <section
      className="py-8 max-w-sm mx-auto relative z-10 px-4 sm:max-w-xl md:max-w-3xl sm:px-6 xl:max-w-7xl xl:px-8"
      aria-labelledby="contact-heading"
    >
      <h2 className="sr-only" id="contact-heading">
        Contact us
      </h2>
      <div className="grid grid-cols-1 gap-y-20 xl:grid-cols-3 xl:gap-y-0 xl:gap-x-8">
        {supportLinks.map((link) => (
          <div key={link.name} className="flex flex-col bg-white rounded-2xl shadow-xl">
            <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
              <div className="absolute top-0 p-5 inline-block bg-blue-gray-700 rounded-xl shadow-lg transform -translate-y-1/2">
                <link.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <h3 className="text-xl font-medium text-blue-gray-900">{link.name}</h3>
              <p className="mt-4 text-base text-blue-gray-500">{link.description}</p>
            </div>
            <div className="p-6 bg-blue-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
              {link.action ?
                <button onClick={link.action} className="text-base font-medium text-blue-700 hover:text-blue-600">
                  {link.cta}<span aria-hidden="true"> &rarr;</span>
                </button>
              :
                <a href={link.href} target='_blank' rel='noreferrer' className="text-base font-medium text-blue-700 hover:text-blue-600">
                  {link.cta}<span aria-hidden="true"> &rarr;</span>
                </a>
              }
              
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}