import { CalendarIcon, ChatAltIcon, ChevronRightIcon, ClipboardCheckIcon, PaperClipIcon } from "@heroicons/react/solid"
import React, { useState } from "react"
import { Candidate, UserNotification } from "../../Shared/types"
import CandidateQuickView from "./CandidateQuickView"

function classNames(...classes: Array<any>) {
  return classes.filter(Boolean).join(' ')
}

interface CandidatesOverviewProps {
  candidates: Array<Candidate>  
  setCandidates: React.Dispatch<React.SetStateAction<Candidate[]>>
  notifications: Array<UserNotification>
  setNotifications: React.Dispatch<React.SetStateAction<UserNotification[]>>
  candidateTab: string
  setCandidateTab: React.Dispatch<React.SetStateAction<string>>
  loadedArchived: boolean
}

export default function CandidatesOverview(props: CandidatesOverviewProps) {
  const tabs = [{name: 'My Candidates', key: 'mine'}, {name: 'All Candidates', key: 'all'}, {name: 'Archived', key: 'archived'}]

  const updateCandidate = (candidate: Candidate) => {
    props.setCandidates(props.candidates.map((c) => (c.slug === candidate.slug ?  candidate : c)))
  }

  const [quickViewCandidateId, setQuickViewCandidateId] = useState(undefined as string | undefined)

  // const candidateNotifications = props.notifications.filter(notification => notification.candidate_id === candidate.id)

  const EmptyState = () => {
    const emptyText = {
      mine: 'You dont have any active candidates yet. Once you have candidates you will be able to manage and interact with them here.',
      all: 'You dont have any active candidates yet. Once you have candidates you will be able to manage and interact with them here.',
      archived: 'You dont have any archived candidates yet. Once you have archived candidates, you will be able to manage them here.'
    }[props.candidateTab]

    return (
      <div className="max-w-lg mx-auto py-8">
      <div>
        <div className="text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
          <h2 className="mt-2 text-lg font-medium text-gray-900">Candidates</h2>
          <p className="mt-1 text-sm text-gray-500">
            {emptyText}
          </p>
        </div>
      </div>
    </div>
    )
  }
  
  return (
    <div className="px-6">
      <div className="pb-5 sm:border-b sm:border-gray-200 sm:pb-0 py-4">
      {/* <h3 className="text-lg leading-6 font-medium text-gray-900">Candidates</h3> */}
      <div className="">
        <div className="sm:hidden">
          <label htmlFor="current-tab" className="sr-only">
            Select a tab
          </label>
          <select
            id="current-tab"
            name="current-tab"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            value={tabs.find((tab) => tab.key === props.candidateTab)?.name}
            onChange={(e: any) => { props.setCandidateTab(e.target.value.toLowerCase()) }}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab, index) => (
              <button
                key={tab.name}
                onClick={() => { props.setCandidateTab(tab.key) }}
                className={classNames(
                  tab.key === props.candidateTab
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
                )}
                aria-current={tab.key === props.candidateTab? 'page' : undefined}
              >
                {tab.name}
                {index === 0 && props.notifications.length > 0 &&
                  <span className="bg-amber-200 text-amber-500 hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium sm:inline-block">
                    {props.notifications.length}
                  </span>
                }
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>             
      {/* Stacked list */}
      <CandidateQuickView notifications={props.notifications} setNotifications={props.setNotifications} candidate={props.candidates.find((c) => c.id === quickViewCandidateId )} updateCandidate={updateCandidate} close={() => { setQuickViewCandidateId(undefined) }} />
      {props.candidates.length === 0 && (props.candidateTab !== 'archived' || props.loadedArchived) &&
        <EmptyState />
      }
      {props.candidateTab === 'archived' && !props.loadedArchived &&
        <div className="max-w-lg mx-auto py-8">
          <svg className="animate-spin h-5 w-5 mx-auto text-indigo-800" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      }
      <ul className="border-t divide-y divide-gray-200 border-gray-200 sm:mt-0 sm:border-t-0">
        {props.candidates.map((candidate) => (
          <div key={candidate.id}>
            <CandidateDetails candidate={candidate} notifications={props.notifications} quickViewCandidate={props.candidates.find((c) => c.id === quickViewCandidateId )} setQuickViewCandidateId={setQuickViewCandidateId} />
          </div>
        ))}
      </ul>
      </div>
  )
}


interface CandidateDetailsProps {
  candidate: Candidate,
  notifications: Array<UserNotification>,
  quickViewCandidate: Candidate | undefined
  setQuickViewCandidateId: React.Dispatch<React.SetStateAction<string | undefined >>
}

function CandidateDetails(props: CandidateDetailsProps) {
  const candidateNotifications = props.notifications.filter(notification => notification.candidate_id === props.candidate.id)

  return (
    <li key={props.candidate.email} className='bg-white overflow-hidden cursor-pointer' onClick={() => { props.quickViewCandidate === props.candidate ? props.setQuickViewCandidateId(undefined) :  props.setQuickViewCandidateId(props.candidate.id) }}>
      <div className="flex items-center py-5 px-0 sm:py-6 sm:px-0">
        <div className="min-w-0 flex-1 flex items-center">
          <div className="flex-shrink-0">
            <img
              className="h-12 w-12 rounded-full group-hover:opacity-75"
              src={props.candidate.avatar}
              alt=""
            />
          </div>
          <div className="min-w-0 flex-1 px-4 grid grid-cols-7 md:grid-cols-12 gap-4">
            <div className='col-span-5'>
              <p className="text-sm font-medium text-purple-600 truncate text-left">{props.candidate.name}</p>
              <p className="hidden sm:block mt-2 flex items-center text-sm text-gray-500">
                <span className="truncate">{props.candidate.email}</span>
              </p>
            </div>
            <div className="hidden md:block md:col-span-5">
              <div>
                <p className="text-sm text-gray-900 text-left">
                {props.candidate.role_title}
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  {/* <CheckCircleIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                    aria-hidden="true"
                  /> */}
                  <span className="inline-flex items-center px-1 py-px mr-2 rounded-md text-xs font-bold bg-gray-200 text-gray-600 border border-gray-300 whitespace-nowrap">
                  {props.candidate.stage + 1}/{props.candidate.candidate_stage_previews.length}
                  </span>
                  {props.candidate.candidate_stages[props.candidate.stage].title}
                </p>
              </div>
            </div>
            <div className='block col-span-2 self-center sm:hidden'>
              <div className="px-0 text-sm text-gray-500 font-medium">
                <div className="flex justify-end items-center space-x-2">
                    {/* <span className="flex-shrink-0 text-xs leading-5 font-medium text-sm text-gray-500 font-medium self-center">
                      {candidateNotifications.length}
                    </span> */}
                    {candidateNotifications.length > 0 &&
                      <span className="bg-amber-200 text-amber-500 ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium inline-block">
                        {candidateNotifications.length}
                      </span>
                    }
                    
                  
                </div>
              </div>
            </div>
            <div className='hidden sm:block sm:col-span-2 self-center'>
              <div className="px-0 text-sm text-gray-500 font-medium">
                <div className="flex justify-end items-center space-x-2">
                  <div className="flex flex-shrink-0 -space-x-3">
                    {!props.candidate.archived_at && candidateNotifications.slice(0,3).map((notification) => (
                      <div className="h-8 w-8 bg-amber-200 text-amber-500 rounded-full ring-2 ring-amber-300 flex items-center justify-center -space-x-4">
                        {
                          {
                            "message": <ChatAltIcon className="h-5 w-5 text-amber-600" aria-hidden="true" />,
                            "file": <PaperClipIcon className="h-5 w-5 text-amber-600" aria-hidden="true" />,
                            "invite": <CalendarIcon className="h-5 w-5 text-amber-600" aria-hidden="true" />,
                            "checklist": <ClipboardCheckIcon className="h-5 w-5 text-amber-600" aria-hidden="true" />
                          }[notification.notification_type]
                        }
                        
                      </div>
                    ))}
                  </div>
                  {candidateNotifications.length > 3 &&
                    <span className="flex-shrink-0 text-xs leading-5 font-medium text-sm text-amber-600 font-medium self-center">
                      +{candidateNotifications.length - 3}
                    </span>
                  }
                  
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <div>
          <ChevronRightIcon
            className="h-5 w-5 text-gray-400 group-hover:text-gray-700"
            aria-hidden="true"
          />
        </div>
      </div>
  </li>
  )
}