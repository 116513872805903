import React from "react"
import { NavLink } from "react-router-dom"

export default function Footer() {
  return (
    <footer className="bg-gray-800" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 pb-20 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="pb-8 xl:grid xl:grid-cols-5 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-4">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">About</h3>
                <ul className="mt-4 space-y-4">
                  <li key='privacy'>
                    <NavLink exact to='/privacy' className="text-base text-gray-300 hover:text-white">Privacy</NavLink>
                  </li>
                  <li key='terms'>
                    <NavLink exact to='/terms' className="text-base text-gray-300 hover:text-white">Terms</NavLink>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Support</h3>
                <ul className="mt-4 space-y-4">
                  <li key='helpcenter'>
                    <a href='https://help.drumcircle.io' target="_blank" rel="noreferrer" className="text-base text-gray-300 hover:text-white">Help Center</a>
                  </li>
                  <li key='security'>
                    <NavLink exact to='/security' className="text-base text-gray-300 hover:text-white">Security</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Drumcircle</h3>
                <ul className="mt-4 space-y-4">
                    <li key='1'>                      
                      <span className="text-base text-gray-300 hover:text-white">
                        made with&nbsp;
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 inline-block" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd" />
                        </svg>
                        &nbsp;in atlanta
                      </span>
                    </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
