import { RoleAttachment, RoleAttachmentFormElement } from '../../Shared/types';
import { v4 as uuidv4 } from 'uuid';
import { useMixPanel } from 'react-mixpanel-provider-component';
import { CalendarIcon, CursorClickIcon, FilmIcon, LocationMarkerIcon, PaperClipIcon, PencilAltIcon, UserCircleIcon, VideoCameraIcon, ClipboardListIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import React from 'react';
import { useAuth } from '../../Shared/AuthContext';
import classNames from 'classnames';
import usePortal from 'react-useportal'
import { ViewGridAddIcon } from '@heroicons/react/solid';

interface AddTileProps {
  stageId: string
  attachments: Array<RoleAttachment>
  setAttachments: (stageId: string,attachments: Array<RoleAttachment>) => void
  setActiveAttachment: React.Dispatch<React.SetStateAction<string>>
}

function AddTile(props: AddTileProps) {
  const {mixpanel} = useMixPanel()
  const { Portal } = usePortal()

  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  let { styles, attributes } = usePopper(referenceElement, popperElement)

  const {currentUser} = useAuth()

  const addAttachment = (type: string, payload: {}) => {
    mixpanel.track(`Role Attachment - Add ${type}`)
    const newId = uuidv4()
    props.setAttachments(props.stageId, [{
      id: newId,
      type: type,
      payload: payload
    }, ...props.attachments])
    props.setActiveAttachment(newId)
  }

  const addSchedulingLink = () => {
    addAttachment('calendly_link', {
      description: 'Schedule Interview',
      details: 'Please select a time for us to connect!',
      url: ''
    })
  }

  const addUserProfile = () => {
    if(currentUser) {
      addAttachment('user_profile', {
        name: `${currentUser.first_name} ${currentUser.last_name}`,
        email: currentUser.email,
        bio: currentUser.bio
      })
    }
  }

  const addQuote = () => {
    if(currentUser) {
      addAttachment('quote', {
        title: '',
        text: '',
        name: `${currentUser.first_name} ${currentUser.last_name}`,
        email: currentUser.email,
        bio: currentUser.bio
      })
    }
  }

  const addLocation = () => {
    addAttachment('location', {
      description: 'Headquarters',
      notes: '',
      address1: '123 Main Street',
      address2: '',
      city: '',
      state: '',
      zipcode: ''
    })
  }

  const addLink = () => {
    addAttachment('link', {
      intro_text: 'Want to learn more?',
      followup_text: 'Check out our page',
      url: 'https://example.com',
      button_text: 'Learn more'
    })
  }

  const addYoutubeLink = () => {
    addAttachment('youtube_link', {
      url: ''
    })
  }

  const addTypeform = () => {
    addAttachment('typeform', {
      form_id: '',
      height: 400
    })
  }

  const addForm = () => {
    addAttachment('form', {
      elements: Array<RoleAttachmentFormElement>(),
      height: 300,
    })
  }

  const addFileRequest = () => {
    addAttachment('file_request', {
      title: 'Share File',
      description: 'Please share the requested file.',
      button_text: 'Upload'
    })
  }

  const addBlurb = () => {
    addAttachment('blurb', {
      title: '',
      text: ''
    })
  }

  let tileOptions = [
    {key: 'blurb', title: 'Blurb', subtitle: 'Short text description', onClick: addQuote, icon: PencilAltIcon, color: 'bg-indigo-500'},
    {key: 'link', title: 'Call to Action', subtitle: 'Drive candidates to a page', onClick: addLink, icon: CursorClickIcon, color: 'bg-blue-500'},
    {key: 'calendly_link', title: 'Calendly Link', subtitle: 'Schedule with Calendly', onClick: addSchedulingLink, icon: CalendarIcon, color: 'bg-cyan-500'},
    {key: 'request_file', title: 'File Request', subtitle: 'Request a file upload', onClick: addFileRequest, icon: PaperClipIcon, color: 'bg-purple-500'},
    {key: 'user_profile', title: 'Profile', subtitle: 'Spotlight a person', onClick: addUserProfile, icon: UserCircleIcon, color: 'bg-amber-500'},
    {key: 'location', title: 'Location', subtitle: 'Address with map', onClick: addLocation, icon: LocationMarkerIcon, color: 'bg-teal-500'},
    {key: 'text', title: 'Text', subtitle: 'Freeform text area', onClick: addBlurb, icon: PencilAltIcon, color: 'bg-sky-500'},
    {key: 'youtube_link', title: 'Youtube Video', subtitle: 'Embed a video', onClick: addYoutubeLink, icon: FilmIcon, color: 'bg-rose-500'},
    {key: 'typeform', title: 'Typeform', subtitle: 'Embed a Typeform', onClick: addTypeform, icon: ClipboardListIcon, color: 'bg-purple-400'},
    // {key: 'form', title: 'Form', subtitle: 'Request information', onClick: addForm, icon: ClipboardListIcon, color: 'bg-purple-400'},
  ]

  if(currentUser && ['atterris@gmail.com', 'andrew@drumcircle.io', 'andrew+veg@drumcircle.io'].includes(currentUser.email)) {
    tileOptions = [
      {key: 'blurb', title: 'Blurb', subtitle: 'Short text description', onClick: addQuote, icon: PencilAltIcon, color: 'bg-indigo-500'},
      {key: 'link', title: 'Call to Action', subtitle: 'Drive candidates to a page', onClick: addLink, icon: CursorClickIcon, color: 'bg-blue-500'},
      {key: 'calendly_link', title: 'Calendly Link', subtitle: 'Schedule with Calendly', onClick: addSchedulingLink, icon: CalendarIcon, color: 'bg-cyan-500'},
      {key: 'request_file', title: 'File Request', subtitle: 'Request a file upload', onClick: addFileRequest, icon: PaperClipIcon, color: 'bg-purple-500'},
      {key: 'user_profile', title: 'Profile', subtitle: 'Spotlight a person', onClick: addUserProfile, icon: UserCircleIcon, color: 'bg-amber-500'},
      {key: 'location', title: 'Location', subtitle: 'Address with map', onClick: addLocation, icon: LocationMarkerIcon, color: 'bg-teal-500'},
      {key: 'text', title: 'Text', subtitle: 'Freeform text area', onClick: addBlurb, icon: PencilAltIcon, color: 'bg-sky-500'},
      {key: 'youtube_link', title: 'Youtube Video', subtitle: 'Embed a video', onClick: addYoutubeLink, icon: FilmIcon, color: 'bg-rose-500'},
      {key: 'typeform', title: 'Typeform', subtitle: 'Embed a Typeform', onClick: addTypeform, icon: ClipboardListIcon, color: 'bg-purple-400'},
      {key: 'form', title: 'Form', subtitle: 'Request information', onClick: addForm, icon: ClipboardListIcon, color: 'bg-purple-400'},
    ]
  }


  return (    
    <div className="py-0 flex justify-between items-center">
      <Popover className="relative w-full">
        {({ open }) => (
          <>
            <Popover.Button
              ref={setReferenceElement}
              className={classNames(
                open ? 'text-gray-300' : 'text-white',
                'relative block w-full px-12 py-4 text-center focus:outline-none bg-gray-50 group hover:bg-gray-100'
              )}
            > 
               <ViewGridAddIcon className="inline-flex w-6 h-6 text-gray-500 group-hover:text-sky-500 mr-4" /><span className=" text-sm text-gray-600 group-hover:text-sky-600">Add new tile</span>
            </Popover.Button>
            <Portal>
            <Popover.Panel ref={setPopperElement} style={{ ...styles.popper, width: referenceElement?.scrollWidth }} {...attributes.popper} className="z-20">
              {({ close }) => (
                <div className="rounded-lg shadow-2xl ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="relative grid gap-6 bg-white p-5 lg:grid-cols-2">
                    {tileOptions.map((item) => (
                      <button
                        key={item.key}
                        onClick={() => { item.onClick(); close()} }
                        className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                      >
                        <div className={classNames("flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-lg text-white", item.color)}>
                          <item.icon className="h-6 w-6" aria-hidden="true" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm text-left font-medium text-gray-700 hover:text-gray-900">{item.title}</p>
                          <p className="mt-1 font-light text-left text-sm text-gray-500 hover:text-gray-700">{item.subtitle}</p>
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </Popover.Panel>
            </Portal>
          </>
        )}
      </Popover>
    </div>  
  )
}

export default React.memo(AddTile);
