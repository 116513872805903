import { RoleAttachment } from '../../Shared/types';
import CallToActionTileEditor from '../../Shared/Tiles/CallToActionTile/CallToActionTileEditor';
import UserProfileTileEditor from '../../Shared/Tiles/UserProfileTile/UserProfileTileEditor';
import CalendlyTileEditor from '../../Shared/Tiles/CalendlyTile/CalendlyTileEditor';
import FileRequestTileEditor from '../../Shared/Tiles/FileRequestTile/FileRequestTileEditor';
import LocationTileEditor from '../../Shared/Tiles/LocationTile/LocationTileEditor';
import YoutubeTileEditor from '../../Shared/Tiles/YoutubeTile/YoutubeTileEditor';
import BlurbTileEditor from '../../Shared/Tiles/BlurbTile/BlurbTileEditor';
import React from 'react';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import QuoteTileEditor from '../../Shared/Tiles/QuoteTile/QuoteTileEditor';
import TypeformTileEditor from '../../Shared/Tiles/TypeformTile/TypeformTileEditor';
import FormTileEditor from '../../Shared/Tiles/FormTile/FormTileEditor';


interface TileEditorProps {
  attachments: Array<RoleAttachment>
  stageId: string
  activeAttachment: string
  setAttachments: (stageId: string, attachments: Array<RoleAttachment>) => void
  setActiveAttachment: React.Dispatch<React.SetStateAction<string>>
}

function TileEditor(props: TileEditorProps) {
  const updateAttachment = (attachment: RoleAttachment) => {
    props.setAttachments(props.stageId, props.attachments.map((a) => {
      return a.id === attachment.id ? attachment : a
    }))
  }

  if(props.activeAttachment === '') { return null }
  const activeAttachment = props.attachments.find(attachment => attachment.id === props.activeAttachment)
  if(!activeAttachment) { return null }

  const renderEditor = () => {
    switch(activeAttachment.type) {
      case 'link': return <CallToActionTileEditor link={activeAttachment} updateAttachment={updateAttachment} />
      case 'user_profile': return <UserProfileTileEditor userProfile={activeAttachment} updateAttachment={updateAttachment} />
      case 'calendly_link': return <CalendlyTileEditor calendlyLink={activeAttachment} updateAttachment={updateAttachment} />
      case 'file_request': return <FileRequestTileEditor fileRequest={activeAttachment} updateAttachment={updateAttachment} />
      case 'location': return <LocationTileEditor location={activeAttachment} updateAttachment={updateAttachment} />
      case 'youtube_link': return <YoutubeTileEditor link={activeAttachment} updateAttachment={updateAttachment} />
      case 'blurb': return <BlurbTileEditor blurb={activeAttachment} updateAttachment={updateAttachment} />
      case 'quote': return <QuoteTileEditor quote={activeAttachment} updateAttachment={updateAttachment} />
      case 'typeform': return <TypeformTileEditor typeform={activeAttachment} updateAttachment={updateAttachment} />
      case 'form': return <FormTileEditor form={activeAttachment} updateAttachment={updateAttachment} />
      default: return null
    }
  }

  const editorTitle = () => {
    switch(activeAttachment.type) {
      case 'link': return 'Call to Action'
      case 'user_profile': return 'Profile'
      case 'calendly_link': return 'Calendly Link'
      case 'file_request': return 'File Request'
      case 'location': return 'Location'
      case 'youtube_link': return 'Youtube Video'
      case 'blurb': return 'Text'
      case 'quote': return 'Blurb'
      case 'typeform': return 'Typeform'
      case 'form': return 'Form'
      default: return ''
    }
  }

  return (
    <div className="">
      <div className="relative bg-gray-50 px-4 py-3 border-b border-gray-200 text-center">
        <button
          type="button"
          className="inline-flex absolute top-3 left-2 items-center border border-transparent text-gray-600"
          onClick={() => { props.setActiveAttachment('') }}
        >
          <ChevronLeftIcon className="h-6 w-6" aria-hidden="true" />
        </button>
        <h3 className="text-sm leading-6 font-medium text-gray-900">{editorTitle()}</h3>
      </div>
      {renderEditor()}
    </div>
  )
  
}
// export default React.memo(Attachments3);
export default TileEditor
