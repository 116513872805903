import { Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import { useExperience } from "../ExperienceContext";

export default function SampleExperienceBanner()  {
  const [show, setShow] = useState(true)
  const {candidate, stage, setStage} = useExperience()

  const showPreviewNextStage = stage.index !== candidate.candidate_stage_previews.length - 1

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="md:fixed md:inset-x-0 md:top-0 md:z-50">
        <div className="relative bg-indigo-600">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="pr-16 sm:text-center sm:px-16">
              <p className="font-medium text-white">
                <span className="md:hidden">Preview experience</span>
                <span className="hidden md:inline">Preview experience</span>
            
                <span className="block sm:ml-2 sm:inline-block">
                  {showPreviewNextStage ?
                    <button
                      type="button"
                      onClick={() => { setStage(stage.index + 1)}}
                      className="text-white font-bold underline"
                    >
                      {' '}See more <span aria-hidden="true">&rarr;</span>
                    </button>
                  :
                    <NavLink
                      exact to='/features'
                      className="text-white font-bold underline"
                    >
                      {' '}Learn more <span aria-hidden="true">&rarr;</span>
                    </NavLink>
                  }
                </span>
              </p>
            </div>
            
            <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
              <button
                type="button"
                onClick={() => { setShow(false) }}
                className="flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none"
              >
                <span className="sr-only">Dismiss</span>
                <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  )
}