import { useEffect } from 'react'
import {
  CogIcon,
  CreditCardIcon,
  PhotographIcon,
  UserCircleIcon,
} from '@heroicons/react/outline'
import Profile from './Profile'
import OrganizationSettings from './Organization'
import Billing from './Billing'
import { usePage } from '../../Shared/PageContext'
import { NavLink, Route, useLocation } from 'react-router-dom'
import Branding from './Branding'



const subNavigation = [
  { name: 'Profile', href: '/settings/profile', icon: UserCircleIcon, current: true },
  { name: 'Branding', href: '/settings/branding', icon: PhotographIcon, current: true },
  // { name: 'Notifications', href: '#', icon: BellIcon, current: false },
  { name: 'Team', href: '/settings/team', icon: CogIcon, current: false },
  { name: 'Billing', href: '/settings/billing', icon: CreditCardIcon, current: false },
  // { name: 'Integrations', href: '#', icon: ViewGridAddIcon, current: false },
]

function classNames(...classes: Array<any>) {
  return classes.filter(Boolean).join(' ')
}

export default function Settings() {
  const { setPage } = usePage()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { setPage({title: 'Settings'}) }, []);

  const location = useLocation()
  

  // const [currentPage, setCurrentPage] = useState('Profile')

  return (
    <div className="px-3">
      <div className="max-w-5xl mx-auto bg-white rounded-lg shadow overflow-hidden">
        <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
          <aside className="py-6 lg:col-span-3">
            <nav className="space-y-1">
              {subNavigation.map((item) => (
                <NavLink
                  key={item.name}
                  exact to={item.href}
                  className={classNames(
                    item.href === location.pathname
                      ? 'bg-indigo-50 border-indigo-500 text-indigo-700 hover:bg-indigo-50 hover:text-indigo-700'
                      : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                    'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  <item.icon
                    className={classNames(
                      item.href === location.pathname
                        ? 'text-indigo-500 group-hover:text-indigo-500'
                        : 'text-gray-400 group-hover:text-gray-500',
                      'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                  <span className="truncate">{item.name}</span>
                </NavLink>
              ))}
            </nav>
          </aside>

          <Route exact path={`/settings/profile`}>
            <Profile />
          </Route>

          <Route exact path={`/settings/branding`}>
            <Branding />
          </Route>

          <Route exact path={`/settings/team`}>
            <OrganizationSettings />
          </Route>

          <Route exact path={`/settings/billing`}>
            <Billing/>
          </Route>                
        </div>
      </div>
    </div>
  )
}
