import React from "react";
import { RoleAttachment, RoleAttachmentFileRequest } from '../../types';
import TextareaAutosize from 'react-textarea-autosize';

interface FileRequestTileEditorProps {
  fileRequest: RoleAttachmentFileRequest
  updateAttachment: (attachment: RoleAttachment) => void
}

export default function FileRequestTileEditor(props: FileRequestTileEditorProps) {
  return (
    <div className="">
      <div className="p-4 space-y-6">

        <div>
          <label htmlFor="title" className="block text-sm font-medium text-gray-700">
            Title
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="title"
              id="title"
              value={props.fileRequest.payload.title}
              onChange={(e) => { props.updateAttachment({...props.fileRequest, payload: {...props.fileRequest.payload, title: e.target.value}})} }
              placeholder='Upload File'
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>

        <div>
          <label htmlFor="details" className="block text-sm font-medium text-gray-700">
            Details
          </label>
          <div className="mt-1">
            <TextareaAutosize
              name="details"
              id="details"
              value={props.fileRequest.payload.description}
              onChange={(e) => { props.updateAttachment({...props.fileRequest, payload: {...props.fileRequest.payload, description: e.target.value}})} }
              minRows={3}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>

        <div>
          <label htmlFor="button-text" className="block text-sm font-medium text-gray-700">
            Button Text
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="button-text"
              id="button-text"
              placeholder="Upload"
              value={props.fileRequest.payload.button_text}
              onChange={(e) => { props.updateAttachment({...props.fileRequest, payload: {...props.fileRequest.payload, button_text: e.target.value}})} }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>

      </div>
    </div>
  )
}