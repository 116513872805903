const sampleExperience = {
  "id": "37",
  "title": "Senior Software Engineer",
  "description": "",
  "background_url": 'https://images.unsplash.com/photo-1454496522488-7a8e488e8606?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3276&q=80',
  "role_stages": [
    {
      "id": "197",
      "role_id": "37",
      "index": 0,
      "title": "Recruiter Interview",
      "details": "",
      "message_title": null,
      "message": undefined,
      "action_items": [],
      "invites": [
        {
          "external_uri": "https://api.calendly.com/scheduled_events/5681455c-c6b0-4400-8645-a34146adaaad/invitees/84069a64-f904-4c00-bb9b-b7e02037947d",
          "event_start": "2022-03-04T10:00:00.000-05:00",
          "event_end": "2022-03-04T10:30:00.000-05:00",
          "summary": "Phone Screen",
          "description": '',
          "location": {"data":{"id":"95943065672","settings":{},"password":"2bVLa9","extra":null},"join_url":"https://zoom.us/j/95943065672?pwd=ck16M3NzVVg2MXhQQVptc0IvTUZpQT09","status":"pushed","type":"zoom"},
          "cancel_url": "https://calendly.com/cancellations/84069a64-f904-4c00-bb9b-b7e02037947d",
          "reschedule_url": "https://calendly.com/reschedulings/84069a64-f904-4c00-bb9b-b7e02037947d",
          "status": "active",
          "timezone": "America/New_York"
        }
      ],
      "attachments": [
        {
          "id": "188966b3-ab34-4cfd-b54b-cc0f189ec123",
          "type": "typeform",
          "payload": {
            "form_id": "M4Kd0gOJ",
            "height": "400",
          }
        },
        {
          "id": "188966b3-ab34-4cfd-b54b-cc0f189ec6df",
          "type": "link",
          "payload": {
            "url": "https://auroraspace.co/careers",
            "intro_text": "Want to learn more?",
            "followup_text": "See what it's like to work here!",
            "button_text": 'Careers page'
          }
        }
      ],
      "source": undefined,
      "files": [
      ]
    },
    {
      "id": "198",
      "role_id": "37",
      "index": 1,
      "title": "Zoom Interview",
      "description": "Meet with our Head of Engineering",
      "details": "",
      "message_title": null,
      "message": undefined,
      "action_items": [],
      "invites": [
        {
          "external_uri": "https://api.calendly.com/scheduled_events/5681455c-c6b0-4400-8645-a34146adaaad/invitees/84069a64-f904-4c00-bb9b-b7e02037947d",
          "event_start": "2022-03-08T09:00:00.000-05:00",
          "event_end": "2022-03-08T09:30:00.000-05:00",
          "summary": "Chat with Scott",
          "description":'',
          "location": {"data":{"id":"95943065672","settings":{},"password":"2bVLa9","extra":null},"join_url":"https://zoom.us/j/95943065672?pwd=ck16M3NzVVg2MXhQQVptc0IvTUZpQT09","status":"pushed","type":"zoom"},
          "cancel_url": "https://calendly.com/cancellations/84069a64-f904-4c00-bb9b-b7e02037947d",
          "reschedule_url": "https://calendly.com/reschedulings/84069a64-f904-4c00-bb9b-b7e02037947d",
          "status": "active",
          "timezone": "America/New_York"
        }
      ],
      "attachments": [
        {
          "id": "43a45622-0f9e-45e1-9115-3a75240b3726",
          "type": "quote",
          "payload": {
            "text": "Please come prepared with specific examples from your previous work. we want to know how you deal with challenging situations, work within a team and go above and beyond to make your team succeed!",
            "title": "How to Prepare",
            "attribution": {
              "avatar": "https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
              "name": "Scott Candler",
              "bio": 'Head of Engineering',
              "email": "scott@drumcircle.io"
            }
          }
        },
        {"id":"c1621565-cc9d-456b-94f9-75e43d59493b","type":"calendly_link","payload":{"url":"https://calendly.com/atterris/zoom-call","description":"Schedule with Calendly", "details": "Choose a day and time that work best for you!"}},
        {"id":"cbb67760-89aa-4ae8-80b7-3991ab4fb221","type":"blurb","payload":{"text":"<h3>Example Questions</h3><ul><li>Walk me through your resume.</li><li>Describe what you do in your current role.</li><li>What is a project that you are most proud of and how did you contribute to that team?</li><li>What programming languages are you familiar with?</li><li>Describe the build and deployment process of your current project.</li><li>How do you use display leadership in your current role?</li><li>What would you have hoped to achieve in your first six months?</li></ul>","title":"About Scott"}}
      ],
      "source": undefined,
      "files": []
    },
    {
      "id": "199",
      "role_id": "37",
      "index": 2,
      "title": "Assignment",
      "description": "Demonstrate your Skills",
      "details": '',
      "message_title": null,
      "message": "<p>I have attached a starter project to use for the assignment.</p><p> If you have any questions or issues, let me know!</p>",
      "action_items": [],
      "invites": [],
      "attachments": [
        {
          "id": "43a45622-0f9e-45e1-9115-3a75240b3726",
          "type": "blurb",
          "payload": {
            "text": '<p>We have designed this assignment to provide an opportunity to demonstrate your work while minimizing the time commitment.</p><p>You can make any change or improvement that you would like, but here are a few ideas to get you started</p><ul><li>Expose the user profile information via the API and consume it in-app.</li><li>Build out a "dev menu" in the app to view a component library.</li></ul>',
            "title": "After your Interview"
          }
        },
        {
          "id": "5c413888-f55d-4abd-84e7-01c501c68b67",
          "type": "file_request",
          "payload": {
            "title": "Take Home Assignment",
            "description": "Please share a zip file with your project and any supporting documentation.",
            "button_text": "Upload"
          }
        }
      ],
      "source": undefined,
      "files": [
        {
          "id": 69,
          "name": "AssignmentProject.zip",
          "type": "application/zip",
          "url": "/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBQUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--d289b0af2226dcea348f2c2a5ae239b3002cf3e3/AssignmentProject.zip?disposition=attachment"
        }
      ]
    },
    {
      "id": "200",
      "role_id": "37",
      "index": 3,
      "title": "Onsite Interview",
      "description": "Meet the team",
      "details": "",
      "message_title": null,
      "message": null,
      "action_items": [],
      "invites": [
        {
          "external_uri": "https://api.calendly.com/scheduled_events/5681455c-c6b0-4400-8645-a34146adaaad/invitees/84069a64-f904-4c00-bb9b-b7e02037947d",
          "event_start": "2022-03-16T08:00:00.000-05:00",
          "event_end": "2022-03-16T09:00:00.000-05:00",
          "summary": "Culture Interview",
          "description":'',
          "location": {"data":{"id":"95943065672","settings":{},"password":"2bVLa9","extra":null},"join_url":"https://zoom.us/j/95943065672?pwd=ck16M3NzVVg2MXhQQVptc0IvTUZpQT09","status":"pushed","type":"zoom"},
          "cancel_url": "https://calendly.com/cancellations/84069a64-f904-4c00-bb9b-b7e02037947d",
          "reschedule_url": "https://calendly.com/reschedulings/84069a64-f904-4c00-bb9b-b7e02037947d",
          "status": "active",
          "timezone": "America/New_York"
        },
        {
          "external_uri": "https://api.calendly.com/scheduled_events/5681455c-c6b0-4400-8645-a34146adaaad/invitees/84069a64-f904-4c00-bb9b-b7e02037947d",
          "event_start": "2022-03-16T09:00:00.000-05:00",
          "event_end": "2022-03-16T10:00:00.000-05:00",
          "summary": "Technical Interview",
          "description":'',
          "location": {"data":{"id":"95943065672","settings":{},"password":"2bVLa9","extra":null},"join_url":"https://zoom.us/j/95943065672?pwd=ck16M3NzVVg2MXhQQVptc0IvTUZpQT09","status":"pushed","type":"zoom"},
          "cancel_url": "https://calendly.com/cancellations/84069a64-f904-4c00-bb9b-b7e02037947d",
          "reschedule_url": "https://calendly.com/reschedulings/84069a64-f904-4c00-bb9b-b7e02037947d",
          "status": "active",
          "timezone": "America/New_York"
        },
        {
          "external_uri": "https://api.calendly.com/scheduled_events/5681455c-c6b0-4400-8645-a34146adaaad/invitees/84069a64-f904-4c00-bb9b-b7e02037947d",
          "event_start": "2022-03-16T10:00:00.000-05:00",
          "event_end": "2022-03-16T11:30:00.000-05:00",
          "summary": "Pairing Interview",
          "description":'',
          "location": {"data":{"id":"95943065672","settings":{},"password":"2bVLa9","extra":null},"join_url":"https://zoom.us/j/95943065672?pwd=ck16M3NzVVg2MXhQQVptc0IvTUZpQT09","status":"pushed","type":"zoom"},
          "cancel_url": "https://calendly.com/cancellations/84069a64-f904-4c00-bb9b-b7e02037947d",
          "reschedule_url": "https://calendly.com/reschedulings/84069a64-f904-4c00-bb9b-b7e02037947d",
          "status": "active",
          "timezone": "America/New_York"
        },
        {
          "external_uri": "https://api.calendly.com/scheduled_events/5681455c-c6b0-4400-8645-a34146adaaad/invitees/84069a64-f904-4c00-bb9b-b7e02037947d",
          "event_start": "2022-03-17T08:30:00.000-05:00",
          "event_end": "2022-03-17T09:00:00.000-05:00",
          "summary": "Hiring Manager Interview",
          "description":'',
          "location": {"data":{"id":"95943065672","settings":{},"password":"2bVLa9","extra":null},"join_url":"https://zoom.us/j/95943065672?pwd=ck16M3NzVVg2MXhQQVptc0IvTUZpQT09","status":"pushed","type":"zoom"},
          "cancel_url": "https://calendly.com/cancellations/84069a64-f904-4c00-bb9b-b7e02037947d",
          "reschedule_url": "https://calendly.com/reschedulings/84069a64-f904-4c00-bb9b-b7e02037947d",
          "status": "active",
          "timezone": "America/New_York"
        }
      ],
      "attachments": [
        {
          "id": "e85fed67-2390-4471-bf24-c8c2d114077d",
          "type": "location",
          "payload": {
            "city": "Brownsville",
            "notes": "Once you arrive, you can park in the main parking lot.",
            "state": "TX",
            "zipcode": "78521",
            "address1": "54298 Boca Chica Blvd",
            "address2": "",
            "latitude": 25.9970307,
            "longitude": -97.15547269534319,
            "description": "Our Headquarters"
          }
        },
        {
          "id": "fba1b5a8-c368-4e9a-ad76-3bf8c1c8b512",
          "type": "blurb",
          "payload": {
            "text": "<h1>Frequently asked questions</h1><p><strong>What should I wear?</strong></p><p>We want you to wear what is most comfortable for you. We have a causal office and your interviewers are likely to be wearing jeans and a t-shirt.</p><p><strong>What do I need to bring?</strong></p><p>If you have a personal laptop you can bring, that can be helpful. However, that is for your comfort, and we will provide everything needed for your interview.</p><p><strong>Additional accommodations?</strong></p><p>Please let me know if you need any accessibility or other type of accommodations. We are happy to work with you to make your interview as easy and approachable as possible!</p>",
            "title": "Frequently Asked Questions"
          }
        },
        // {
        //   "id": "43a45622-0f9e-45e1-9115-3a75240b3726",
        //   "type": "quote",
        //   "payload": {
        //     "text": "After your interview, the team will review. I will reach out once the team makes a decision, usually within 3 days.",
        //     "title": "After your Interview",
        //     "avatar": "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
        //     "name": "Sydney Burton",
        //     "bio": 'Lead Technical Recruiter'
        //   }
        // }
      ],
      "source": undefined,
      "files": []
    },
    {
      "id": "201",
      "role_id": "37",
      "index": 4,
      "title": "Final Decision",
      "description": "",
      "details": "",
      "message_title": null,
      "message": null,
      "action_items": [],
      "invites": [
      ],
      "attachments": [
        {
          "id": "43a45622-0f9e-45e1-9115-3a75240b3726",
          "type": "quote",
          "payload": {
            "text": "I will reach out and let you know as soon as the team has made their final decision, usually within 3 days!",
            
            
            "attribution": {
              "avatar": "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
              "name": "Sydney Burton",
              "bio": 'Lead Technical Recruiter',
              "email": "sdyney@drumcircle.io"
            }
          }
        },
        {
          "id": "188966b3-ab34-4cfd-b54b-cc0f189ec123",
          "type": "typeform",
          "payload": {
            "form_id": "F1Eqw5ga",
            "height": "400",
          }
        }
      ],
      "source": undefined,
      "files": []
    }
  ],
  "source": undefined,
  "archived_at": undefined,
  "created_at": "2022-01-23T10:24:27.413-05:00",
  "updated_at": "2022-01-23T10:24:27.413-05:00"
}

export default sampleExperience

