/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios"
import Pusher from "pusher-js"
import { useEffect, useState } from "react"
import { useMixPanel } from "react-mixpanel-provider-component"
import { BrandingTheme, Candidate, CandidateActivity, CandidateNotification, CandidateStage, CandidateStagePreview, RoleAttachmentChecklist, RoleAttachmentFileRequest, RoleAttachmentForm, RoleAttachmentFormElement, RoleAttachmentTypeform } from "../../Shared/types"
import { ExperienceContext } from "../ExperienceContext"

let pusherKey = '9f0e23841f56932d0171'
if(window.location.host === 'staging.drumcircle.io') { pusherKey = '515eecb89cccf1e45a43' }
if(window.location.host === 'drumcircle.io') { pusherKey = '5d183d20cce979d99046' }

interface ExperienceProviderProps {
  slug: string
  children: JSX.Element
}

export const ExperienceProvider = (props: ExperienceProviderProps) => {
  const { mixpanel } = useMixPanel()
  
  const [activityMenuOpen, setActivityMenuOpen] = useState(false)
  const [activeStage, setActiveStage] = useState(0)
  const [loadingCandidate, setLoadingCandidate] = useState(true)
  
  const [candidate, setCandidate] = useState({
    candidate_stage_previews: Array<CandidateStagePreview>(),
    candidate_stages: Array<CandidateStage>(),
    activity: Array<CandidateActivity>(),
    notifications: Array<CandidateNotification>()
  } as Candidate)

  const [branding, setBranding] = useState({} as BrandingTheme)

  useEffect(() => { setActiveStage(candidate.stage)}, [candidate.stage])
  
  const fetchExperience = async () => {
    const response = await axios.get(`/candidates/${props.slug}`)
    setCandidate(response.data)
    setBranding(response.data.branding)
    setLoadingCandidate(false)
  }
  useEffect(() => { fetchExperience() }, []);

  useEffect(() => {
    if(candidate.slug) {
      const pusher = new Pusher(pusherKey, {cluster: 'us2'})
      const channel = pusher.subscribe(`candidate-${props.slug}`)
      
      channel.bind('notification', addNotification)
      
      return (() => {
        pusher.unbind(`candidate-${props.slug}`)
      })
    }
  }, [candidate.slug])

  const addNotification = (notification: CandidateNotification) => {
    setCandidate(prevCandidate => { return {...prevCandidate, notifications: [...prevCandidate.notifications, notification]} })
    fetchExperience()
  }

  const sendMessage = async (message: string) => {
    if(message === '') { return }
    
    const response = await axios.post(`/candidates/${candidate.slug}/activity`, { message: message });
    setCandidate({ ...candidate, activity: [...candidate.activity, response.data] });
    mixpanel.track('Experience - Send Message')
  }

  const uploadFile = (event: any, fileRequest?: RoleAttachmentFileRequest) => {
    [...event.target.files].map((file: any) => {
      const data = new FormData()
      
      data.append('file', file)
      if(fileRequest) { data.append('file_request_id', fileRequest.id) }

      mixpanel.track('Experience - Add File')
      axios.post(`/candidates/${candidate.slug}/files`, data , {})
        .then(response => {
          setCandidate(response.data)
        })

      return null
    })
  }

  const markNotificationsAsRead = async () => {
    if(candidate.notifications.length === 0) { return }
    const notificationIds = candidate.notifications.map(notification => notification.id)

    const response = await axios.post(`/candidates/${candidate.slug}/notifications/read`, { notifications: {ids: notificationIds} })
    setCandidate({...candidate, notifications: response.data.notifications })
  }

  const markNotificationAsRead = async (notification: CandidateNotification) => {
    const response = await axios.post(`/candidates/${candidate.slug}/notifications/read`, { notifications: {ids: [notification.id]} })
    setCandidate({...candidate, notifications: response.data.notifications })
  }

  const updateTypeform = async (typeform: RoleAttachmentTypeform) => {
    await axios.post(`/candidates/${candidate.slug}/stage/${stage.index}/typeform`, typeform)
  }

  const updateChecklist = async (checklist: RoleAttachmentChecklist, itemIndex: number, checked: boolean) => {
    const response = await axios.post(`/candidates/${candidate.slug}/stage/${stage.index}/checklist`, {
      checklist_id: checklist.id,
      item_index: itemIndex,
      complete: checked
    })

    if(response.data.candidate) {
      setCandidate(response.data.candidate)
    } else {
      checklist.payload.items[itemIndex].complete = checked

      const updatedAttachments = candidate.candidate_stages[stage.index].attachments.map((attachment) => {
        return checklist.id === attachment.id ? checklist : attachment
      })
  
      let newCandidateStages = Array.from(candidate.candidate_stages)
      newCandidateStages[stage.index].attachments = updatedAttachments
  
      setCandidate({...candidate, candidate_stages: newCandidateStages, activity: [...candidate.activity, response.data.activity]})
      mixpanel.track('Experience - Update Checklist')
    }
  }

  const stageAvailable = (index: number) => { return index <= candidate.stage }
  const isPreview = false

  const setStage = (index: number) => {
    if(!stageAvailable(index)) { return }
    setActiveStage(index)
  }

  const stage = candidate.candidate_stages[activeStage] || candidate.candidate_stages[candidate.stage]

  const updateForm = (form: RoleAttachmentForm) => {
    setCandidate(prevCandidate => {
      const stages = prevCandidate.candidate_stages.map((stage) => {
        const attachments = stage.attachments.map((attachment) => {
          return attachment.id === form.id ? form : attachment
        })

        return {...stage, attachments: attachments}
      })

      return {...prevCandidate, candidate_stages: stages}
    })
  }

  // const updateFormElement = (element: RoleAttachmentFormElement, value: any) => {
  //   const items = Array.from(candidate.candidate_stages);

  //   // setCandidate({...candidate, candidate_stages: [...candidate.candidate_stages]})
  // }

  const submitForm = async (form: RoleAttachmentForm) => {
    const response = await axios.post(`/candidates/${candidate.slug}/stage/${stage.index}/form`, form)
    setCandidate(response.data)
  }

  return (
    <ExperienceContext.Provider value={{ isPreview, updateForm, submitForm, branding, candidate, setCandidate, sendMessage, uploadFile, updateTypeform, stage, setStage, activityMenuOpen, setActivityMenuOpen, markNotificationsAsRead, markNotificationAsRead, updateChecklist, stageAvailable}}>
      {!loadingCandidate && props.children}
    </ExperienceContext.Provider>
  )
}