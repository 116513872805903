import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useAuth } from "../Shared/AuthContext";
import LogoDark from './assets/logo-dark.png'
import { ReactComponent as GoogleLogo } from './assets/google.svg';

interface OrgInvite {
  email: string
  org_name: string
}

interface SignupInviteParams {
  invite_token: string
}

function SignupInvite() {
  const { invite_token } = useParams<SignupInviteParams>()
  const [invite, setInvite] = useState({} as OrgInvite)
  const [invalidInvite, setInvalidInvite] = useState(false)

  const fetchInvite = async () => {
    try {
      const response = await axios.get(`/organization/invite/${invite_token}`)
      setInvite(response.data.invite)
    } catch(error) {
      setInvalidInvite(true)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchInvite() }, []);

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [password, setPassword] = useState("")

  const {signup} = useAuth()

  const handleSubmit = (event: { preventDefault: () => void; }) => {
    event.preventDefault()
    signup({firstName, lastName, email: invite.email, password, inviteToken: invite_token})
  }
  
  if(invalidInvite) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <NavLink to='/'>
            <img
              className="mx-auto h-14 w-auto"
              src={LogoDark}
              alt="Drumcircle"
            />
          </NavLink>
          <h2 className="mt-8 text-center text-3xl font-extrabold text-gray-900">Invalid Invite</h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Make sure you used the provided invite link. If you are still having issues, reach out to the person who invited you.
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <NavLink to='/'>
          <img
            className="mx-auto h-14 w-auto"
            src={LogoDark}
            alt="Drumcircle"
          />
        </NavLink>
        <h2 className="mt-8 text-center text-3xl font-extrabold text-gray-900">Create your account</h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          to join <span className="font-medium text-indigo-600">{invite.org_name}</span>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  First name
                </label>
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  autoFocus
                  required
                  onChange={e => setFirstName(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                  Last name
                </label>
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  autoComplete="family-name"
                  required
                  onChange={e => setLastName(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={invite.email}
                  disabled
                  className="appearance-none text-gray-600 bg-gray-100 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  onChange={e => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Sign up
              </button>
            </div>
          </form>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">or continue with</span>
              </div>
            </div>

            <div className="mt-6 flex justify-center">
              <div>
                <a
                  href={`/auth/google_oauth2?invite_token=${invite_token}`}
                  className="inline-flex pt-2 pb-1 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Sign in with Twitter</span>
                  <GoogleLogo className="h-6 w-32"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignupInvite;
