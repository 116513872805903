import React, { useEffect, useState } from 'react'
import { useAuth } from '../../Shared/AuthContext'
import { Organization, OrganizationMember, OrganizationInvite } from '../../Shared/types';
import { useMixPanel } from 'react-mixpanel-provider-component'
import axios from 'axios'
import InviteMember from './InviteMember'
import RemoveInvite from './RemoveInvite'
import RemoveMember from './RemoveMember'
import SuccessNotification from '../Shared/SuccessNotification';

export default function OrganizationSettings() {
  // const [loadingOrg, setLoadingOrg] = useState(true);
  const [saveSuccess, setSaveSuccess] = useState(false)
  const [org, setOrg] = useState({} as Organization);
  const [orgName, setOrgName] = useState(org.name);
  // const [updatingOrg, setUpdatingOrg] = useState(false);

  const {currentUser} = useAuth()
  const {mixpanel} = useMixPanel()

  const fetchOrg = async () => {
    const response = await axios.get('/organization')
    setOrg(response.data.org)
    // setLoadingOrg(false)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchOrg() }, []);

  const updateOrg = async () => {
    // setUpdatingOrg(true)
    const response = await axios.put('/organization', {name: orgName})
    setOrg(response.data.org)
    mixpanel.track('Organization - Update')
    setSaveSuccess(true)
    // setUpdatingOrg(false)
  }

  const removeMember = (member: OrganizationMember) => {
    const updatedMembers = org.members.filter((m) => m.id !== member.id)
    setOrg({...org, members: updatedMembers})
  }

  const removeInvite = (invite: OrganizationInvite) => {
    const updatedInvites = org.invites.filter((i) => i.id !== invite.id)
    setOrg({...org, invites: updatedInvites})
  }

  const addInvite = (invite: OrganizationInvite) => {
    setOrg({...org, invites: [...org.invites, invite]})
  }

  useEffect(() => { setOrgName(org.name)}, [org])

  
  return (
    <>
      <SuccessNotification show={saveSuccess} setShow={setSaveSuccess} title="Successfully saved!" subtitle="Your profile has been updated." />
      <div className="divide-y divide-gray-200 lg:col-span-9">
        <div className="py-6 px-4 sm:p-6 lg:pb-8">
          <div>
            <h2 className="text-lg leading-6 font-medium text-gray-900">Team</h2>
            <p className="mt-1 text-sm text-gray-500">
              This information will be displayed publicly so be careful what you share.
            </p>
          </div>
          <div className="mt-6 grid grid-cols-12 gap-6">
            <div className="col-span-8">
              <label htmlFor="orgname" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                type="text"
                name="orgname"
                id="orgname"
                value={orgName || ''}
                onChange={e => setOrgName(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
          </div>
        </div>

        <div className="pt-6 divide-y divide-gray-200">
          <div className="px-4 sm:px-6">
            <div>
              <h2 className="text-lg leading-6 font-medium text-gray-900">Team Members</h2>
              <p className="mt-1 text-sm text-gray-500">
                Invite your team to get the most from Drumcircle.
              </p>
            </div>
            <div className="space-y-2 mt-6">
              { currentUser && currentUser.role === 'owner' && 
                <InviteMember addInvite={addInvite}/>
              }
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-6 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Role
                          </th>
                          <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {org && org.members && org.members.map((member) => (
                          <tr key={member.email}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="flex items-center">
                                <div className="flex-shrink-0 h-10 w-10">
                                  <img className="h-10 w-10 rounded-full" src={member.avatar} alt="" />
                                </div>
                                <div className="ml-4">
                                  <div className="text-sm font-medium text-gray-900">{`${member.first_name} ${member.last_name}`}</div>
                                  <div className="text-sm text-gray-500">{member.email}</div>
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                Active
                              </span>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{member.role}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              {member.role !== 'owner' && currentUser && currentUser.role === 'owner' &&
                                <RemoveMember member={member} removeMember={removeMember} />
                              }
                            </td>
                          </tr>
                        ))}
                        {org && org.invites && org.invites.map((invite) => (
                          <tr key={invite.email}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="flex items-center">
                                <div className="flex-shrink-0 h-10 w-10">
                                  <img className="h-10 w-10 rounded-full" src={invite.avatar} alt="" />
                                </div>
                                <div className="ml-4">
                                  <div className="text-sm font-medium text-gray-900">{' '}</div>
                                  <div className="text-sm text-gray-500">{invite.email}</div>
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                Invited
                              </span>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{' '}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              <RemoveInvite invite={invite} removeInvite={removeInvite} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

          </div>
        </div>
        </div>


        <div className="mt-4 py-4 px-4 flex justify-end sm:px-6">
          <button
            type="button"
            onClick={() => {window.location.reload()}}
            className="bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
          >
            Cancel
          </button>
          <button
            onClick={updateOrg}
            className="ml-5 bg-sky-700 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
          >
            Save
          </button>
        </div>    
      </div>
    </>
  )
}
