import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import MixPanelProvider from 'react-mixpanel-provider-component';
import { ErrorBoundary } from 'react-error-boundary';
import Error500 from './Shared/Error500';
import { AuthProvider } from './Shared/AuthContext';
import { BrowserRouter } from 'react-router-dom';


let sentryDSN = ''
if(window.location.host === 'staging.drumcircle.io') { sentryDSN = 'https://fb4d9b739eee4184bf8800f9ee36ff68:6229b9f0e58d458daa2cf2c800f69ab5@o564691.ingest.sentry.io/5705562' }
if(window.location.host === 'drumcircle.io') { sentryDSN = 'https://1db26350b497480bbab93574e9175269:84527b7fd2e148ffa5ea7bcce3d1321a@o564700.ingest.sentry.io/5705577' }

let mixpanelToken = 'b8bdd51efeaed2da90a3fe806020f0a6'
if(window.location.host === 'staging.drumcircle.io') { mixpanelToken = 'b8bdd51efeaed2da90a3fe806020f0a6' }
if(window.location.host === 'drumcircle.io') { mixpanelToken = '3e10f4757a8bedc05c53f1a04da95c7d' }

if(sentryDSN) {
  Sentry.init({
    dsn: sentryDSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <MixPanelProvider token={mixpanelToken}>
      <ErrorBoundary FallbackComponent={Error500}>
        <BrowserRouter>
          <AuthProvider>
            <App/>
          </AuthProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </MixPanelProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
