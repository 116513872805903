import axios from "axios"
import { useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useAuth } from "../Shared/AuthContext"

interface ConfirmEmailParams {
  email_token: string
}

export default function ConfirmEmail() {
  const { email_token } = useParams<ConfirmEmailParams>()
  const { fetchLoginStatus } = useAuth()
  const history = useHistory()
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { confirmEmail() }, [])

  const confirmEmail = async () => {
    try {
      await axios.post(`/users/confirm-email/${email_token}`)
      fetchLoginStatus()
      history.push('/')
    } catch(error) {
      history.push('/')
    }
  }

  return null
}