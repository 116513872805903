import React from "react";
import { RoleAttachment, RoleAttachmentLocation } from '../../types';
import TextareaAutosize from 'react-textarea-autosize';

interface LocationTileEditorProps {
  location: RoleAttachmentLocation
  updateAttachment: (attachment: RoleAttachment) => void
}

export default function LocationTileEditor(props: LocationTileEditorProps) {
  return (
    <div className="">
      <div className="p-4 space-y-6">
        <div>
          <label htmlFor='name' className="block text-sm font-medium text-gray-700">
            Name
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="name"
              id='name'
              value={props.location.payload.description}
              onChange={(e) => { props.updateAttachment({...props.location, payload: {...props.location.payload, description: e.target.value}})} }
              placeholder="Our Office"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>

        <div>
          <label htmlFor='street-address' className="block text-sm font-medium text-gray-700">
            Street address
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="street-address"
              id="street-address"
              autoComplete="street-address"
              placeholder='123 Main Street'
              value={props.location.payload.address1}
              onChange={(e) => { props.updateAttachment({...props.location, payload: {...props.location.payload, address1: e.target.value}})} }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>

        <div className="mt-4 grid grid-cols-1 gap-y-2 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-2">
            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
              City
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="city"
                id="city"
                autoComplete="address-level2"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                value={props.location.payload.city}
                onChange={(e) => { props.updateAttachment({...props.location, payload: {...props.location.payload, city: e.target.value}})} }
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="state" className="block text-sm font-medium text-gray-700">
              State
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="state"
                id="state"
                autoComplete="address-level1"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                value={props.location.payload.state}
                onChange={(e) => { props.updateAttachment({...props.location, payload: {...props.location.payload, state: e.target.value}})} }
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
              Zip
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="postal-code"
                id="postal-code"
                autoComplete="postal-code"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                value={props.location.payload.zipcode}
                onChange={(e) => { props.updateAttachment({...props.location, payload: {...props.location.payload, zipcode: e.target.value}})} }
              />
            </div>
          </div>
        </div>

        <div>
          <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
            Notes
          </label>
          <div className="mt-1">
            <TextareaAutosize
              name="notes"
              id="notes"
              value={props.location.payload.notes}
              onChange={(e) => props.updateAttachment({...props.location, payload: {...props.location.payload, notes: e.target.value}})}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              minRows={3}
            />
          </div>
        </div>

      </div>
    </div>
  )
}