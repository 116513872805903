import React, { Fragment, useEffect, useRef, useState } from 'react'
import { ChatAltIcon, UserAddIcon, DocumentAddIcon, BadgeCheckIcon, CalendarIcon, DocumentTextIcon, PaperClipIcon, XIcon } from '@heroicons/react/solid'
import { Candidate, CandidateActivity, CandidateChecklistActivity, CandidateInvite, CandidateInviteLocation } from '../Shared/types'
import moment from 'moment'
import { useAuth } from '../Shared/AuthContext'
import { Transition } from '@headlessui/react'
import TextareaAutosize from 'react-textarea-autosize';
import { ChatIcon } from '@heroicons/react/outline'
import { useExperience } from "./ExperienceContext"
import './ExperienceChat.css'
import linkifyHtml from 'linkify-html';
import Linkify from 'react-linkify';

interface ExperienceActivityProps {
  disabled?: boolean
}


export default function ExperienceChat(props: ExperienceActivityProps) {
  const { currentUser } = useAuth()
  const { candidate, activityMenuOpen, setActivityMenuOpen, sendMessage, uploadFile, markNotificationsAsRead } = useExperience()
  const [newMessage, setNewMessage] = useState('')
  const [autoFocus, setAutoFocus] = useState(false)

  // const [activityMenuOpen, setActivityMenuOpen] = useState(false)

  const sendNewMessage = async () => {
    await sendMessage(newMessage)
    setNewMessage('')
  }

  const sendMessageRef = useRef<HTMLTextAreaElement>(null);
  useEffect(()=>{ if(autoFocus) { sendMessageRef.current?.focus() }}, [autoFocus]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { if(activityMenuOpen) { markNotificationsAsRead() } }, [activityMenuOpen])
  
  return (
    <>
      
      <button
        type="button"
        onClick={() => { setActivityMenuOpen(!activityMenuOpen) }}
        className="ring-1 ring-black ring-opacity-5 active:shadow-inner active:transform active:scale-95 inline-flex overflow-visible shadow-md fixed bottom-0 right-0 items-center p-4 m-6 w-16 h-16 rounded-full text-white bg-gradient-to-r from-purple-500 to-indigo-500 hover:bg-indigo-700 focus:outline-none z-50"
      >
        <div className="relative w-full h-full">
          {candidate.notifications.length > 0 &&
            <span className="block md:hidden absolute bottom-8 left-6 items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full ring-1 ring-red-700">{candidate.notifications.length}</span>
          }
          
          <Transition show={!activityMenuOpen} className="absolute w-full h-full"
            enter="transition-all ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ChatIcon className=""/>
        </Transition>

        <Transition show={activityMenuOpen} className="absolute w-full h-full"
            enter="transition-all ease-linear duration-150 transform"
            enterFrom="opacity-0 rotate-45"
            enterTo="opacity-100 rotate-0"
            leave="transition ease-linear duration-150 transform"
            leaveFrom="opacity-100 rotate-0"
            leaveTo="opacity-0 rotate-45"
          >
            <XIcon className=""/>
        </Transition>
        </div>
      </button>

      <Transition
        show={activityMenuOpen}
        unmount={false}
        enter="transform ease-out duration-300 transition"
        enterFrom="opacity-0 translate-y-12"
        enterTo="opacity-100 sm:translate-y-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="chat-window-container h-full sm:h-2/3 w-full sm:max-w-md fixed bottom-24 mb-2 right-0 sm:right-6 ring-1 ring-black ring-opacity-5 shadow-lg rounded-lg bg-white z-50 flex flex-col"
      >

          <div className="border-b-2 border-indigo-500 text-indigo-600">
            <div className="-mb-px flex justify-center">
              <div className="group inline-flex items-center justify-center py-4 px-1 font-medium text-sm w-1/3">
                <ChatIcon className="text-indigo-500 -ml-0.5 mr-2 h-5 w-5" aria-hidden="true" />
                <span>Activity</span>
              </div>
            </div>
          </div>


          {/* Main Content */}
          <div className="flex-1 min-h-0 overflow-y-scroll">
            <div className="h-full overflow-y-scroll">
              <DashboardFeed candidate={candidate} />
            </div>
          </div>

          {/* Footer */}
          {!props.disabled &&
            <div className="flex items-start space-x-2 px-2 py-2 border-t border-gray-300 bg-gray-100 rounded-b-lg">
            <div className="flex-shrink-0">
              <img
                className="inline-block h-10 w-10 rounded-full"
                src={currentUser?.avatar || candidate.avatar}
                alt=""
              />
            </div>
            <div className="min-w-0 flex-1">
              <div className="relative">
                <div className="bg-white border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                  <label htmlFor="comment" className="sr-only">
                    Add your comment
                  </label>
                  <TextareaAutosize
                    name="comment"
                    id="comment"
                    className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm"
                    placeholder="Send a message..."
                    rows={1}
                    value={newMessage}
                    ref={sendMessageRef}
                    onChange={(e) => setNewMessage(e.target.value)}
                  />
                  <div className="" aria-hidden="true">
                    <div className="py-px">
                      <div className="h-9" />
                    </div>
                  </div>
                </div>
      
                <div className="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-between">
                  <div className="flex items-center space-x-5">
                    <div className="flex items-center">
                      <label
                        htmlFor='chat-file-upload'
                        className="break-inside relative -m-2.5 w-10 h-10 rounded-full flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none cursor-pointer"
                      >
                        <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                        <span className="sr-only">Attach a file</span>
                        <input
                          name="file-request"
                          id='chat-file-upload'
                          type="file"
                          onChange={uploadFile}
                          className="hidden absolute opacity-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                        />
                        
                      </label>
                    </div>
                  </div>
                  <div className="flex-shrink-0">
                    <button
                      onClick={sendNewMessage}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

      </Transition>
    </>
  )
}



interface DashboardFeedProps {
  // activities: Array<CandidateActivity>
  candidate: Candidate
  count?: number
}

function DashboardFeed(props: DashboardFeedProps) {

  const renderMessage = (activity: CandidateActivity, showAvatar: boolean) => {
    const candidate = props.candidate
    const experienceUrl = `/e/${candidate?.slug}`
    const icon = <ChatAltIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
    const payload = JSON.parse(activity.payload)

    return (
      <>
        {renderBadge(icon, activity.creator.avatar, showAvatar)}
        <div className="min-w-0 flex-1">
          <div>
            <div className="text-sm">
              <a href={experienceUrl} className="font-medium text-gray-900">
                {activity.creator.name}
              </a>
              <span className="text-sm text-gray-500">&nbsp;{moment(activity.created_at).fromNow()}</span>
            </div>
          </div>
          <div className="wrap-links mt-2 prose prose-sm text-gray-700">
            {payload.title &&
              <h3>{payload.title}</h3>
            }
            <p dangerouslySetInnerHTML={{__html: linkifyHtml(payload.text) }} />
          </div>
        </div>
      </>
    )
  }

  const renderChecklist = (activity: CandidateActivity, showAvatar: boolean) => {
    const candidate = props.candidate
    const experienceUrl = `/e/${candidate?.slug}`
    const checklist = JSON.parse(activity.payload) as CandidateChecklistActivity
    const completedText = checklist.complete ? 'completed' : 'uncompleted'
    const icon = <BadgeCheckIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />

    return (
      <>
      {renderBadge(icon, activity.creator.avatar, showAvatar)}      
      <div className="min-w-0 flex-1 py-1.5">
        <div className="text-sm text-gray-500">
          <a href={experienceUrl} className="font-medium text-gray-900">
            {activity.creator.name}
          </a>{' '}
          {completedText}{' '}
          <span className="font-medium text-gray-900">
            {checklist.text}
          </span>{' '}
          <p className="whitespace-nowrap">{moment(activity.created_at).fromNow()}</p>
        </div>
      </div>
    </>
    )
  }

  const renderFileUpload = (activity: CandidateActivity, showAvatar: boolean) => {
    const candidate = props.candidate
    const experienceUrl = `/e/${candidate?.slug}`
    const icon = <DocumentAddIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />

    return (
      <>
      {renderBadge(icon, activity.creator.avatar, showAvatar)}
      <div className="min-w-0 flex-1 py-1.5">
        <div className="text-sm text-gray-500">
          <a href={experienceUrl} className="font-medium text-gray-900">
            {activity.creator.name}
          </a>{' '}
          uploaded {' '}
          <Fragment>
            <a href={JSON.parse(activity.payload).file.url} className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
              <span className="absolute flex-shrink-0 flex items-center justify-center">
              <DocumentTextIcon className="h-3 w-3 text-gray-500" aria-hidden="true" />
              </span>
              <span className="ml-4 font-medium text-gray-900">{JSON.parse(activity.payload).file.name}</span>
            </a>{' '}
          </Fragment>
          <p className="whitespace-nowrap">{moment(activity.created_at).fromNow()}</p>
        </div>
      </div>
    </>
    )
  }

  const renderCreation = (activity: CandidateActivity, showAvatar: boolean) => {
    const candidate = props.candidate
    const experienceUrl = `/e/${candidate?.slug}`
    const icon = <UserAddIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />

    return (
      <>
      {renderBadge(icon, candidate?.avatar!, showAvatar)}
      <div className="min-w-0 flex-1 py-1.5">
        <div className="text-sm text-gray-500">
          <a href={experienceUrl} className="font-medium text-gray-900">
            {candidate?.name}
          </a>{' '}
          created {' '}
          <p className="whitespace-nowrap">{moment(activity.created_at).fromNow()}</p>
        </div>
      </div>
    </>
    )
  }

  const renderInvite = (activity: CandidateActivity, showAvatar: boolean) => {
    const invite = JSON.parse(activity.payload) as CandidateInvite
    const candidate = props.candidate
    const experienceUrl = `/e/${candidate?.slug}`
    const icon = <CalendarIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />

    return (
      <>
        {renderBadge(icon, activity.creator.avatar, showAvatar)}
        <div className="min-w-0 flex-1 py-1.5">
          <div className="text-sm text-gray-500">
          <a href={experienceUrl} className="font-medium text-gray-900">
            {activity.creator.name}
            </a>{' '}
            Event Scheduled{' '}
            <span className="font-medium text-gray-900">
              {invite.summary}
            </span>{' '}
            <p className="whitespace-nowrap">{moment(activity.created_at).fromNow()}</p>
          </div>
          <div className="mt-2 text-sm text-gray-700">
            <p>{moment(invite.event_start).format("MMM Do, h:mm a")} - {moment(invite.event_end).format("h:mm a")}</p>
            <LocationDetails location={invite.location} />
          </div>
        </div>
      </>
    )
  }

  const renderActivities = () => {
    const filteredActivities = props.candidate.activity.filter(a => a.activity_type !== 'transition')//.slice(-4)
    const activities = props.count ? filteredActivities.slice(-props.count) : filteredActivities

    let exit = false
    return activities.map((activity, index) => {
      let lastThing
      if(index > 0) {
        lastThing = activities[index - 1]
      }

      let showAvatar = true;
      const comparisonId = activity.activity_type === 'creation' ? activity.candidate_id : activity.creator.id
      const otherComparisonId = lastThing?.activity_type === 'creation' ? lastThing.candidate_id : lastThing?.creator.id
      
      if(comparisonId === otherComparisonId) {
      // if(lastThing?.creator.id && lastThing.creator.id === comparisonId) {
        showAvatar = false;
      }

      let nextThing
      let showLine = true
      if(index === activities.length - 1) {
        showLine = false
      }

      if(index < activities.length -1) {
        nextThing = activities[index+1]
        const thisThingId = activity.activity_type === 'creation' ? activity.candidate_id : activity.creator.id
        const nextThingId = nextThing.activity_type === 'creation' ? nextThing.candidate_id : nextThing.creator.id
        if(nextThingId !== thisThingId) {
          showLine = false;
        }
      }
      
      if(exit) {
        return null
      }

      return (
        <li key={index}>
          <div className="relative" >
            {showLine &&
              <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
            }
            <div className="relative flex items-start space-x-3">
              {renderActivity(activity, showAvatar)}
            </div>
          </div>
        </li>
      )
    })
  }

  const renderBadge = (icon: JSX.Element, avatar: string, showAvatar: boolean)=> {
    if(true) {
      return (
        <div className="relative">
          <img src={avatar} alt="" className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"/>
          <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">{icon}</span>
        </div>
      )
    } else {
      return (
        <div>
          <div className="relative px-1">
            <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">{icon}</div>
          </div>
        </div>
      )
    }
  }

  const renderActivity = (activity: CandidateActivity, showAvatar: boolean) => {
    switch (activity.activity_type) {
      case 'creation': 
        return renderCreation(activity, showAvatar)
      case 'invite':
        return renderInvite(activity, showAvatar)
      case 'file':
        return renderFileUpload(activity, showAvatar)
      case 'message':
        return renderMessage(activity, showAvatar)
      case 'checklist':
        return renderChecklist(activity, showAvatar)
      default:
        return null
    }
  }

  const scrollRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    setTimeout(() => { scrollRef.current?.scrollIntoView({ block: 'nearest' }) }, 0)
  })

  
  return (
    <>
    <ul className="space-y-8 mt-6 my-4 mx-6">
      {renderActivities()}
      
    </ul>
      <div ref={scrollRef}></div>
    </>
  )
}

const LocationDetails = (props: {location: CandidateInviteLocation}) => {
  const locationLinkDecorator = (text: string) => {
    if(text.startsWith('https://zoom.us')) { return 'Zoom Meeting' }
    if(text.startsWith('https://meet.google.com/')) { return 'Google Meet' }
    return 'Meeting Location'
  }
  //todo - divider line?
  //todo - cancel/reschedule if present?
  //todo - how to indicate canceled
  //todo - indicate past event?
  

  switch(props.location.type) {
    case 'unknown': 
      return (
        <Linkify textDecorator={locationLinkDecorator} componentDecorator={(decoratedHref, decoratedText, key) => (
          <a target="blank" href={decoratedHref} key={key}>
            {decoratedText}
          </a>
        )} ><p className="line-clamp-2 text-sm text-indigo-600">{props.location.text}</p></Linkify>
      )
    case 'google_conference':
    case 'meet':
      return (
        <a target="_blank" rel="noreferrer" href={props.location.join_url} className="line-clamp-2 text-sm text-indigo-600">Google Meet</a>
      )
    case 'zoom':
      const zoomText = props.location.data.password ? `Zoom Meeting - Password: ${props.location.data.password}` : 'Zoom Meeting'
      return (
        <a target="_blank" rel="noreferrer" href={props.location.join_url} className="line-clamp-2 text-sm text-indigo-600">{zoomText}</a>
      )
    case 'microsoft_teams_conference':
      return (
        <a target="_blank" rel="noreferrer" href={props.location.join_url} className="line-clamp-2 text-sm text-indigo-600">MS Teams Meeting</a>
      )
    default:
      if(props.location.join_url) {
        return (
          <a target="_blank" rel="noreferrer" href={props.location.join_url} className="line-clamp-2 text-sm text-indigo-600">Join Meeting</a>
        )
      } else {
        const locationText = props.location.location || props.location.text
        if(!locationText) { return null }

        return (
          <Linkify textDecorator={locationLinkDecorator} componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )} ><p className="line-clamp-2 text-sm text-indigo-600">{locationText}</p></Linkify>
        )
      }
  }
}