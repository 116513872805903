import { useEffect, useRef, useState } from 'react';
import { RoleStageFile } from '../../Shared/types';
import { useMixPanel } from 'react-mixpanel-provider-component';
import { DocumentAddIcon, PlusSmIcon } from '@heroicons/react/outline';
import React from 'react';

interface StageFileEditorProps {
  files: Array<RoleStageFile>
  setFiles: (files: Array<RoleStageFile>) => void
}

function StageFileEditor(props: StageFileEditorProps) {
  const [files, setFiles] = useState(props.files)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const {mixpanel} = useMixPanel()

  useEffect(() => { setFiles(props.files) }, [props])

  const selectFileHandler = (e:any) => {
    const selectedFiles: Array<RoleStageFile> = [...e.target.files].map((file: any) => {
      return {
        id: undefined,
        type: file.type,
        name: file.name,
        url: file.url,
        file: file
      }
    })

    const merged = [...files, ...selectedFiles]
    props.setFiles(merged)
  }
  
  const addFilesAction = () => {
    if(fileInputRef.current) {fileInputRef.current.click() }
    mixpanel.track('Role Action - Add Files')
  }

  const removeFileAction = (index: number) => {
    const updatedFiles = files.filter((file, i) => i !== index )
    props.setFiles(updatedFiles)
    mixpanel.track('Role Action - Remove File')
  }

  return (
    <>
      <input ref={fileInputRef} type="file" hidden name="file" onChange={selectFileHandler}/>
      <ul className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
        {props.files.map((file, index) => (
          <li key={'files' + index} className="flex items-center justify-between py-3">
            <div className="flex items-center">
              <DocumentAddIcon className="w-8 h-8 p-1 rounded-full text-amber-50 bg-amber-500 border-2 border-transparent" />
              <p className="ml-4 text-sm font-medium text-gray-900">{file.name}</p>
            </div>
            <button
              type="button"
              className="ml-6 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => { removeFileAction(index) }}>
              Remove
            </button>
          </li>
        ))}
        <li className="flex items-center justify-between py-2">
          <button type="button" className="group -ml-1 flex items-center rounded-md bg-white p-1 focus:outline-none focus:ring-2 focus:ring-indigo-500" onClick={() => { addFilesAction() }}>
            <span className="flex h-8 w-8 items-center justify-center rounded-full border-2 border-dashed border-gray-300 text-gray-400">
              <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
            </span>
            <span className="ml-4 text-sm font-medium text-indigo-600 group-hover:text-indigo-500">
              Add file
            </span>
          </button>
        </li>
      </ul>
    </>
  )
}

export default React.memo(StageFileEditor);
