import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { useMixPanel } from 'react-mixpanel-provider-component';
import { useHistory } from 'react-router-dom';
import { Dialog, RadioGroup, Transition, Listbox, Switch } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { CheckIcon, PlusSmIcon, SelectorIcon } from '@heroicons/react/solid'
import classNames from 'classnames';

const settings = [
  { name: 'New role', description: 'Build a new role from scratch', key: 'new' },
  { name: 'Clone role', description: 'Copy an existing role', key: 'clone' },
]

interface Role {
  title: string,
  description: string,
  id: string
}

interface CreateRoleProps {
  roles: Array<Role>
}

function CreateRole(props: CreateRoleProps) {
  const [selected, setSelected] = useState(settings[0])
  const [validationError, setValidationError] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [open, setOpen] = useState(false)
  const [roles, setRoles] = useState(props.roles)
  const [roleName, setRoleName] = useState('')
  const [recruiterInterview, setRecruiterInterview] = useState(true)
  const [zoomInterview, setZoomInterview] = useState(true)
  const [assignment, setAssignment] = useState(true)
  const [onsiteInterview, setOnsiteInterview] = useState(true)

  const stages = [
    { name: 'Recruiter Interview', description: 'Initial recruiter screen to discuss the role.', key: 'recruiter_interview', checked: recruiterInterview, onChange: setRecruiterInterview },
    { name: 'Zoom Interview', description: 'Remote interview with the hiring manager.', key: 'zoom_interview', checked: zoomInterview, onChange: setZoomInterview },
    { name: 'Take-Home Assignment', description: 'Skills assessment for the candidate to complete.', key: 'assignment', checked: assignment, onChange: setAssignment },
    { name: 'Onsite Interview', description: 'Complete onsite interview experience.', key: 'onsite_interview', checked: onsiteInterview, onChange: setOnsiteInterview },
  ]
  
  const history = useHistory();
  const { mixpanel } = useMixPanel()

  const [selectedClone, setSelectedClone] = useState(roles[0])

  const createRole = async () => {
    if(roleName === '') {
      setValidationError(true)
      return
    }

    setSubmitting(true)
    if(selected.key === 'clone') {
      const response = await axios.post('/roles', {title: roleName, clone: selectedClone.id as string})

      mixpanel.track('Role - Clone (UI)', {roleName: response.data.role.title, roleId: response.data.role.id})
      history.push(`/role/${response.data.role.id}`)
    } else {
      const response = await axios.post('/roles', {title: roleName, stages: {
        recruiter_interview: recruiterInterview,
        zoom_interview: zoomInterview,
        assignment: assignment,
        onsite_interview: onsiteInterview,
      }})

      mixpanel.track('Role - Create (UI) ', {roleName: response.data.role.title, roleId: response.data.role.id})
      history.push(`/role/${response.data.role.id}`)
    }
  }

  useEffect(() => { setValidationError(false) }, [roleName])
  useEffect(() => { setRoles(props.roles) }, [props.roles])

  const renderCloneOptions = () => {
    return (
      <div>
      <label htmlFor="project-name" className="block text-sm font-medium text-gray-900">
        Role
      </label>
      <Listbox value={selectedClone} onChange={setSelectedClone}>
        <div className="mt-1 relative">
          <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            <span className="block truncate">{selectedClone.title}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>

          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
              {roles.map((role) => (
                <Listbox.Option
                  key={role.id}
                  className={({ active }) =>
                    classNames(
                      active ? 'text-white bg-indigo-600' : 'text-gray-900',
                      'cursor-default select-none relative py-2 pl-3 pr-9'
                    )
                  }
                  value={role }
                >
                  {({ selected, active }) => (
                    <>
                      <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                        {role.title}
                      </span>

                      {selected ? (
                        <span
                          className={classNames(
                            active ? 'text-white' : 'text-indigo-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      </div>
    )
  }

  const renderNewOptions = () => {
    return (
      <div>
        {/* <label htmlFor="project-name" className="block text-sm font-medium text-gray-900">
            Stages
          </label> */}
        <ul className="divide-y divide-gray-200">
          
          {stages.map((stage) => 
            <Switch.Group as="li" key={stage.key} className="py-4 flex items-center justify-between">
              <div className="flex flex-col">
                <Switch.Label as="a" className="text-sm font-medium text-gray-900" passive>
                  {stage.name}
                </Switch.Label>
                <Switch.Description className="text-sm text-gray-500">
                  {stage.description}
                </Switch.Description>
              </div>
              <Switch
                checked={stage.checked}
                onChange={stage.onChange}
                className={classNames(
                  stage.checked ? 'bg-indigo-500' : 'bg-gray-200',
                  'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    stage.checked ? 'translate-x-5' : 'translate-x-0',
                    'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </Switch.Group>
          )}
        </ul>
      </div>
    )
  }
  
  return (
    <>
      <span className="block ml-2 shadow-2xl">
        <button
          type="button"
          disabled={submitting}
          onClick={() => { setOpen(true) }}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm text-white bg-white bg-indigo-500 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <PlusSmIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Role
        </button>
      </span>
          
      <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <form className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                  <div className="flex-1 h-0 overflow-y-auto">
                    <div className="py-6 px-4 bg-gray-800 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">Create Role</Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-gray-800 rounded-md text-indigo-200 hover:text-white focus:outline-none"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-gray-100">
                          Fill in a name and select your template to get started.
                        </p>
                      </div>
                    </div>
                    <div className="flex-1 flex flex-col justify-between">
                      <div className="px-4 divide-y divide-gray-200 sm:px-6">
                        <div className="space-y-6 pt-6">
                          

                          <RadioGroup value={selected} onChange={setSelected}>
                            <RadioGroup.Label className="sr-only">Create type</RadioGroup.Label>
                            <div className="bg-white rounded-md -space-y-px">
                              {settings.map((setting, settingIdx) => (
                                <RadioGroup.Option
                                  key={setting.name}
                                  value={setting}
                                  className={({ checked }) =>
                                    classNames(
                                      settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                                      settingIdx === settings.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                                      checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200',
                                      'relative border p-4 flex cursor-pointer focus:outline-none'
                                    )
                                  }
                                >
                                  {({ active, checked }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                                          active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                                          'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                                        )}
                                        aria-hidden="true"
                                      >
                                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                                      </span>
                                      <div className="ml-3 flex flex-col">
                                        <RadioGroup.Label
                                          as="span"
                                          className={classNames(checked ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium')}
                                        >
                                          {setting.name}
                                        </RadioGroup.Label>
                                        <RadioGroup.Description
                                          as="span"
                                          className={classNames(checked ? 'text-indigo-700' : 'text-gray-500', 'block text-sm')}
                                        >
                                          {setting.description}
                                        </RadioGroup.Description>
                                      </div>
                                    </>
                                  )}
                                </RadioGroup.Option>
                              ))}
                            </div>
                          </RadioGroup>

                          <div>
                            <label htmlFor="project-name" className="block text-sm font-medium text-gray-900">
                              Name
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="project-name"
                                id="project-name"
                                value={roleName}
                                onChange={(e) => { setRoleName(e.target.value)}}
                                className={classNames(validationError ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500' : '', "block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md")}
                              />
                              {validationError &&
                                <p className="mt-2 text-sm text-red-600" id="email-error">
                                  Please enter a name for this role.
                                </p>
                              }
                            </div>
                          </div>

                          {selected.key === 'clone' &&
                            renderCloneOptions()
                          }

                          {selected.key === 'new' &&
                            renderNewOptions()
                          }
                      
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                    <button
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={createRole}
                      className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </>
  )
}

export default CreateRole;
