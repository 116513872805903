import classNames from "classnames"
import React from "react"
import { Draggable, Droppable } from "react-beautiful-dnd"
import { RoleStage } from "../../Shared/types"

interface RoleStageSidebarProps {
  stages: Array<RoleStage>
  currentStageIndex: number
  setActiveStage: React.Dispatch<React.SetStateAction<number>>
}

export default function RoleStageSidebar(props: RoleStageSidebarProps) {

  return (
    <Droppable droppableId='stages' type='stages'>
      {(provided) => (
        <nav aria-label="Sidebar" className="block w-16 sm:w-24 bg-gray-800 overflow-y-auto flex-none flex flex-col items-center">
          <div className="flex-1 my-2 w-full px-2 space-y-1" {...provided.droppableProps} ref={provided.innerRef}>
            {props.stages.map((stage, index) => (
              <Draggable key={stage.temp_id || stage.id} draggableId={(stage.temp_id || stage.id).toString()} index={stage.index} >
                {(provided) => (
                  <div
                    {...provided.dragHandleProps}
                    {...provided.draggableProps} ref={provided.innerRef as any}
                    key={stage.title}
                    onClick={() => { props.setActiveStage(stage.index) }}
                    className={classNames(
                      props.currentStageIndex === stage.index ? 'bg-gray-900 text-white' : 'text-gray-400 hover:bg-gray-700',
                      props.currentStageIndex > stage.index ? 'bg-gray-800 text-gray-400 hover:bg-gray-800' : '',
                      'group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium'
                    )}
                  >
                    <span className="group-hover:border-gray-400 flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full">
                      <span className={classNames(props.currentStageIndex === stage.index ? 'bg-gray-900 text-white' : 'text-gray-400 hover:bg-gray-700')}>{stage.index + 1}</span>
                    </span>
                    <span className="hidden sm:block mt-2 self-center text-center">{stage.title}</span>
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        </nav>
      )}
    </Droppable>
  )
}