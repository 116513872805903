import React, { Fragment, useEffect, useRef, useState } from 'react'
import { ChatAltIcon, UserAddIcon, DocumentAddIcon, BadgeCheckIcon, CalendarIcon, DocumentTextIcon } from '@heroicons/react/solid'

import { Candidate, CandidateActivity, CandidateChecklistActivity, CandidateFile, CandidateInvite, CandidateInviteLocation } from '../../Shared/types'
import moment from 'moment'
import { useAuth } from '../../Shared/AuthContext'
import axios from 'axios'
import linkifyHtml from 'linkify-html';
import Linkify from 'react-linkify';


interface CandidateActivityProps {
  candidate: Candidate
  count?: number
  updateCandidate: (candidate: Candidate) => void
  disabled?: boolean
}

export default function CandidateActivitySection(props: CandidateActivityProps) {
  const { currentUser } = useAuth()
  const [newMessage, setNewMessage] = useState('')
  const [files, setFiles] = useState(Array<CandidateFile>())

  const sendMessage = async () => {
    if(newMessage === '') { return }
    const response = await axios.post(`/candidates/${props.candidate.slug}/activity`, {message: newMessage})
    props.updateCandidate({...props.candidate, activity: [...props.candidate.activity, response.data]})
    setNewMessage('')
  }

  const uploadFile = (e:any) => {
    [...e.target.files].map((file: any) => {
      setFiles([...files, {type: file.type, name: file.name, url: file.url}])

      const data = new FormData()
      data.append('file', file)
      axios.post(`/candidates/${props.candidate.slug}/files`, data , {})
        .then(response => {
          props.updateCandidate(response.data)
        })

      return null
    })
  }


  return (
    <>
    <div className="bg-white w-full flex justify-end min-h-0 pointer-events-auto relative border-t border-gray-200">
      <div className="text-sm font-medium text-gray-500 absolute top-0 left-0 pl-6 pt-6 hidden sm:block">Recent Activity</div>
        <div className="flex-initial overflow-y-scroll w-full sm:w-2/3">
          <DashboardFeed candidate={props.candidate}/>
        </div>
      </div>
      <div className="flex justify-end bg-white rounded-b-lg">
      <div className="bg-white h-32 pt-1 px-6 w-full sm:w-2/3 pointer-events-auto rounded-b-lg">
        {!props.disabled &&
          <div className="flex space-x-3">
            <div className="flex-shrink-0">
              <div className="relative">
                <img
                  className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
                  src={currentUser?.avatar}
                  alt=""
                />

                <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
                  <ChatAltIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </div>
            </div>
            <div className="min-w-0 flex-1">
                <div>
                  <label htmlFor="message" className="sr-only">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows={2}
                    className="shadow-sm block w-full focus:ring-gray-900 focus:border-gray-900 sm:text-sm border border-gray-300 rounded-md"
                    placeholder="Send a message"
                    onChange={(e) => setNewMessage(e.target.value)}
                    value={newMessage}
                  />
                </div>
                <div className="mt-4 flex items-center justify-end space-x-4">
                  <label
                    htmlFor='quickview-file-upload'
                    className="break-inside relative w-full sm:w-auto border border-gray-300 shadow-sm inline-flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none cursor-pointer"
                  >
                    <DocumentAddIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                    <span>Upload</span>
                    <input
                      name="file-request"
                      id='quickview-file-upload'
                      type="file"
                      onChange={uploadFile}
                      className="hidden absolute opacity-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                    />
                  </label>
                  <button
                    type="submit"
                    onClick={sendMessage}
                    className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-900 hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  >
                    Send
                  </button>
                </div>
            </div>
          </div>
        }
      </div>
      </div>
      </>
  )
}



interface DashboardFeedProps {
  // activities: Array<CandidateActivity>
  candidate: Candidate
  count?: number
}

function DashboardFeed(props: DashboardFeedProps) {

  const renderMessage = (activity: CandidateActivity, showAvatar: boolean) => {
    const candidate = props.candidate
    const experienceUrl = `/e/${candidate?.slug}`
    const icon = <ChatAltIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
    const payload = JSON.parse(activity.payload)

    return (
      <>
        {renderBadge(icon, activity.creator.avatar, showAvatar)}
        <div className="min-w-0 flex-1">
          <div>
            <div className="text-sm">
              <a href={experienceUrl} className="font-medium text-gray-900">
                {activity.creator.name}
              </a>
            </div>
            <p className="mt-0.5 text-sm text-gray-500">{moment(activity.created_at).fromNow()}</p>
          </div>
          <div className="wrap-links mt-2 text-sm text-gray-700 prose">
            {payload.title &&
              <h3>{payload.title}</h3>
            }
            <p dangerouslySetInnerHTML={{__html: linkifyHtml(payload.text) }} />
          </div>
        </div>
      </>
    )
  }

  const renderChecklist = (activity: CandidateActivity, showAvatar: boolean) => {
    const candidate = props.candidate
    const experienceUrl = `/e/${candidate?.slug}`
    const checklist = JSON.parse(activity.payload) as CandidateChecklistActivity
    const completedText = checklist.complete ? 'completed' : 'uncompleted'
    const icon = <BadgeCheckIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />

    return (
      <>
      {renderBadge(icon, activity.creator.avatar, showAvatar)}      
      <div className="min-w-0 flex-1 py-1.5">
        <div className="text-sm text-gray-500">
          <a href={experienceUrl} className="font-medium text-gray-900">
            {activity.creator.name}
          </a>{' '}
          {completedText}{' '}
          <span className="font-medium text-gray-900">
            {checklist.text}
          </span>{' '}
          <p className="whitespace-nowrap">{moment(activity.created_at).fromNow()}</p>
        </div>
      </div>
    </>
    )
  }

  const renderFileUpload = (activity: CandidateActivity, showAvatar: boolean) => {
    const candidate = props.candidate
    const experienceUrl = `/e/${candidate?.slug}`
    const icon = <DocumentAddIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />

    return (
      <>
      {renderBadge(icon, activity.creator.avatar, showAvatar)}
      <div className="min-w-0 flex-1 py-1.5">
        <div className="text-sm text-gray-500">
          <a href={experienceUrl} className="font-medium text-gray-900">
            {activity.creator.name}
          </a>{' '}
          uploaded {' '}
          <Fragment>
            <a href={JSON.parse(activity.payload).file.url} className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
              <span className="absolute flex-shrink-0 flex items-center justify-center">
              <DocumentTextIcon className="h-3 w-3 text-gray-500" aria-hidden="true" />
              </span>
              <span className="ml-4 font-medium text-gray-900">{JSON.parse(activity.payload).file.name}</span>
            </a>{' '}
          </Fragment>
          <p className="whitespace-nowrap">{moment(activity.created_at).fromNow()}</p>
        </div>
      </div>
    </>
    )
  }

  const renderCreation = (activity: CandidateActivity, showAvatar: boolean) => {
    const candidate = props.candidate
    const experienceUrl = `/e/${candidate?.slug}`
    const icon = <UserAddIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />

    return (
      <>
      {renderBadge(icon, candidate?.avatar!, showAvatar)}
      <div className="min-w-0 flex-1 py-1.5">
        <div className="text-sm text-gray-500">
          <a href={experienceUrl} className="font-medium text-gray-900">
            {candidate?.name}
          </a>{' '}
          created {' '}
          <p className="whitespace-nowrap">{moment(activity.created_at).fromNow()}</p>
        </div>
      </div>
    </>
    )
  }

  const renderInvite = (activity: CandidateActivity, showAvatar: boolean) => {
    const invite = JSON.parse(activity.payload) as CandidateInvite
    const candidate = props.candidate
    const experienceUrl = `/e/${candidate?.slug}`
    const icon = <CalendarIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />

    return (
      <>
        {renderBadge(icon, activity.creator.avatar, showAvatar)}
        <div className="min-w-0 flex-1 py-1.5">
          <div className="text-sm text-gray-500">
          <a href={experienceUrl} className="font-medium text-gray-900">
            {activity.creator.name}
            </a>{' '}
            Event Scheduled{' '}
            <span className="font-medium text-gray-900">
              {invite.summary}
            </span>{' '}
            <p className="whitespace-nowrap">{moment(activity.created_at).fromNow()}</p>
          </div>
          <div className="mt-2 text-sm text-gray-700">
            <p>{moment(invite.event_start).format("MMM Do, h:mm a")} - {moment(invite.event_end).format("h:mm a")}</p>
            <LocationDetails location={invite.location} />
          </div>
        </div>
      </>
    )
  }

  const renderActivities = () => {
    const filteredActivities = props.candidate.activity.filter(a => a.activity_type !== 'transition')//.slice(-4)
    const activities = props.count ? filteredActivities.slice(-props.count) : filteredActivities

    let exit = false
    return activities.map((activity, index) => {
      let lastThing
      if(index > 0) {
        lastThing = activities[index - 1]
      }

      let showAvatar = true;
      const comparisonId = activity.activity_type === 'creation' ? activity.candidate_id : activity.creator.id
      const otherComparisonId = lastThing?.activity_type === 'creation' ? lastThing.candidate_id : lastThing?.creator.id
      
      if(comparisonId === otherComparisonId) {
      // if(lastThing?.creator.id && lastThing.creator.id === comparisonId) {
        showAvatar = false;
      }

      let nextThing
      let showLine = true
      if(index === activities.length - 1) {
        showLine = false
      }

      if(index < activities.length -1) {
        nextThing = activities[index+1]
        const thisThingId = activity.activity_type === 'creation' ? activity.candidate_id : activity.creator.id
        const nextThingId = nextThing.activity_type === 'creation' ? nextThing.candidate_id : nextThing.creator.id
        if(nextThingId !== thisThingId) {
          showLine = false;
        }
      }
      
      if(exit) {
        return null
      }

      return (
        <li key={index}>
          <div className="relative" >
            {showLine &&
              <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
            }
            <div className="relative flex items-start space-x-3">
              {renderActivity(activity, showAvatar)}
            </div>
          </div>
        </li>
      )
    })
  }

  const renderBadge = (icon: JSX.Element, avatar: string, showAvatar: boolean)=> {
    if(showAvatar) {
      return (
        <div className="relative">
          <img src={avatar} alt="" className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"/>
          <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">{icon}</span>
        </div>
      )
    } else {
      return (
        <div>
          <div className="relative px-1">
            <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">{icon}</div>
          </div>
        </div>
      )
    }
  }

  const renderActivity = (activity: CandidateActivity, showAvatar: boolean) => {
    switch (activity.activity_type) {
      case 'creation': 
        return renderCreation(activity, showAvatar)
      case 'invite':
        return renderInvite(activity, showAvatar)
      case 'file':
        return renderFileUpload(activity, showAvatar)
      case 'message':
        return renderMessage(activity, showAvatar)
      case 'checklist':
        return renderChecklist(activity, showAvatar)
      default:
        return null
    }
  }

  const scrollRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    scrollRef.current?.scrollIntoView({ block: 'nearest' })
  })

  return (
    <>
      <ul className="py-6 pl-6 pr-6 space-y-6">
        {renderActivities()}
        
      </ul>
      <div className="h-0 m-0 p-0" ref={scrollRef}></div>
    </>
  )
}

const LocationDetails = (props: {location: CandidateInviteLocation}) => {
  const locationLinkDecorator = (text: string) => {
    if(text.startsWith('https://zoom.us')) { return 'Zoom Meeting' }
    if(text.startsWith('https://meet.google.com/')) { return 'Google Meet' }
    return 'Meeting Location'
  }
  //todo - divider line?
  //todo - cancel/reschedule if present?
  //todo - how to indicate canceled
  //todo - indicate past event?
  

  switch(props.location.type) {
    case 'unknown': 
      return (
        <Linkify textDecorator={locationLinkDecorator} componentDecorator={(decoratedHref, decoratedText, key) => (
          <a target="blank" href={decoratedHref} key={key}>
            {decoratedText}
          </a>
        )} ><p className="line-clamp-2 text-sm text-indigo-600">{props.location.text}</p></Linkify>
      )
    case 'google_conference':
    case 'meet':
      return (
        <a target="_blank" rel="noreferrer" href={props.location.join_url} className="line-clamp-2 text-sm text-indigo-600">Google Meet</a>
      )
    case 'zoom':
      const zoomText = props.location.data.password ? `Zoom Meeting - Password: ${props.location.data.password}` : 'Zoom Meeting'
      return (
        <a target="_blank" rel="noreferrer" href={props.location.join_url} className="line-clamp-2 text-sm text-indigo-600">{zoomText}</a>
      )
    case 'microsoft_teams_conference':
      return (
        <a target="_blank" rel="noreferrer" href={props.location.join_url} className="line-clamp-2 text-sm text-indigo-600">MS Teams Meeting</a>
      )
    default:
      if(props.location.join_url) {
        return (
          <a target="_blank" rel="noreferrer" href={props.location.join_url} className="line-clamp-2 text-sm text-indigo-600">Join Meeting</a>
        )
      } else {
        const locationText = props.location.location || props.location.text
        if(!locationText) { return null }

        return (
          <Linkify textDecorator={locationLinkDecorator} componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )} ><p className="line-clamp-2 text-sm text-indigo-600">{locationText}</p></Linkify>
        )
      }
  }
}
