import React from "react"
import Linkify from 'react-linkify';


import { CandidateInviteLocation } from "./types"

export const InviteLocationDetails = (props: {location: CandidateInviteLocation}) => {
  const locationLinkDecorator = (text: string) => {
    if(text.startsWith('https://zoom.us')) { return 'Zoom Meeting' }
    if(text.startsWith('https://meet.google.com/')) { return 'Google Meet' }
    return 'Meeting Location'
  }
  //todo - divider line?
  //todo - cancel/reschedule if present?
  //todo - how to indicate canceled
  //todo - indicate past event?

  // flex items-center text-sm font-bold leading-6 text-pink-500 hover:text-pink-700 active:text-pink-900

  switch(props.location.type) {
    case 'unknown': 
      return (
        <Linkify textDecorator={locationLinkDecorator} componentDecorator={(decoratedHref, decoratedText, key) => (
          <a target="blank" href={decoratedHref} key={key}>
            {decoratedText}
          </a>
        )} ><p className="line-clamp-2 text-sm text-indigo-600">{props.location.text}</p></Linkify>
      )
    case 'google_conference':
    case 'meet':
      return (
        <a target="_blank" rel="noreferrer" href={props.location.join_url} className="line-clamp-2 text-sm text-indigo-600">Google Meet</a>
      )
    case 'zoom':
      // const zoomText = props.location.data.password ? `Join Zoom Meeting - Password: ${props.location.data.password}` : 'Join Zoom Meeting'
      return (
        <a target="_blank" rel="noreferrer" href={props.location.join_url} className="line-clamp-2 text-xs font-bold text-indigo-600">Zoom Meeting</a>
      )
    case 'microsoft_teams_conference':
      return (
        <a target="_blank" rel="noreferrer" href={props.location.join_url} className="line-clamp-2 text-sm text-indigo-600">MS Teams Meeting</a>
      )
    default:
      if(props.location.join_url) {
        return (
          <a target="_blank" rel="noreferrer" href={props.location.join_url} className="line-clamp-2 text-sm text-indigo-600">Join Meeting</a>
        )
      } else {
        const locationText = props.location.location || props.location.text
        if(!locationText) { return null }

        return (
          <Linkify textDecorator={locationLinkDecorator} componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )} ><p className="line-clamp-2 text-sm text-indigo-600">{locationText}</p></Linkify>
        )
      }
  }
}
